<template>
  <Navbar v-if="isLogged" />
  <RouterView />
  <Notifications />
</template>
<script>
import Notifications from '@/components/Common/Notifications/Main.vue';
import Navbar from '@/components/Common/Navbar.vue'
import { computed, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ref, provide, onUnmounted } from 'vue';
import base64url from 'base64url';
//import persistentData from "@/plugins/persistentData";
export default {
  components:{
    Notifications,
    Navbar
  },
  setup() {
    const store = useStore();
    const routeActive = ref('');
    const route = useRoute();   
    const router = useRouter() 
    const isLogged = computed(() => store.getters.manageUser_logged);
    const widthSize = ref(window.innerWidth)
    provide('widthSize', widthSize)

    const formatDate = (fecha) => {
      if(fecha != null)
      {
        fecha = new Date(fecha)
        const dia = fecha.getDate().toString().padStart(2, '0');
        const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio = fecha.getFullYear().toString();

        return `${dia}/${mes}/${anio}`;
      }
      return '-';
    }
    
    provide('formatDate', formatDate);

    const onResize = () => { 
      widthSize.value = window.innerWidth
    }
    
    onMounted(() => {
      window.addEventListener('resize', onResize)
      setTimeout(() => {
        window.vueApp.redirectFromForm = () => {
          window.vueApp.$router.push(JSON.parse(base64url.decode(window.vueApp.$route.params.hash)).prevUrl);
        };
        
        const evalEvent = function(event) { 
          if(event.data == "FormularioView.OnFormReady('window.vueApp.redirectFromForm()')")
          {
            window.vueApp.redirectFromForm()
          }
          if(!isNaN(event.data))
          {
            document.getElementById('formIframe').style.height = event.data + 'px';
          }
        };

        window.addEventListener("message", evalEvent);

        
        setInterval(function() {
          if(isLogged)
          {
            // window.i18n.global.locale.value = persistentData.get('language').toLowerCase();
          }
        },500)
      },500);

      routeActive.value = route.name;
      if(routeActive.value != "LOGIN")
      {
        store.dispatch('manageUser_active');
      }
      if (!isLogged.value) router.push('/login')

     });

    onUnmounted(() => {
      window.removeEventListener('resize', onResize)
    })
    return {
      isLogged,
      route
    }
  },
}
</script>


<style lang="scss" >
@import "@/styles/main.scss";
html, body{
  margin: 0;
  padding:0;
  color: #000;
  //background: #f6f6f6;
  background: #fff;
  //background: rgba(72, 51, 233, 0.1);
}


#app {
  width: 100%;
  //max-width: 1420px;
  margin: 0 auto;
}

</style>
