import interceptor from '@/plugins/interceptor';
const pathController = '/CartaConstanciaCursada'

export default {
  state: () => ({

  }),
  mutations: {
  },
  actions: {
    async requestedLetters_requests() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/solicitudes`);
    }
  }
}