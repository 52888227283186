<template>
    <div class="tooltip">
        <span class="tooltip__title">{{ title }}</span>
        <p class="tooltip__description">{{ description }}</p>
    </div>
</template>

<script>
export default {
        props: ['title', 'description']
    }
</script>

<style lang="scss" scoped>
    .tooltip{
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding: 12px;
        border-radius: 10px;
        background-color: #DFDCF5;
        color: #000;
        width: 225px;
        position: absolute !important;
        z-index: 9999;
        &__title{
            font-size: 12px;
            font-weight: 600;
        }
        &__description{
            font-size: 11px;
            font-weight: 400;
        }
    }
</style>