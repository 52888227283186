import interceptor from '@/plugins/interceptor';
const pathController = '/Security'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async security_authenticate(_, model) {
      if(process.env.OFFLINE_MODE == 'enabled')
      {
        console.log(model)
        return {
          status: 200,
          data: {
            token : "test",
            expirationTime: "",
            userData : {},
            roles:['Joven']
          }
        }
      } 
      else
      {
        return await interceptor.anonymous.post(`${process.env.VUE_APP_API_URL}${pathController}/authenticate`, model);
      }
    },
    async security_refreshToken(_, token) {
      if(process.env.OFFLINE_MODE == 'enabled')
      {
        console.log(token)
        return {
          status: 200,
          data: {
            token : "test",
            expirationTime: ""
          }
        }
      }
      else
      {
        return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/refresh_token`, token);
      }
    },
    async security_forgotPassword(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_URL}${pathController}/forgot_password`, model);
    },
    async security_moodleAccess() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/moodle_access`);
    },
    async security_rukyAccess() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/ruky_access`);
    },
    async security_creadlyAccess() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/creadly_access`);
    },
  }
}