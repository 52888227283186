<template>
  <nav class="navbar">
    <template v-if="!isMobile">
      <div class="breakpoints-container">
        <router-link to="/" class="navbar__logo">
          <img src="../../assets/icons/forge-icon-black.svg" />
          <h4>Campus Forge</h4>
        </router-link>
        <ul class="navbar__options">
          <li
            v-if="user.userData.NivelPosicion == 5"
            @click="router.replace('/nivel5')"
          >
            NIVEL 5
          </li>
          <li class="navbar__profile">
            <button ref="dropdown" @click="isOpen = !isOpen">PERFIL</button>
            <transition name="fade">
              <ul v-if="isOpen" class="navbar__profile_dropdown">
                <li @click="router.replace('/profile')">
                  <i class="material-icons">account_circle</i>
                  Tu perfil
                </li>
                <li class="disabled">
                  <i class="material-icons-outlined">grading</i>
                  Mis evaluaciones
                </li>
                <li @click="showConfirmation = true">
                  <i class="material-icons-outlined">grading</i>
                  Cerrar sesión
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>
    </template>

    <template v-else>
      <i
        @click="isOpen = !isOpen"
        class="material-icons-outlined navbar__hamburger"
        >menu</i
      >
      <div :class="`navbar__mobileList${isOpen ? '--opened' : ''}`">
        <router-link @click="isOpen = false" to="/" class="navbar__logo">
          <img src="../../assets/icons/forge-icon-black.svg" />
          <h4>Campus Forge</h4>
        </router-link>
        <ul class="navbar__options">
          <li
            v-if="user.userData.NivelPosicion == 5"
            @click="
              router.replace('/nivel5');
              isOpen = false;
            "
          >
            NIVEL 5
          </li>
          <li
            @click="
              router.replace('/profile');
              isOpen = false;
            "
          >
            TU PERFIL
          </li>
          <li class="disabled">
            MIS EVALUACIONES
          </li>
          <li
            @click="
              showConfirmation = true;
              isOpen = false;
            "
          >
            CERRAR SESION
          </li>
        </ul>
      </div>
    </template>
  </nav>

  <Modal v-if="showConfirmation">
    <div class="center columns">
      <div class="text-modal center">
        <span class="full text">{{ $t('navbar.confirmLogout') }}</span>
      </div>
      <div class="buttons center rows full modal__buttons">
        <button
          :disabled="processing"
          class="btn btn-small red"
          style="background-color: inherit; color: #4833E9;"
          @click="toggleConfirmation"
        >
          {{ $t('navbar.cancelBtn') }}
        </button>
        <button
          style="background-color: #4833E9; color: #fff;"
          :disabled="processing"
          class="btn btn-small"
          @click="goLogin"
        >
          {{ $t('navbar.confirmBtn') }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Common/Modal.vue';
import { onMounted, onUnmounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
  components: {
    Modal,
  },
  setup() {
    const showConfirmation = ref(false);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const isOpen = ref();
    const dropdown = ref(null);
    const isMobile = ref(null);
    const user = computed(() => store.getters.manageUser_user);

    function toggleConfirmation() {
      showConfirmation.value = false;
    }

    function goLogin() {
      store.dispatch('manageUser_disabled');
      router.push('/Login');
      showConfirmation.value = false;
    }

    onMounted(() => {
      isMobile.value = window.innerWidth <= 600;
      document.addEventListener('click', (e) => {
        if (e.target !== dropdown.value && !isMobile.value) {
          isOpen.value = false;
        }
      });

      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth <= 600;
      });
    });

    onUnmounted(() => {
      document.removeEventListener('click', (e) => {
        if (e.target !== dropdown.value && !isMobile.value) {
          isOpen.value = false;
        }
      });

      window.removeEventListener('resize', () => {
        isMobile.value = window.innerWidth <= 600;
      });
    });
    return {
      showConfirmation,
      route,
      router,
      isOpen,
      dropdown,
      isMobile,
      toggleConfirmation,
      goLogin,
      user,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal__buttons {
  @media screen and (max-width: 345px) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.navbar {
  position: relative;
  width: 100%;
  height: 79px;
  box-shadow: 0 5px 35px 0px rgba(189, 189, 189, 0.25);
  
  .breakpoints-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (width < 600px) {
    height: 60px;
  }
  &__hamburger {
    position: relative;
    top: 25%;
    left: 18px;
    color: $lightblue;
    cursor: pointer;
    font-size: 34px;
  }
  &__mobileList {
    height: calc(100vh - 60px);
    width: 100vw;
    position: absolute;
    left: 0;
    display: none;
    background-color: #fff;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 12%;
    padding: 32px 0;
    z-index: 500;
    transition: top 0.5s ease-in-out;
    &--opened {
      display: flex;
      @extend .navbar__mobileList;
      top: 60px;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    &:hover {
      opacity: 1 !important;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    gap: 26px;
    font-size: 14px;
    font-weight: 700;
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
      font-size: 18px;
      li:last-child {
        margin-top: 32px;
        font-size: 17px;
      }
    }
    > li {
      cursor: pointer;
      transition: all 0.2s ease-out;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__profile {
    position: relative;
    &:hover {
      opacity: 1 !important;
    }
    button {
      color: $lightblue;
    }
    &_dropdown {
      position: absolute;
      right: -6px;
      top: 50px;
      width: 200px;
      padding: 24px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      animation: fade 0.3s ease-in-out;
      background-color: #fff;
      align-items: flex-start;
      z-index: 99;
      li {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 13px;
        font-weight: 600;
        i {
          color: $lightblue;
        }
      }
    }
    @keyframes fade {
      from {
        opacity: 0;
        transform: translateY(-10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-enter, .slide-leave-to /* .slide-leave-active en <2.1.8 */ {
  transform: translateY(-100%);
}

.open.slide-enter-active,
.open.slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.open.slide-enter, .open.slide-leave-to /* .slide-leave-active en <2.1.8 */ {
  transform: translateY(0);
}
</style>
