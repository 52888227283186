<template>

<div class="overlay" v-show="props.show" >
    <div class="modal">
      <div style="display: flex; width: 100%; justify-content: flex-end;">
        <i @click="emit('close')" class="material-icons" style="cursor: pointer; font-size: 22px;">close</i>
      </div>
      <h3>
        {{ $t('progress.boxPreparacionLaboral.popup.title') }}
      </h3>

      <!--tabs for array to change and display data from array-->
      <div class="tabs">
        <!--<div class="tab" v-for="(tab, index) in data" :key="index" @click="selectedTab = index" :class="{ active: selectedTab === index }">
          {{ tab.title }}
        </div>-->
        <!--placeholder for now-->
        <div class="tabs__container">
          <div 
            class="tab" 
            v-for="(interview, index) in data.Entrevistas" 
            :key="index" 
            @click="selectedTab = index" 
            :class="{ active: selectedTab === index }"
          >
            {{ $t('progress.boxPreparacionLaboral.popup.interview') }} {{ interview.Numero }}
            <!-- Conditionally show error icon if the interview was not attended -->
            <i v-if="!interview.Asistio" class="material-icons">error</i>
          </div>
        </div>
        


        <!-- view selected Entrevista -->
        <div v-if="data.Entrevistas.length > 0 && data.Entrevistas[selectedTab]">


          <div v-if="data.Entrevistas[selectedTab].Asistio">
            <div class="row">
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Interviewer') }}</span>
                </div>
                <div class="data">
                  <span>{{data.Entrevistas[selectedTab].Entrevistador}}</span>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Punctuality') }}</span>
                </div>
                <div class="data">
                  <span>
                    {{ 
                      $t(
                          'progress.boxPreparacionLaboral.popup.' + 
                          data.Entrevistas[selectedTab].Puntualidad
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Presence') }}</span>
                </div>
                <div class="data">
                  <span>
                    {{ 
                      $t(
                          'progress.boxPreparacionLaboral.popup.' + 
                          data.Entrevistas[selectedTab].Presencia
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Attitude') }}</span>
                </div>
                <div class="data">
                  <span>
                    <!-- {{data.Entrevistas[selectedTab].Actitud}} -->
                    {{ 
                      $t(
                          'progress.boxPreparacionLaboral.popup.' + 
                          data.Entrevistas[selectedTab].Actitud
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.NonVerbalCommunication') }}</span>
                </div>
                <div class="data">
                  <span>
                    <!-- {{data.Entrevistas[selectedTab].ComunicacionNoVerbal}} -->
                    {{ 
                      $t(
                          'progress.boxPreparacionLaboral.popup.' + 
                          data.Entrevistas[selectedTab].ComunicacionNoVerbal
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Employable') }}</span>
                </div>
                <div class="data">
                  <span>{{data.Entrevistas[selectedTab].Empleable == true ? 'Si' : 'No'}}</span>
                </div>
              </div>
            </div>
            <div class="row row--full">
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Strenghts') }}</span>
                </div>
                <div class="data">
                  <p>{{data.Entrevistas[selectedTab].Fortalezas}}</p>
                </div>
              </div>
              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Weaknesses') }}</span>
                </div>
                <div class="data">
                  <p>{{data.Entrevistas[selectedTab].Debilidades}}</p>
                </div>
              </div>
            </div>
          </div>


          <div v-else class="when-didnt-assist">

            <div class="when-didnt-assist__error">
              <i class="material-icons">error</i>
              <span>{{ props.studentName }} no se presentó a la entrevista pactada.</span>
            </div>

            <div class="row row--full">

              <div class="column">
                <div class="reference">
                  <span>{{ $t('progress.boxPreparacionLaboral.popup.Interviewer') }}</span>
                </div>
                <div class="data">
                  <span>{{data.Entrevistas[selectedTab].Entrevistador}}</span>
                </div>
              </div>
              
            </div>

          </div>

        

        </div>




    </div>

      
    </div>
  </div>

</template>

<script setup>
import { defineProps, defineEmits, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const props = defineProps({
  show: Boolean,
  studentName: String
});

const emit = defineEmits(['close']);

const route = useRoute();

const data = ref({
  Entrevistas: []
});

const selectedTab = ref(0);

const store = useStore();

async function getData() {
  try {
    let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'PREPARACION_LABORAL', type: 'DETALLE' })
    data.value = req.data;
    
    // Mock a second interview if only one is present
/*     if (data.value.Entrevistas.length === 1) {
      const secondInterview = JSON.parse(JSON.stringify(data.value.Entrevistas[0])); // Deep copy the first interview
      secondInterview.Numero = 2; // Change the number to 2
      secondInterview.Entrevistador = "John Doe"; // Change interviewer's name
      secondInterview.Asistio = false; // Change attendance status to simulate an unattended interview
      data.value.Entrevistas.push(secondInterview); // Add the second mock interview
    }
 */  } catch (error) {
    console.error('Error fetching interview data:', error);
    store.dispatch('notifications_create', { text: 'Error al obtener detalles de preparación laboral.' });
  }
}

onMounted(async () => {
  await getData();
});
</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.modal {
  background: #fff;
  box-shadow: $boxesShadow;
  padding: $margin-kilo;
  text-align: center;
  max-width: 80vw;
  height: auto;
  border: 3px solid #fff;
  margin-top: $margin-base;
  z-index: 14;
  position: relative;
  margin-bottom: $margin-kilo;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  min-height: 573px;
  width: 100%;
  max-width: 602px;
  border-radius: $cardRadius;
  h3{
    text-align: left;
    margin-bottom: 20px;
  }

  .tabs {
    .tabs__container{
        display: flex;
        background: #FFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

        //scrolleable tabs
        overflow-x: auto;

        .tab {
            padding: $margin-kilo;
            border-bottom: 5px solid transparent;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;

            &.active{
                border-bottom: 5px solid $lightblue;
            }

            i {
                color: $lightblue;
            }
        }
    }

    .when-didnt-assist__error {
      margin-top: 24px;
      background: rgba(245, 112, 85, 0.20);
      color: $red;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      padding: 16px;
      gap: 16px;
    }

    .row {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // Creates three columns with equal width
        gap: 24px; // Spacing between grid items
        margin-top: 24px; // Spacing below the row

        @media (max-width:980px) {
            grid-template-columns: repeat(2, 1fr);
        }

        &--full {
            grid-template-columns: repeat(1, 1fr);
        }

        

        .column {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            gap: 8px;
            text-align: left;

            .reference {
                span {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .data {
                span {
                    font-size: 14px;
                    font-weight: 400;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 14;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

</style>
