<template>
  <div class="page-content full center">
    <div class="full">
      <span class="page-tittle full">Cartas solicitadas</span>
    </div>
    <div v-for="item in RequestedLetters" :key="item.id" class="card full">
      <div class="card__head full rows">
        <div class="top rows full center">
          <div class="rows full">
            <img src="@/assets/icons/card.svg" alt="" />
            <h3>{{ item.ConfiguracionNombre }}</h3>
          </div>
        </div>
      </div>
      <div class="card__content full">
        <div class="indications__tittle full">
          {{ "Estado: " + item.Estado }}
        </div>
        <div v-if="item.ComentarioRechazado" class="indications__tittle full">
          {{ "Comentario: " + item.ComentarioRechazado }}
        </div>
        <div
          v-if="item.FechaDescargaHabilitada"
          class="indications__tittle full"
        >
          {{ "Disponible desde: " + item.FechaDescargaHabilitada }}
        </div>
        <div v-if="item.Url" class="indications__tittle full">
          Link Descarga:
          <a :href="item.Url" class="indications__tittle">Descargar aquí</a>
        </div>
      </div>
    </div>
    <router-link to="/Letters">
      <div class="add-btn center pointer">
        <img src="@/assets/icons/plus.svg" alt="" class="full" />
      </div>
    </router-link>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: {},
  data() {
    return {
      reRender: 0,
    };
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const loading = ref(false);
    const RequestedLetters = ref([]);

    onMounted(async () => {
      const request = await store.dispatch("requestedLetters_requests", {});
      if (request.status === 200) {
        RequestedLetters.value = request.data;
        console.log(RequestedLetters);
      } else {
        store.dispatch("notifications_create", {
          text: t(`notifications.${request.data}`),
        });
        loading.value = false;
      }
    });

    return {
      RequestedLetters,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin: $margin-milli;
  max-width: 480px;
}

/* .page-content {
    position: relative;
} */
a {
  text-decoration: inherit;
  color: #0000ee;
}
</style>