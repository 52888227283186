<template>
  <Loader v-if="!attendanceLoaded" />
  <div  class="monitoring" v-else>
    <div class="monitoring__back" @click="goBack">
      <i class="material-icons">arrow_back</i> {{ $t('global.back') }}
    </div>
    
    <div class="monitoring__header">
      <div>
        <strong>{{ $t('attendanceMonitoring.attendanceMonitoring') }}</strong>
        <span class="download disabled" style="cursor: pointer"><i class="material-icons">save_alt</i> {{ $t('attendanceMonitoring.download') }}</span>
      </div>
      <div class="monitoring__header__detail bx-shadow">
        <div class="monitoring__header__detail__text">
          <span><strong>{{ $t('attendanceMonitoring.class') }}:</strong> {{ attendanceData.claseNombre }}</span>
          <span><strong>{{ $t('attendanceMonitoring.date') }}:</strong> {{ formatDate(attendanceData.claseFecha) }}</span>
          <span><strong>{{ $t('attendanceMonitoring.activeParticipants') }}:</strong> {{ attendanceData.total }}</span>
        </div>
        <div class="monitoring__header__detail__assistance">
          <div class="assistance__item assistance__item__green">
            <div class="assistance__item__qty">{{ attendanceData.presentes }}</div>
            <div class="assistance__item__detail">
              <strong class="assistance__item__detail__percentage">{{ Math.round(attendanceData.presentes * 100 / attendanceData.total) }}%</strong>
              <strong>{{ $t('attendanceMonitoring.present') }}</strong>
            </div>
          </div>
          <div class="assistance__item assistance__item__red">
            <div class="assistance__item__qty">{{ attendanceData.ausentes }}</div>
            <div class="assistance__item__detail">
              <strong class="assistance__item__detail__percentage">{{ Math.round(attendanceData.ausentes * 100 / attendanceData.total) }}%</strong>
              <strong>{{ $t('attendanceMonitoring.absent') }}</strong>
            </div>
          </div>
          <div class="assistance__item assistance__item__yellow">
            <div class="assistance__item__qty">{{ attendanceData.tardes }}</div>
            <div class="assistance__item__detail">
              <strong class="assistance__item__detail__percentage">{{ Math.round(attendanceData.tardes * 100 / attendanceData.total) }}%</strong>
              <strong>{{ $t('attendanceMonitoring.late') }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>


    <strong>{{ $t('attendanceMonitoring.detail') }}</strong>
    <div class="table__container" :style="{ paddingBottom: optionsIsEnabled() ? '150px' : '10px'}" >
      <div class="table bx-shadow">
        <span class="table__title">{{ $t('attendanceMonitoring.participant') }}</span>
        <span class="table__title">{{ $t('attendanceMonitoring.date') }}</span>
        <span class="table__title">
          {{ $t('attendanceMonitoring.status') }} 
          <i @mouseover="statusTooltip = true" @mouseleave="statusTooltip = false" class="material-icons" style="color: #4833E9; font-size: 16px;">info</i>
          <Tooltip 
            v-if="statusTooltip"
            :title="$t('attendanceMonitoring.modifyStatusHint')"
            :description="$t('attendanceMonitoring.modifyStatusDescription')"
          />
        </span>
        <span class="table__title"></span>
        <template v-for="(alumno, idx) in attendancePagination()">
          <span class="table__data" style="font-weight: 600;" v-if="true" :key="idx + 'student'">{{ alumno.nombreCompleto }}</span>
          <span class="table__data" v-if="true" :key="idx + 'competence'">{{ formatDate(alumno.fecha) }}</span>
          <div @click="handleStatus(idx)" class="table__data" v-if="true" :key="idx + 'status'">
            <span :class="`attendance--${alumno.estado} ${setLoadingStatus[idx] ? 'attendance--sending' : ''}`">
              {{ $t(`attendance.${alumno.estado}`) }}
              <i class="material-icons">
                {{ alumno.estado === 0
                  ? 'check_circle' 
                  : alumno.estado === 4 || alumno.estado === 5 
                    ? 'access_time'
                    : 'cancel'
                  }}
              </i>
            </span> 
            <div v-if="attendanceTooltip[idx]" class="attendance__list bx-shadow">
              <span @click="handleChangeStatus(alumno, 0, idx)" class="attendance__variant">
                <div style="background-color: #00C58F;"></div>
                {{ $t('attendanceMonitoring.presentWithNotice') }}
              </span>
              <span @click="handleChangeStatus(alumno, 5, idx)" class="attendance__variant">
                <div style="background-color: #F62E53;"></div>
                {{ $t('attendanceMonitoring.absentWithNotice') }}
              </span>
              <span @click="handleChangeStatus(alumno, 2, idx)" class="attendance__variant">
                <div style="background-color: #F62E53;"></div>
                {{ $t('attendanceMonitoring.absentWithoutNotice') }}
              </span>
              <span @click="handleChangeStatus(alumno, 4, idx)" class="attendance__variant">
                <div style="background-color: #FFB23F;"></div>
                {{ $t('attendanceMonitoring.lateWithNotice') }}
              </span>
              <span @click="handleChangeStatus(alumno, 1, idx)" class="attendance__variant">
                <div style="background-color: #FFB23F;"></div>
                {{ $t('attendanceMonitoring.lateWithoutNotice') }}
              </span>
            </div>
          </div>
          <form :class="`table__data ${setLoadingComment[idx] ? 'attendance--sending' : ''}`" @submit.prevent="sendMessage(alumno, comment[(attendanceData.page * 10 - 10) + idx], idx)" v-if="true" :key="idx + 'check'" >
            <input type="text" name="" id="" placeholder="Comentarios" class="input" v-model="comment[(attendanceData.page * 10 - 10) + idx]">
            <button type="submit" :class="`material-icons-outlined sendIcon ${setLoadingComment[idx] ? 'attendance--sending' : ''}`">send</button>
          </form>
        </template>
        <div class="table__pagination">
          <button @click="attendanceData.page--" :disabled="attendanceData.page === 1" ><i class="material-icons-outlined">arrow_forward</i>{{ $t('attendanceMonitoring.previous') }}</button>
          <span style="border-bottom: 0;"><strong>{{ $t('attendanceMonitoring.page') }} {{ attendanceData.page }}</strong> / {{ attendanceData.totalPages }}</span>
          <button @click="attendanceData.page++" :disabled="attendanceData.page === attendanceData.totalPages">{{ $t('attendanceMonitoring.next') }} <i class="material-icons-outlined">arrow_forward</i></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Tooltip from '../../components/Tooltip.vue';
import Loader from '../../components/Loader.vue';
import { useStore } from 'vuex';

import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const formatDate = inject("formatDate");
    const statusTooltip = ref(false)
    const attendanceTooltip = ref({});
    const dateCourse = ref(new Date());
    const route = useRoute()
    const experimentalResults = ref([]);
    const attendanceData = ref()
    const attendanceLoaded = ref(false)
    const store = useStore()
    const comment = ref({})
    const setLoadingComment = ref({})
    const setLoadingStatus = ref({})
    const refreshInterval = ref(null)
    const qtyPresents = ref({
      present: 0,
      absent: 0,
      late: 0
    })


    const attendancePagination = () => {
      let start = attendanceData.value.page === 1 ? 0 : (attendanceData.value.page - 1) * 10
      let end = attendanceData.value.page * 10
      return attendanceData.value.alumnos?.slice(start, end)
    }

    const optionsIsEnabled = () => {  
      for (let i in attendanceTooltip.value) {
        console.log(attendanceTooltip.value[i])
        if(attendanceTooltip.value[i]) return true
      }
      return false
    }

    const sendMessage = async (alumno, comment, idx) => {
      if(!comment.trim().length) return
      if (setLoadingComment.value[idx]) return
      let req = {
        AlumnoId: alumno.id,
        ClaseId: route.params.id,
        Estado: alumno.estado,
        Comentario: comment.trim()
      }
      try {
        setLoadingComment.value[idx] = true
        await store.dispatch('set_asistencia', req)
        store.dispatch("notifications_create", { text: t('notifications.message-send-ok') })
      } catch (err) {
        store.dispatch("notifications_create", {text: t('notifications.message-send-error') })
      } finally {
        setLoadingComment.value[idx] = false
      }
    } 

    const handleStatus = (idx) => {
      // eslint-disable-next-line no-prototype-builtins
      if (attendanceTooltip.value.hasOwnProperty(idx)) {
        if (attendanceTooltip.value[idx]) {
          Object.keys(attendanceTooltip.value).forEach(v => attendanceTooltip.value[v] = false)
          return
        }
      }
      Object.keys(attendanceTooltip.value).forEach(v => attendanceTooltip.value[v] = false)
      attendanceTooltip.value[idx] = true
      return
  }
    const handleChangeStatus = async (alumno, status, idx) => {
      if (setLoadingStatus.value[idx]) return
      let req = {
        AlumnoId: alumno.id,
        ClaseId: route.params.id,
        Estado: status,
        Comentario: alumno.comentario
      }
      try {
        setLoadingStatus.value[idx] = true
        await store.dispatch('set_asistencia', req)
        refreshData()
        store.dispatch("notifications_create", { text: t('notifications.status-change-ok') })
      } catch (err) {
        store.dispatch("notifications_create", {text: t('notifications.status-change-error') })
      } finally {
        setLoadingStatus.value[idx] = false
      }
    }

    const getData = async () => {
      try {
        attendanceLoaded.value = false
        const { data } = await store.dispatch('get_asistencia', route.params.id)
        attendanceData.value = data.Data
        attendanceData.value.totalPages = Math.ceil(data.Data.alumnos.length / 10)
        attendanceData.value.page = 1
        attendanceData.value.alumnos.forEach((alumno, idx) => comment.value[idx] = alumno.comentario)
      } catch (err) {
        console.log(err)
        store.dispatch('notification_create', { text: t('notifications.data-error') })
      } finally {
        attendanceLoaded.value = true
      }
    }

    const refreshData = async () => {
      let page = attendanceData.value.page
      let totalPages = attendanceData.value.totalPages
      const { data } = await store.dispatch('refresh_asistencia', route.params.id)
      attendanceData.value = data.Data
      attendanceData.value.page = page
      attendanceData.value.totalPages = totalPages
    }

    onMounted(() => {
      getData()
      refreshInterval.value = setInterval(refreshData, 5000)
    });

    onUnmounted(() => {
      clearInterval(refreshInterval.value)
    })
    
      
      function goBack() {
          router.go(-1);
      }
      return {
        dateCourse,
        goBack,
        formatDate,
        experimentalResults,
        statusTooltip,
        attendanceTooltip,
        qtyPresents,
        attendanceData,
        attendanceLoaded,
        handleStatus,
        handleChangeStatus,
        setLoadingComment,
        sendMessage,
        comment,
        attendancePagination,
        setLoadingStatus,
        optionsIsEnabled
      };
  },
    components: { Tooltip, Loader }
}
</script>
<style lang="scss" scoped>

.input{
  border: none;
  border-radius: 50px;
  background-color: #f6f6f6;
  width: 100%;
  padding: 12px 28px;
  padding-right: 46px;
  &::placeholder{
    color: #212121;
    font-size: 13px;
  }
}

.attendance{
  font-weight: 600;
  font-size: 11px;
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: center;
  justify-content: center;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  width: 150px;
  cursor: pointer;
  &--sending{
    cursor: default;
    opacity: .4;
  }
  &__list{
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 18px;
    background-color: #fff;
    border-radius: 10px;
    width: max-content;
    position: absolute;
    top: 40px;
    z-index: 9999;
  }
  &__variant{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #212121;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    transition: all .1s ease-in-out;
    &:hover{
      transform: scale(1.05);
    }
    div{
      width: 18px;
      height: 18px;
      border-radius: 9999px;
    }
  }
  .material-icons{
    font-size: 14px;
    line-height: 0
  }
  &--0{
    @extend .attendance;
    background-color: #00C58F;
  }
  &--2, &--5, &--3, &---1{
    @extend .attendance;
    background-color: #FF3B66;
  }
  &--1, &--4{
    @extend .attendance;
    background-color: #FFB600;
  }
}

.sendIcon{
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 36px;
  transition: all .2s ease;
  font-size: 18px;
  &:hover{
    transform: scale(1.1);
  }
}

.monitoring {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 79px);
  gap: 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 24px 1.5%;
  padding-bottom: 42px;
  &__back {
    color: $lightblue;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 4px;

    i {
      font-size: 16px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0 5px;
    div {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: lighter;
      }

      .download {
        display: flex;
        align-content: center;
        gap: 12px;
        font-weight: bold;
        color: #009696;
        cursor: pointer;
      }

    }

    &__detail {
      padding: 24px 38px;
      @media (max-width: 860px){
        flex-direction: column;
        gap: 24px;
      }
      &__text {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__assistance {
        display: flex;
        gap: 32px;
      @media (max-width: 860px){
        flex-wrap: wrap;
        justify-content: center !important;
      }
        .assistance__item {
          display: flex;
          align-items: center;
          gap: 12px;

          &__green {
            .assistance__item__qty {
              background: #00C58F;
            } 
            .assistance__item__detail__percentage {
              color: #00C58F; 
            }
          }

          &__yellow {
            .assistance__item__qty {
              background: #FFB600;
            } 
            .assistance__item__detail__percentage {
              color: #FFB600; 
            }
          }


          &__red {
            .assistance__item__qty {
              background: #FF3B66;
            } 
            .assistance__item__detail__percentage {
              color: #FF3B66; 
            }
          }



          &__qty {
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: bold;
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          &__detail {
            display: flex;
            flex-direction: column;
            gap: 4px;
            justify-content: center;
          }
        }
      }
    }

  }
}

.table{
  display: grid;
  grid-template-columns: repeat(3, minmax(150px, 1fr)) minmax(315px ,2fr);
  gap: 12px 0;
  width: 100%;
  padding: 24px 38px;
  border-radius: 5px;
  min-width: 960px;
  overflow: visible;
  &__container{
    overflow: auto;
    padding: 0 5px;
    max-height: 1100px;
    height: max-content;
  }
  &__title{
    font-size: 12px !important;
    font-weight: 700 !important;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    .material-icons{
      cursor: default;
    }
    .tooltip{
      top: 18px;
      right: 60%;
    }
  }
  &__data{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #BDBDBD;
    text-align: start;
    padding-right: 12px;
    padding-bottom: 12px;
    position: relative;
  }
  &__review{
    transition: all .2s ease-in-out;
    &:hover{
      opacity: .8;
    }
    cursor: pointer;
    color: $blue;
  }
  &__pagination{
    grid-column-start: 1;
    grid-column-end: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      strong{
        font-size: 16px;
        font-weight: 600;
      }
    }
    button{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      gap: 6px;
      cursor: pointer;
      color: #000;
      &:disabled{
        color: #000;
        opacity: .8;
        cursor: default;
      }
      &:first-child{
        .material-icons-outlined{
            transform: rotateY(180deg)
        }
      }
      .material-icons-outlined{
        font-size: 16px;
      }
    }
  }
} 

.tooltip {
  position: relative;
  opacity: 1 !important;

  &:hover &-options {
    display: block;
  }
  
  &-options {
    display: none;
    position: absolute;
    background: white;
    width: 180px;
    left: 40px;
    // top: 16px;
    padding: 4px;
    border-radius: 12px;

    ul li {
      display: flex;
      gap: 8px;
      padding: 4px;
      color: $black;
    }
  }
}
</style>