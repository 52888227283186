<template>
    <div class="etapa" v-if="loaded" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">

        <div class="title">
            <h2>{{ $t('dashboard.stage.stage') }} {{data.Numero}}</h2>
            <span>{{data.Desde}} - {{data.Hasta}}</span>
        </div>

        <h3>{{ $t('dashboard.stage.workshops') }}</h3>

        <div class="talleres">
            <h4>{{ data.EsDeTalleresObligatorios ? $t('dashboard.stage.mandatoryInitials') : $t('dashboard.stage.chosenByYou') }}</h4>
            <span v-if="data.Estado == 'CUMPLIDO' && ( (data.EstaTrabajando && data.EsDeTalleresObligatorios) || !data.EstaTrabajando)">{{ $t('dashboard.stage.metGoal') }}</span>
            <span v-else-if="data.Estado == 'INCUMPLIDO' && data.EstaFinalizada">{{ $t('dashboard.stage.didNotMeetGoal') }}</span>
            <span v-else-if="data.Estado == 'SUPERADO'">{{ $t('dashboard.stage.exceededGoal') }}</span>
        </div>

        <div class="bonus__info" v-if="data.EstaTrabajando && !data.EsDeTalleresObligatorios">{{ $t('dashboard.stage.allWorkshopsAreOptional') }}</div>
        
        <div class="progress" v-else>
            <div :style="`width: ${data.Talleres.Porcentaje}%; 
                ${data.Talleres.Porcentaje == 0 ? 'background:#EDEBFD ;color:#4833E9;' : ''}`
            ">
                {{ data.Talleres.Realizados+'/'+data.Talleres.Totales }}
            </div>
        </div>

        <div class="bonus">
            <h4>{{ $t('dashboard.stage.bonus') }}</h4>
            <div v-if="data.Bonus" class="bonus__stars" >
                <svg v-for="i in data.Bonus" :key="i" width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="22.4999" cy="22.5" r="22.5" fill="#DCCDFE"/>
                    <path d="M21.9999 28.77L28.1799 32.5L26.5399 25.47L31.9999 20.74L24.8099 20.13L21.9999 13.5L19.1899 20.13L11.9999 20.74L17.4599 25.47L15.8199 32.5L21.9999 28.77Z" fill="#4833E9"/>
                </svg>

            </div>
            <div v-else class="bonus__info">
                <template v-if="data.EsDeTalleresObligatorios">
                    {{ $t('dashboard.stage.inThisStage') }}
                </template>
                <template v-else-if="data.Talleres.Realizados < data.Talleres.Totales && !data.EstaTrabajando">{{ $t('dashboard.stage.completeRequiredWorkshops') }}</template>
                <template v-else-if="data.Talleres.Realizados == data.Talleres.Totales && data.Bonus == 0 && !data.EstaTrabajando">{{ $t('dashboard.stage.noAdditionalWorkshops') }}</template>
                <template v-else-if="data.EstaTrabajando && data.Bonus == 0">{{ $t('dashboard.stage.notSignedUpForAnyWorkshop') }}</template>
            </div>
        </div>

        <div class="entrevista">
            <h3>{{ $t('dashboard.stage.interview') }} #{{data.Numero}}</h3>
            <span v-if="data.EntrevistaEstado == 'COMPLETO'" style="color:#00C58F">{{ $t('dashboard.stage.complete') }}<i class="material-icons">check_circle</i></span>
            <span v-if="data.EntrevistaEstado == 'INCOMPLETO'" style="color:#FF3B66">{{ $t('dashboard.stage.incomplete') }}<i class="material-icons">highlight_off</i></span>
            <span v-if="data.EntrevistaEstado == 'PENDIENTE'" style="color:#FFB600">{{ $t('dashboard.stage.pending') }}<i class="material-icons">highlight_off</i></span>
        </div>

        <div class="select-etapa">
            <button v-for="(etp,idx) in etapas" :key="idx" @click="selectEtapa(idx)" :style="{backgroundColor: idx == selectedIdx ? '#4833E9' : '#fff'}"></button>
        </div>
        
    </div>
</template>
  
<script setup>
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';
import formatDate from '@/plugins/formatDate.js'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

 const props = defineProps({
    etapas: Array,
    selectedEtapa: Number
 })
 const emit = defineEmits(['changeEtapa']);
 const etapas = ref(null);
 const data = ref(null);
 const loaded = ref(false);
 const selectedIdx = ref(0);

 const selectEtapa = (idx) => {
    data.value = etapas.value[idx];
    selectedIdx.value = idx;
    emit('changeEtapa', data.value.Numero);
 }

 watch(()=>props.selectedEtapa,()=>{
    selectEtapa(etapas.value.indexOf(etapas.value.find(et => et.Numero == props.selectedEtapa)));
 })

 onMounted(()=>{
    etapas.value = props.etapas;
    etapas.value.forEach(et => { et.Desde =  formatDate(et.Desde, t, true); et.Hasta =  formatDate(et.Hasta, t, true);  })
    selectEtapa(etapas.value.indexOf(etapas.value.find(et => et.Numero == props.selectedEtapa)));
    loaded.value = true;
 })


// Handle card swipe ---

let touchStartX = 0;
let isDragging = false;

const handleTouchStart = (event) => {
  touchStartX = event.touches[0].clientX;
  isDragging = true;
}

const handleSwipe = (direction) => {
  if(direction == 'next' && selectedIdx.value < etapas.value.length-1){
    selectEtapa(selectedIdx.value+1)
  }
  if(direction == 'prev' && selectedIdx.value > 0){
    selectEtapa(selectedIdx.value-1)
  }
}

const handleTouchMove = (event) => {
  if (!isDragging) return; 
  const touchEndX = event.touches[0].clientX;
  const deltaX = touchEndX - touchStartX;
  const sensitivity = 50;
  if (Math.abs(deltaX) > sensitivity) {
    const direction = deltaX > 0 ? 'prev' : 'next';
    handleSwipe(direction);
    isDragging = false; 
  }
}

const handleTouchEnd = () => {
  touchStartX = 0;
};

</script>

<style lang="scss" scoped>
.etapa{
    width: 363px;
    min-height: 380px;
    padding: 20px 26px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background: #fff;
    box-shadow: 5px 5px 35px 0px rgba(224, 224, 224, 0.50);
    @media(width < 600px){
        width: 100%;
    }
    h2{
        font-size: 20px;
        font-weight: 700;

    }
    h3{
        font-size: 20px;
        font-weight: 500;
    }
    h4{
        font-size: 14px;
        font-weight: 400;
    }
    .title{
        display: flex;
        gap: 12px;
        align-items: flex-end;
        span{
            padding-bottom: 2px;
            color: #BDBDBD;
            font-size: 16px;
            font-weight: 500;
        }
    }
    @media(width < 600px){
        h2,h3{
            font-size: 16px;
        }
        .title span{
            font-size: 14px;
        }
    }
    .talleres{
        display: flex;
        align-items: center;
        gap: 12px;
        span{
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 12px;
            font-weight: 400;
            color: #4833E9;
        }
    }
    .progress{
        position: relative;
        width: 100%;
        height: 20px;
        border-radius: 50px;
        background: #EDEBFD;
        >div{
            position: absolute;
            //width: 80%;
            height: 20px;
            padding-left: 22px;
            border-radius: 50px;
            background: #4833E9;
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 12px;
            letter-spacing: 1px;
        }
    }
    .bonus{
        margin-top: 10px;
        h4{
            margin-bottom: 10px;
        }
        &__stars{
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
        &__info{
            color: #4833E9;
            font-size: 15px;
            font-weight: 400;
        }
    }
    .entrevista{
        margin: 12px 0;
        h3{
            font-size: 20px;
            font-weight: 500;
            @media(width < 600px){
                font-size: 16px;
            }
        }
        span{
            margin-top: 8px;
            width: fit-content;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: 10px;
            font-weight: 600;
            border-radius: 4px;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
            i{
                font-size: 12px;  
            }
        }
    }
    .select-etapa{
        display: flex;
        justify-content: center;
        gap: 16px;
        button{
            width: 10px;
            height: 10px;
            padding: 0;
            border: 1px solid #4833E9;
            border-radius: 50%;
        }
    }
} 
</style>