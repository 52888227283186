<template>
  <div class="overlay" >
    <div class="modal">
      <div style="display: flex; width: 100%; justify-content: flex-end;">
        <!-- <i @click="onClose" class="material-icons" style="cursor: pointer; font-size: 22px;">close</i> -->
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default { 
    emits: ['onClose'],
    setup(props,{ emit }) {
      const onClose = () => emit('onClose')
      return {
        onClose
      }
    }
  }
</script>
<style lang="scss" scoped>
.modal {
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: $margin-small;
  text-align: center;
  max-width: 80vw;
  height: auto;
  border: 3px solid #fff;
  margin-top: $margin-base;
  z-index: 14;
  position: relative;
  margin-bottom: $margin-kilo;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 14;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}
</style>
