<template>
  <div class="login__container">
    <header class="header">
      <div class="header__img">
        <a href="/Home"
          ><img
            src="../../../assets/images/logo-fundacion-forge-footer.png"
            alt=""
        /></a>
      </div>
    </header>
    <div v-if="loginMode" class="body">
      <div class="body__image">
        <img src="../../../assets/images/students.svg" alt="" />
      </div>
      <div class="card">
        <div class="card__header">
          <h3>{{$t('login.title')}}</h3>
        </div>
        <div class="card__content">
          <button
            class="pointer"
            id="google"
            @click="authenticateGoogle"
            :disabled="processing"
          >
            {{$t('login.startWithGoogle')}}
          </button>
          <hr />
          <form @submit.prevent="login" style="display: flex; flex-direction: column; gap: 16px; margin-bottom: 8px;" >
            <Input 
              v-model="loginData.username"
              id="username"
              :label="$t('login.username')"
              :placeholder="$t('login.username')"
            />
            <Input 
              v-model="loginData.password"
              id="password"
              type="password"
              :validatePasswordCharacters="true"
              :label="$t('login.password')"
              :placeholder="$t('login.password')"
            />
            <div class="card__recover">
              <a @click="handleMode" class="pointer"> {{$t('login.forgotPassword')}}</a>
            </div>
  
            <button class="pointer" @click="login" :disabled="processing">
              {{$t('login.loginBtn')}}
            </button>
          </form>

          <FollowUs />
        </div>
      </div>
    </div>
    <div v-if="!loginMode" class="body">
      <div class="body__image">
        <img src="../../../assets/images/forgot-password.svg" alt="" />
      </div>
      <div class="card" id="recover__form">
        <div class="card__header">
          <h3>{{$t('login.recoveryPasswordTitle')}}</h3>
        </div>
        <div class="card__content">
          <Input 
            placeholder="Email"
            label="Email"
            id="recoverPassword"
            v-model="recoverPassEmail"  
            style="margin-bottom: 12px;"
          />
          <div class="card__recover">
            <a @click="handleMode" class="pointer">
              {{$t('login.backToLogin')}}
            </a>
          </div>
          <button class="pointer" @click="forgotPassword">{{$t('login.recoveryBtn')}}</button>
        </div>
      </div>
    </div>
    <footer></footer>
  </div>
  <ExternalLogin
    ref="externalLoginCtrl"
    :username="loginData.username"
    :password="loginData.password"
  />
</template>
<script>
import { useStore } from "vuex";
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import persistentData from "@/plugins/persistentData";
import ExternalLogin from "@/components/Common/ExternalLogin.vue";
import FollowUs from "@/components/Common/FollowUs.vue";
import gAuth from "vue3-google-auth";
import Input from "../../../components/Input.vue";
import base64url from 'base64url';

export default {
  components: {
    FollowUs,
    ExternalLogin,
    Input
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const loading = ref(false);
    const loginData = ref({ username: "", password: "" });
    const loginMode = ref(true);
    const recoverPassEmail = ref("");
    const processing = ref(false);
    const externalLoginCtrl = ref();
    

    onMounted(async () => {
      await store.dispatch("manageUser_disabled");
    });

    function handleMode() {
      loginMode.value = !loginMode.value;
    }

    let isGoogleInit = false;
    const $gAuth = gAuth.useGAuth();
    const checkGAuthLoadInterval = setInterval(() => {
      isGoogleInit = $gAuth.isInit;
      if (isGoogleInit) clearInterval(checkGAuthLoadInterval);
    }, 1000);

    async function authenticateGoogle() {
      if (!isGoogleInit) {
        store.dispatch("notifications_create", {
          text: t(`notifications.cant-login-with-google`),
        });
        return;
      }

      loading.value = true;
      try {
        const authCode = await $gAuth.signIn();
        try {
          const request = await store.dispatch("security_authenticate", {
            username: "",
            password: "",
            googleId: authCode.getId(),
          });
          if (request.status === 200) {
            persistentData.set("token", request.data.token);
            persistentData.set("tokenExpiration", request.data.tokenExpiration);
            persistentData.set("user", JSON.stringify(request.data));
            persistentData.set("language", 'ES');
            store.dispatch("manageUser_active");
            router.push("/");
          }
          loading.value = false;
        } catch (e) {
          if (typeof e.response.data == "string")
            store.dispatch("notifications_create", {
              text: t(`notifications.${e.response.data}`),
            });
          else
            store.dispatch("notifications_create", {
              text: t(`notifications.generic-error`),
            });
          loading.value = false;
        }
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    }


    async function login() {
      if (loginData.value.username !== "" && loginData.value.password !== "") {
        try {
          processing.value = true;
          const request = await store.dispatch("security_authenticate", {
            username: loginData.value.username,
            password: loginData.value.password,
            googleId: "",
          });
          if (request.status === 200) {
            if (
              request.data.roles.length == 1 &&
              request.data.userData.RoleStr == "Joven"
              ) {
                persistentData.set("token", request.data.token);
                persistentData.set(
                  "tokenExpiration",
                  request.data.expirationTime
                );
                persistentData.set(
                  "tokenValidation",
                  base64url.encode(JSON.stringify({ username: loginData.value.username, password: loginData.value.password }))
                );
                
                persistentData.set("user", JSON.stringify(request.data));
                router.push("/");
                store.dispatch("manageUser_active");
            } else if (
              request.data.roles.length == 1 &&
              request.data.userData.RoleStr == "FacilitadorForge"
            ) {
              // if (request.data.userData.MoodleAdmin) {
              //   try {
              //     externalLoginCtrl.value.submitForm();
              //     router.push("/");
              //   } catch (e) {
              //     store.dispatch("notifications_create", {
              //       text: t(`notifications.${e.response.data}`),
              //     });
              //   }
              // } else {
              //   persistentData.set("token", request.data.token);
              //   persistentData.set(
              //     "tokenExpiration",
              //     request.data.expirationTime
              //   );
              //   persistentData.set("user", JSON.stringify(request.data));
              //   store.dispatch("manageUser_active");
              //   const requestMoodle = await store.dispatch(
              //     "security_moodleAccess"
              //   );
              //   store.dispatch("manageUser_disabled");
              //   if (requestMoodle.status === 200) {
              //     store.dispatch("notifications_create", {
              //       text: "Accediendo al campus...",
              //     });
              //     window.open(requestMoodle.data.Data.UrlSSO, "_self").focus();
              //   }
              // }
            // } else {
              persistentData.set("token", request.data.token);
              persistentData.set(
                "tokenExpiration",
                request.data.tokenExpiration
              );
              
              persistentData.set(
                  "tokenValidation",
                  base64url.encode(JSON.stringify({ username: loginData.value.username, password: loginData.value.password }))
                );
              persistentData.set("user", JSON.stringify(request.data));
              router.push("/");
              store.dispatch("manageUser_active");
            }
          }
        } catch (e) {
          processing.value = false;
          if (e.response && typeof e.response.data == "string")
            store.dispatch("notifications_create", {
              text: t(`notifications.${e.response.data}`),
            });
          else
            store.dispatch("notifications_create", {
              text: t(`notifications.generic-error`),
            });
        }
      } else {
        processing.value = false;
        store.dispatch("notifications_create", {
          text: "Complete los campos para iniciar sesión",
        });
      }
    }

    async function forgotPassword() {
      if (recoverPassEmail.value !== "") {
        console.log(recoverPassEmail.value);
        try {
          const request = await store.dispatch("security_forgotPassword", {
            email: recoverPassEmail.value,
            role: 1,
          });
          if (request.status === 200) {
            store.dispatch("notifications_create", {
              text: t(`notifications.login.emailWillBeSend`),
            });
            loginMode.value = true;
          }
        } catch (e) {
          if (typeof e.response.data == "string")
            store.dispatch("notifications_create", {
              text: t(`notifications.${e.response.data}`),
            });
          else
            store.dispatch("notifications_create", {
              text: t(`notifications.generic-error`),
            });
        }
      } else {
        store.dispatch("notifications_create", {
          text: t(`notifications.login.pleaseCompleteEmail`),
        });
      }
    }

    return {
      loginData,
      loginMode,
      loading,
      processing,
      externalLoginCtrl,
      forgotPassword,
      recoverPassEmail,
      authenticateGoogle,
      login,
      handleMode,
    };
  },
};
</script>
<style lang="scss" scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0px;
}

.login{
  &__container{
    background-color: #f4f8fb;
    height: 100vh;
  }
}

.header {
  max-width: 100%;
  max-height: 100px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 960px) {
    height: 120px;
    justify-content: left;
    &__img {
      padding: 10px;
    }
  }

  &__img {
    height: 80px;

    img {
      height: 100%;
    }
  }
}
.body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  align-items: center;
  &__image {
    display: none;
  }

  @media (min-width: 960px) {
    padding: 0;
    background-color: #f4f8fb;

    .card {
      width: 420px;
      max-width: 420px;
    }

    &__image {
      display: flex;
      height: 360px;
      margin: 40px 20px;
      padding: 40px 20px;
      img {
        width: 100%;
        max-width: 343px;
      }
    }
  }
}

.card {
  margin: 20px;
  width: 100%;
  //height: 540px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
  color: #202020;
  display: flex;
  flex-direction: column;
  align-items: center;

  //@media (min-width: 960px) {
  //    width: 480px;
  //}
  &__content {
    padding: 16px;
    width: 100%;
    text-align: center;
    hr {
      margin: 8px 50px 16px;
    }
    input {
      width: 100%;
      margin-bottom: 12px;
      border: 1px solid #757575;
      border-radius: 4px;
      font-size: 12px;
      min-height: 32px;
      padding: 2px 8px;
    }

    a {
      text-align: left;
      font-size: 16px;
    }

    button {
      max-width: 380px;
      width: 100%;
      margin: 0 auto;
      height: 40px;
      background: $lightblue;
      border-radius: 28px;
      border: none;
      color: #ffffff;
      font-size: 16px;
    }
  }

  &__recover {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 12px;

    a {
      color: #4353a0;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
  &__header {
    position: relative;
    height: 100px;
    width: 100%;
    background: $blue;
    border-radius: 16px 16px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: flex-end;

    img {
      position: absolute;
      top: 16px;
      left: 20px;
      width: 24px;
      height: 24px;
    }
    h3 {
      margin: 20px;
      font-weight: 600;
      font-size: 24px;
      color: #ffffff;
    }
  }

  &__text {
    padding: 40px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      width: 100%;
      font-size: 16px;
      padding: 12px;
    }

    &__container {
      width: 100%;

      ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        li {
          display: inline-block;
          justify-content: center;
          margin: 4px;
          a img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}
#google {
  background: #d54640;
}
.pointer:hover {
  cursor: pointer;
  opacity: 0.8;
}
#recover__form {
  max-height: 260px;
}
</style>

