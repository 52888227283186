<template>
    <div class="bg-container">
        <div class="dashboard breakpoints-container">
            <h1>{{ $t('dashboard.welcome') }} Nivel 5</h1>
            <div class="dashboard-components" v-if="props.data">
                <CircleGrap 
                    :data="props.data" 
                    @tutorial="emit('tutorial')"
                />
                <EtapaCard 
                    :etapas="props.data.Etapas" 
                    :selectedEtapa="props.selectedEtapa"
                    @changeEtapa="(etapaNumero)=> emit('changeEtapa', etapaNumero)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import EtapaCard from './EtapaCard.vue';
import CircleGrap  from './CircleGraph.vue';
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line no-unused-vars
const { t } = useI18n();

const props = defineProps({
    data: Object,
    selectedEtapa: Number
});
const emit = defineEmits(['tutorial','changeEtapa']);

</script>

<style lang="scss" scoped>

.bg-container{
    width: 100%;
    background: rgba(72, 51, 233, 0.05);
    @media(width < 600px){
        background: rgba(72, 51, 233, 0.1);
    }
}

.dashboard{
    /*padding: 0 16% 22px 16%;
     @media(width < 1470px){
        padding: 0 12% 22px 12%;
    }
    @media(width < 1350px){
        padding: 0 8% 22px 8%;
    }
    @media(width < 1200px){
        padding: 0 4% 22px 4%;
    }*/
    padding-bottom: 22px;
    @media(width < 600px){
        //padding: 0 12px 22px 12px;
    }
     &-components{
        width: 100%;
        display: flex; 
        justify-content: space-between;
        gap: 28px;
        @media(width < 1000px){
            flex-wrap: wrap;
            gap: 22px;
        }
    }
    h1{
        padding-top: 36px;
        padding-bottom: 22px;
        font-size: 24px;
        font-weight: 800;
        @media(width < 1000px){
            font-size: 20px;
        }
    }
}
</style>