export default {
  state: () => ({
    list: []
  }),
  getters: {
    notifications_list: state => state.list
  },
  actions: { 
    notifications_create({commit}, item) {
      const notification = item;
      notification['guid'] = `${new Date().getTime()}`;
      commit('notifications_addItem', notification);
    },
    notifications_delete({commit}, guid) {
      commit('notifications_removeItem', guid);
    }
  },
  mutations: { 
    notifications_addItem(state, notification) {
      state.list.push(notification);
    },
    notifications_removeItem(state, guid) {
      const idx = state.list.findIndex((notification) => notification.guid === guid);
      state.list.splice(idx, 1);
    },
    
  },
}