<template>
    <div>
        <div class="back" @click="goBack">
          <i class="material-icons">arrow_back</i> Volver
        </div>
        <div id="pc" class="full columns center">
            <div class="profile-card columns" id="contentIframe">
                <!-- <div class="profile__head full rows">
                    <h3>{{formTitle}}</h3>
                </div> -->
                <Loader v-if="loading" />
                <div v-if="!loading">
                  <iframe id="formIframe" :src="formUrl" style="width:100%; height: 100%;"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { onMounted } from '@vue/runtime-core';
    import { ref } from 'vue'
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import Loader from '../../components/Loader.vue';
    import base64url from 'base64url';

    export default {
        components:{
            Loader
        },
        setup() {
            const store = useStore();
            const route = useRoute();
            const router = useRouter();
            const loading = ref(true);
            const formData = ref({});
            const formUrl = ref("");
            const formTitle = ref("");
            const prevRoute = ref(null)

            onMounted(async () => {
                let { prevUrl, formUCode, editable, theme, externalUser } = JSON.parse(base64url.decode(route.params.hash))
                prevRoute.value = prevUrl
                try {
                    loading.value = true
                    const { data } = await store.dispatch('form_getByCode', { formUCode });    
                    formData.value = data.Formulario
                    formUrl.value = `${process.env.VUE_APP_INSCRIPCION_URL}/Form/StartForm/?formUCode=${formData.value.FormUCode}&callbackURL=JS:window.vueApp.redirectFromForm()&mod=${theme}&fCode=${formData.value.FilialId}&ReadOnly=${!editable}&externalUser=${externalUser}`;
                    formTitle.value = formData.value.FormTitle
                } catch (err) {
                    store.dispatch('notifications_create', { text: 'Ha ocurrido un error cargando el formulario.' });
                    loading.value = false;
                } finally {
                    loading.value = false;
                }
                const childWindow = document.getElementById('formIframe');
                const contentIframe = document.getElementById('contentIframe');

                window.addEventListener('message', message => {
                    // if (message.data !== childWindow) {
                        if (message && message.data) {
                            childWindow ? childWindow.style.height = `${message.data + 30}px` : null;
                            contentIframe ? contentIframe.style.height = `${message.data + 30}px` : null;
                            loading.value = false
                        }
                    //     return; // Skip message in this event listener
                    // }
                });
            });


            function goBack() {
                router.replace(prevRoute.value)
            }

            return{
                loading,
                formData,
                formTitle,
                formUrl,
                goBack
            }
        }
    }
</script>
<style lang="scss" scoped>
    * {
        padding: 0;
        margin: 0;
    }
    
#pc {
    // height: calc(100vh - 88px);
    width:100%;
    justify-content: flex-start;
    padding: $margin-small;
    overflow-y: auto;
    
    .profile-card {
        max-width: 400px;
        padding: $margin-small;
        background: $white;
        width: 100%;
        border-radius: $redondeadobig;
        box-shadow: 0 4px 6px rgb(0 0 0 / 25%);
        padding: $margin-small;
        overflow: hidden;
    
        iframe {
            border:none;
        }

        .profile__head {
            margin: $margin-xmilli;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            margin-bottom: 20px;
            h3 {
                @include font-settings(milli, xmega);
                }
            
            img {
                margin: 0 $margin-milli;
            }
        }

        .profile__content {
            flex-direction: column;
            border-top: 1px solid $gray;
            padding: $margin-milli;
            justify-content: space-between;

            .avatar {
                height: 100%;
                padding-top: 12px;
                flex-direction: column-reverse;
                justify-content: flex-start;
                position: relative;
                
                &__camera {
                    position: absolute;
                    height: 28px;
                    width: 28px;
                    border: none;
                    z-index: 1;
                    right: 4px;
                    border-radius: 50%;
                    background: $lightblue;
                    padding: $margin-hmilli;

                    img {
                        width: 100%;
                    }
                }

                .img {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    border: 1px solid $black;
                    background-size: cover;
                    background-position: center center;
                }
            }
            .data {
                padding: $margin-milli 0;
                height: 100%;
                width: 100%;
                
                h4 {
                    @include font-settings(small, mega);
                }

                span {
                    @include font-settings(milli, xmega);
                }

                input {
                    @include font-settings(milli, xmega);
                    border: none;
                    border-bottom: 1px solid $gray;
                }
            }
        }

        .profile__button {
                justify-content: space-between;
                position: relative;
                min-height: 24px;
                .save {
                    position: absolute;
                    min-width: 120px;
                    @include font-settings(small, mega);
                    right: 0;
                }
                .edit {
                    min-width: 100px;
                    position: absolute;
                    @include font-settings(small, mega);
                    left: 0;
                }
            }
    }

    @media(min-width: 960px) {
        padding-top: $margin-mega;
        // height: calc(100vh - 48px);
        .edit-btn-mob {
            display:none;
        }
        .profile-card {
            // max-width: 600px;
            max-width: 1024px;
            padding: 0;
            padding-bottom: 8px;
            
            .profile__head {
                margin: 4px;
                margin-bottom: 20px;
                h3 {
                    font-size: 18px;
                    }
                
                img {
                    margin: 0 8px;
                }
            }

            .profile__content {
                flex-direction: row;
                border-top: 1px solid $gray;
                padding: 12px;
                justify-content: space-between;

                .avatar {
                    height: 100%;
                    padding-top: 12px;
                    justify-content: flex-start;

                    &__camera {
                        height: 36px;
                        width: 36px;
                        background: $lightblue;
                    }

                    .img {
                        height: 140px;
                        width: 140px;
                        border-radius: 50%;
                        border: 1px solid $black;
                    }
                }

                .data {
                    padding: 12px;
                    height: 100%;
                    width: 400px;
                    
                    h4 {
                        @include font-settings(small, mega);
                    }

                    span {
                        @include font-settings(milli, xmega);
                    }

                    input {
                        @include font-settings(milli, xmega);
                        border: none;
                        border-bottom: 1px solid $gray;
                    }
                }
            }

            .profile__button {
                button {
                    min-width: 160px;
                    @include font-settings(xmilli, base)
                }
            }
            
        }
    }
}

.cropper {
    width: 100%;
    max-width: 330px !important;
}

.btn-cropper {
        margin-top: $margin-base;
        min-width: 120px;
    }

.text-modal {
    .text {
        padding: $margin-kilo $margin-small
    }
}
.buttons {
    justify-content: space-evenly;
    button {
        min-width: 120px;
    }
}

.back {
    width: 1024px;
    margin: 12px auto;
    color: $lightblue;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 4px;

    i {
        font-size: 16px;
    }
}

#contentIframe {
    padding: 0px!important;
}

 
</style>