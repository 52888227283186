<template>
    <div id="pc" class="full columns center">
        <div class="profile-card columns">
            <div class="profile__head full rows">
                <img src="@/assets/icons/avatar.svg" alt="">
                <h3>Formularios pendientes</h3>
            </div>
            <div>
              <ul>
                <li v-for="form in pendingForms" :key="form.id">
                    <a :href="`/Forms/${form.FormUCode}`" >{{form.FormTitle}}</a>
                </li>
              </ul>
            </div>
        </div>
    </div>
</template>
<script>
    import { onMounted } from '@vue/runtime-core';
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n';
  
    import { useStore } from 'vuex';
    
    export default {
        components:{
            
        },
        setup() {
            const store = useStore();
            const { t } = useI18n();

            const loading = ref(false);
            const pendingForms = ref([])
            
             onMounted(async () => {
               
                const request = await store.dispatch('form_autoevpending');
                if (request.status === 200) {
                  pendingForms.value = request.data.Evaluaciones
                }
                else {
                  store.dispatch('notifications_create', { text: t(`notifications.${request.data}`) });
                  loading.value = false;
                }
            });

            return{
                pendingForms
            }
        }
    }
</script>
<style lang="scss" scoped>
    * {
        padding: 0;
        margin: 0;
    }
    
#pc {
    height: calc(100vh - 88px);
    width:100%;
    background: $lightgray;
    justify-content: flex-start;
    padding: $margin-small;
    
    .profile-card {
        max-width: 400px;
        padding: $margin-small;
        background: $white;
        width: 100%;
        border-radius: $redondeadobig;
        box-shadow: 0 4px 6px rgb(0 0 0 / 25%);
        padding: $margin-small;


        .profile__head {
            margin: $margin-xmilli;
            
            h3 {
                @include font-settings(milli, xmega);
                }
            
            img {
                margin: 0 $margin-milli;
            }
        }

        .profile__content {
            flex-direction: column;
            border-top: 1px solid $gray;
            padding: $margin-milli;
            justify-content: space-between;

            .avatar {
                height: 100%;
                padding-top: 12px;
                flex-direction: column-reverse;
                justify-content: flex-start;
                position: relative;
                
                &__camera {
                    position: absolute;
                    height: 28px;
                    width: 28px;
                    border: none;
                    z-index: 1;
                    right: 4px;
                    border-radius: 50%;
                    background: $lightblue;
                    padding: $margin-hmilli;

                    img {
                        width: 100%;
                    }
                }

                .img {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    border: 1px solid $black;
                    background-size: cover;
                    background-position: center center;
                }
            }
            .data {
                padding: $margin-milli 0;
                height: 100%;
                width: 100%;
                
                h4 {
                    @include font-settings(small, mega);
                }

                span {
                    @include font-settings(milli, xmega);
                }

                input {
                    @include font-settings(milli, xmega);
                    border: none;
                    border-bottom: 1px solid $gray;
                }
            }
        }

        .profile__button {
                justify-content: space-between;
                position: relative;
                min-height: 24px;
                .save {
                    position: absolute;
                    min-width: 120px;
                    @include font-settings(small, mega);
                    right: 0;
                }
                .edit {
                    min-width: 100px;
                    position: absolute;
                    @include font-settings(small, mega);
                    left: 0;
                }
            }
    }

    @media(min-width: 960px) {
        padding-top: $margin-mega;
        height: calc(100vh - 48px);
        .edit-btn-mob {
            display:none;
        }
        .profile-card {
            max-width: 600px;
            padding: $margin-base;

            .profile__head {
                margin: 4px;
                
                h3 {
                    font-size: 18px;
                    }
                
                img {
                    margin: 0 8px;
                }
            }

            .profile__content {
                flex-direction: row;
                border-top: 1px solid $gray;
                padding: 12px;
                justify-content: space-between;

                .avatar {
                    height: 100%;
                    padding-top: 12px;
                    justify-content: flex-start;

                    &__camera {
                        height: 36px;
                        width: 36px;
                        background: $lightblue;
                    }

                    .img {
                        height: 140px;
                        width: 140px;
                        border-radius: 50%;
                        border: 1px solid $black;
                    }
                }

                .data {
                    padding: 12px;
                    height: 100%;
                    width: 400px;
                    
                    h4 {
                        @include font-settings(small, mega);
                    }

                    span {
                        @include font-settings(milli, xmega);
                    }

                    input {
                        @include font-settings(milli, xmega);
                        border: none;
                        border-bottom: 1px solid $gray;
                    }
                }
            }

            .profile__button {
                button {
                    min-width: 160px;
                    @include font-settings(xmilli, base)
                }
            }
            
        }
    }
}

.cropper {
    width: 100%;
    max-width: 330px !important;
}

.btn-cropper {
        margin-top: $margin-base;
        min-width: 120px;
    }

.text-modal {
    .text {
        padding: $margin-kilo $margin-small
    }
}
.buttons {
    justify-content: space-evenly;
    button {
        min-width: 120px;
    }
}


 
</style>