import interceptor from '@/plugins/interceptor';
const pathController = '/CartaConstanciaCursada'

export default {
    state: () => ({

    }),
    mutations: {
    },
    actions: {

        async letters_solicitud(_, model) {
            return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/solicitud`, model);
        },
        async letters_configuraciones() {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/configuraciones`);
        }
    }
}