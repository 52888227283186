<template>
  <div class="tabs full center">
    <ul class="tabs__header rows full">
      <li
        class="pointer btn"
        v-for="title in tabTitles"
        :key="title"
        :class="{ selected: title == selectedTitle }"
        @click="selectedTitle = title"
      >
        {{ title }}
      </li>
    </ul>
    <slot> </slot>
  </div>
</template>
<script>
import { ref, provide } from "vue";
export default {
  setup(props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const selectedTitle = ref(tabTitles.value[0]);

    provide("selectedTitle", selectedTitle);

    return {
      tabTitles,
      selectedTitle,
    };
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  margin: 0 auto;
  max-width: 600px;

  &__header {
    left: 0;
    justify-content: space-between;
    max-width: 380px;
    padding-top: $margin-small;

    li {
      @include font-settings(milli, base);
      text-align: center;
      padding: $margin-milli $margin-base;
      transition: 0.4s all ease-out;

      border-radius: 100px;
      color: $lightblue;
      background-color: $white;
      border: 1px solid $lightblue;
      cursor: pointer;
      color: $black;
    }

    li.selected {
      background: $lightblue;
      color: $white;
    }
  }
}
</style>
