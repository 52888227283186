<template>

    <div class="module-box">

        <h4 :style="levelNotAllowed ? 'margin-bottom:0px' : ''">{{ $t('progress.boxPreparacionLaboral.title') }}
            <span class="data__empty" v-if="levelNotAllowed">{{ $t('progress.fromLevel') }} {{ data.DisponibleDesdeNivel }}</span>
        </h4>

        <template v-if="!reqError">
       
            <div v-if="!data || (data && !levelNotAllowed)">

                <div class="evaluacion best-version">
                    <div class="reference" >
                        <template v-if="data">
                            <i class="material-icons">moving</i>
                            <span>{{ $t('progress.boxPreparacionLaboral.betterVersion') }}</span>               
                        </template>
                        <template v-else>
                            <Skeletor height="23" width="23" />
                        </template>
                    </div>

                    <div class="data">
                        <template v-if="data">
                            <span
                            class="data__status"
                            :class="{
                                'data__status--complete': data.MejorVersion.Texto === 'COMPLETADO',
                                'data__status--incomplete': data.MejorVersion.Texto === 'INCOMPLETO',
                                'data__status--pending': data.MejorVersion.Texto === 'PENDIENTE'
                            }"
                            >
                                {{ 
                                    $t(
                                        'status.' + 
                                        data.MejorVersion.Texto.toUpperCase()
                                    )
                                }}
                            </span>
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                        </template>
                    </div>

                    
                </div>

                <div class="evaluacion experience">
                    <div class="reference">
                        <template v-if="data">
                            <i class="material-icons">work_outline</i>
                            <span>{{ $t('progress.boxPreparacionLaboral.laboralExperience') }}</span>
                        </template>
                        <template v-else>
                            <Skeletor width="23" height="23" />
                        </template>
                    </div>
                    <div class="data">
                        <template v-if="data">
                            <span
                            class="data__status"
                            :class="{
                                'data__status--complete': data.ExperienciaLaboral.Texto === 'COMPLETADO',
                                'data__status--incomplete': data.ExperienciaLaboral.Texto === 'INCOMPLETO',
                                'data__status--pending': data.ExperienciaLaboral.Texto === 'PENDIENTE'
                            }"
                            >
                                <!-- {{ $t('status.PENDING') }} -->
                                {{ 
                                    $t(
                                        'status.' + 
                                        data.ExperienciaLaboral.Texto.toUpperCase()
                                    )
                                }}
                            </span>       
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                        </template>
                    </div>
                </div>

                <div class="evaluacion formation">
                    <div class="reference">
                        <template v-if="data">
                            <i class="material-icons material-icons-outlined">school</i>
                            <span>{{ $t('progress.boxPreparacionLaboral.formation') }}</span>
                        </template>
                        <template v-else>
                            <Skeletor width="23" height="23" />
                        </template>
                    </div>
                    <div class="data">
                        <template v-if="data">
                            <span
                            class="data__status"
                            :class="{
                                'data__status--complete': data.Formacion.Texto === 'COMPLETADO',
                                'data__status--incomplete': data.Formacion.Texto === 'INCOMPLETO',
                                'data__status--pending': data.Formacion.Texto === 'PENDIENTE'
                            }"
                            >                            <!-- {{ $t('status.COMPLETADO') }} -->
                                {{ 
                                    $t(
                                        'status.' + 
                                        data.Formacion.Texto.toUpperCase()
                                    )
                                }}
                            </span>                
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                        </template>
                    </div>
                </div>

                <div class="evaluacion cv">
                    <div class="reference" >
                        <template v-if="data">
                            <i class="material-icons">description</i>
                            <span>{{ $t('progress.boxPreparacionLaboral.CVs') }}</span>               
                        </template>
                        <template v-else>
                            <Skeletor height="23" width="23" />
                        </template>
                    </div>

                    <div class="data">
                        <template v-if="data">
                            <span class="data__number">
                                {{data.Cvs.Total}}
                            </span>
                            <div
                            class="description">
                                Ruky: {{data.Cvs.HechosEnRuky}}
                            </div>
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                        </template>
                    </div>
                    
                </div>

                <div class="evaluacion interviews">
                    <div class="reference">
                        <template v-if="data">
                            <i class="material-icons">co_present</i>
                            <span>{{ $t('progress.boxPreparacionLaboral.simulatedInterviews') }}</span>
                        </template>
                        <template v-else>
                            <Skeletor width="23" height="23" />
                        </template>
                    </div>
                    <div class="data">
                        <template v-if="data">
                            <span>{{data.Entrevistas.Total}}</span>
                            <span class="show-modal"
                                @click="modalDetail.render = true; modalDetail.show = true;">
                                {{ $t('progress.boxPreparacionLaboral.viewFeedbacks') }}
                            </span> 
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                        </template>
                    </div>
                </div>

                <ModalDetail v-if="modalDetail.render"
                    :show="modalDetail.show"
                    :studentName="props.studentName"
                    @close="modalDetail.show = false"
                />

            </div>
        </template>

        <ErrorBox v-else />

    </div>

</template>

<script setup>
import { onMounted, ref, defineProps } from 'vue'; 
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';
import ModalDetail from './ModalDetail.vue';
import ErrorBox from '../ReqError.vue';

const store = useStore();
const route = useRoute();

const props = defineProps({
    studentName: String,
    studentLevel : Number
})

const modalDetail = ref({
    render: false,
    show: false
});

const data = ref(null);
const levelNotAllowed = ref(false);
const reqError = ref(false);

async function getData(){
    try {
        let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'PREPARACION_LABORAL', type: 'GENERAL' })
        //let req = await store.dispatch('seguimiento_modulos', { alumnoId: 181464, moduloCode: 'PREPARACION_LABORAL', type: 'GENERAL' })
        data.value = req.data;
        levelNotAllowed.value = !data.value.Disponible || data.value.DisponibleDesdeNivel > props.studentLevel;
    } catch (error) {
        console.log(error);
        reqError.value = true;
    }
}

onMounted(
    getData()
)

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";


.data__empty{
    padding: 4px 8px;
    margin-left: 12px;
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #BDBDBD;
    background: #F6F6F6;
    border-radius: 5px;
}

.module-box{

    > div {
        gap: 24px;
    }
    .evaluacion{
        padding-right: 24px;

        &.interviews {
            border-right: none;
        }
        .reference{
            i{
                background: #FDDCE3;
            }
        }
        .data{
            align-items: center;
           
            span{

                &.data__status {
                    font-size: 12px;
                    font-weight: 700;
                    color: $lightblue;
                    border-radius: 5px;
                    padding: 4px 8px;
                    margin-top: 12px;

                    &.data__status--complete{
                        color: $green;
                        background: rgba(0, 197, 143, 0.10);
                    }
                    &.data__status--incomplete{
                        color: $red;
                        background: rgba(245, 112, 85, 0.20);
                    }
                    &.data__status--pending{
                        color: $yellow;
                        background: rgba(245, 112, 85, 0.20);
                    }
                }
            }

            .show-modal {
                align-self: center;
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                color: #4833E9;
                cursor: pointer;
            }

            .description{
                display: flex;
                align-items: center;
                font-size: 10px;
                font-weight: 400;
                color: $lightblue;
                background-color: rgba(72, 51, 233, 0.05);
                padding: 4px;
                border-radius: 4px;
            }
        }
    }
}

</style>