import { createRouter, createWebHistory } from 'vue-router';
import persistentData from '@/plugins/persistentData';
//import Home from '../views/Home.vue'
//import LoginMode from '../views/Login/Mode/Main.vue'
import Home from '../views/Home/Main.vue';
import Files from '../views/Files/Main.vue';
import Forms from '../views/Forms/Main.vue';
import FormsDetails from '../views/Forms/Form.vue';

// import LoginStudents from '../views/Login/Students/Main.vue'
// import LoginTeachers from '../views/Login/Teachers/Main.vue'
import LoginGeneric from '../views/Login/Generic/Main.vue';
// import LoginCollaborators from '../views/Login/Collaborators/Main.vue'
import Profile from '../views/Profile/Main.vue';
//import Login2 from '../views/Login2/Main.vue'
import MonitoringActivities from '../views/MonitoringActivities/Main.vue';
import AttendanceMonitoring from '../views/AttendanceMonitoring/Main.vue';
import LearningDiary from '../views/LearningDiary/Main.vue';
import SelfknowledgeAxis from '../views/SelfknowledgeAxis/Main.vue';
import MyEvaluation from '../views/Profile/MyEvaluation.vue';
import LevelFive from '../views/LevelFive/LevelFive.vue';
import Progreso from '@/views/Seguimiento/Progreso.vue';
import ProgresoParticipante from '@/views/Seguimiento/ProgresoParticipante.vue';

const guard = (to, from, next) => {
  if (persistentData.get('token') !== null) {
    next();
  } else {
    next('/login');
  }
};

import Details from '../views/Files/Details/Main.vue';
import Letters from '../views/Letters/Main.vue';
import RequestedLetters from '../views/Letters/RequestedLetters/Main.vue';
const routes = [
  { path: '/Profile', name: 'Profile', component: Profile, beforeEnter: guard },
  { path: '/Login', name: 'Login', component: LoginGeneric },
  { path: '/', name: 'Home', component: Home, beforeEnter: guard },
  { path: '/Forms', name: 'Forms', component: Forms, beforeEnter: guard },
  {
    path: '/Forms/:hash',
    name: 'FormsDetails',
    component: FormsDetails,
    beforeEnter: guard,
  },
  //{ path: '/Home', name: 'Home1', component: Home },
  { path: '/Files', name: 'Files', component: Files, beforeEnter: guard },
  //{ path: '/Login/Students', name: 'LoginStudents', component: LoginStudents },
  //{ path: '/Login/Teachers', name: 'LoginTeachers', component: LoginTeachers },
  //{ path: '/Login/Collaborators', name: 'LoginCollaborators', component: LoginCollaborators },
  //{ path: '/Login2', name: 'Login2', component: Login2 }
  { path: '/Files/Details/:id', name: 'Details', component: Details },
  { path: '/myEvaluation', name: 'myEvaluation', component: MyEvaluation },
  /* {
    path: '/Login/Students',
    name: 'LoginStudents',
    component: LoginStudents
  },
  {
    path: '/Login/Teachers',
    name: 'LoginTeachers',
    component: LoginTeachers
  },
  {
    path: '/Login/Collaborators',
    name: 'LoginCollaborators',
    component: LoginCollaborators
  }, 
  {
    path: '/Login2',
    name: 'Login2',
    component: Login2
  },
  */
  {
    path: '/Files/Details/:id',
    name: 'Details',
    component: Details,
  },
  {
    path: '/Letters',
    name: 'Letters',
    component: Letters,
  },
  {
    path: '/RequestedLetters',
    name: 'RequestedLetters',
    component: RequestedLetters,
  },
  {
    path: '/monitoringActivities/:hash',
    name: 'MonitoringActivities',
    component: MonitoringActivities,
  },
  {
    path: '/attendanceMonitoring/:id',
    name: 'AttendanceMonitoring',
    component: AttendanceMonitoring,
  },
  {
    path: '/learningDiary',
    name: 'LearningDiary',
    component: LearningDiary,
  },
  {
    path: '/selfknowledgeAxis',
    name: 'SelfknowledgeAxis',
    component: SelfknowledgeAxis,
  },
  {
    path: '/nivel5',
    name: 'LevelFive',
    component: LevelFive,
  },
  {
    path: '/seguimiento/:courseId', //
    name: 'Seguimiento',
    component: Progreso,
  },
  {
    path: '/seguimiento/:courseId/alumno/:alumnoId',
    name: 'ProgresoParticipante',
    component: ProgresoParticipante,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});

export default router;
