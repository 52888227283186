<template>
    <div class="modal-detail__card">

        <header>
            <span>{{category}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
                <circle cx="4.5" cy="4.5" r="4.5" :class="data.Color"/>
            </svg>
        </header>

        <main>
            <span>{{ data.Value != null ? `${data.Value}%` : 'N/A' }}</span>
            <button @click="modalDetalle = true">{{ $t('progress.boxCompetencias.seeDetails') }}</button>
        </main>

        <footer>
            <template v-if="data.Data.Variacion == null">
                {{ $t('progress.boxCompetencias.noComparative') }}
            </template>
            <div v-else-if="data.Data.Variacion == 0">
                {{ $t('progress.boxCompetencias.noVariation') }}
            </div>
            <div v-else>
                <span :class="data.Data.Variacion > 0 ? 'VERDE' : 'ROJO'">
                    <i class="material-icons" :style="data.Data.Variacion < 0 ? 'align-self:flex-end' : ''">{{ data.Data.Variacion > 0 ? 'arrow_upward' : 'arrow_downward' }}</i> 
                    {{ data.Data.Variacion > 0 ? data.Data.Variacion : data.Data.Variacion.toString().slice(1) }} %
                </span>
                {{ $t('progress.boxCompetencias.fromLastEvaluation') }}
            </div>
        </footer>

    </div>

    <DetalleCompetencia v-if="modalDetalle"
        :name="props.category"
        @close="modalDetalle = false"
    />
</template>

<script setup>
import { defineProps, ref } from 'vue';
import DetalleCompetencia from './DetalleCompetencia.vue';

const props = defineProps({
    category: String,
    data: Object
})

const modalDetalle = ref(false);

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

</style>