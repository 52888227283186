import { createStore } from 'vuex'
import ManageUser from '@/store/common/ManageUser';
import Notifications from '@/store/common/Notifications';
// API
import APISecurity from '@/store/API/Security';
import APIStudent from '@/store/API/Student';
import APIDocumentation from '@/store/API/Documentation'
import APIForm from '@/store/API/Form'
import APIInteractions from '@/store/API/Interactions'
import APILetters from '@/store/API/Letters'
import APIRequestedLetters from '@/store/API/RequestedLetters'
import APIFacilitador from '@/store/API/Facilitador'
import APIJoven from '@/store/API/Jovenes.js'
import APINivelCinco from '@/store/API/NivelCinco.js'
import APISeguimiento from '@/store/API/Seguimiento.js'

export default createStore({
  modules: {
    ManageUser,
    Notifications,
    APISecurity,
    APIForm,
    APIStudent,
    APIDocumentation,
    APIInteractions,
    APILetters,
    APIRequestedLetters,
    APIFacilitador,
    APIJoven,
    APINivelCinco,
    APISeguimiento
  },
});
