<template>
    <div class="breakpoints-container">

        <header>
            <div class="btn-return">
                <span @click="router.push(`/seguimiento/${route.params.courseId}`)"><i class="material-icons">arrow_back</i>{{ $t('global.back') }}</span>
            </div>

            <h2>
                <template v-if="data">
                    <div><h3>{{ $t('progress.progressOf') }} </h3><h4>{{ data.NombreCompleto }}</h4></div>
                    <div><h3>{{ $t('progress.currentLevel') }}:</h3><h4>{{ data.Nivel }}</h4></div>
                </template>
                <template v-else>
                    <Skeletor width="280" height="19" />
                    <Skeletor width="130" height="19" />
                </template>
            </h2>
        </header>

        <main v-if="data" class="progreso">
            <component v-for="(comp, idx) in data.Modulos" :key="idx" :is="comp.Codigo" 
                :data="comp.Data"
                :studentName="data.NombreCompleto"
                :studentLevel="data.Nivel"
            />
        </main>

    </div>
</template>

<script>
import GENERAL from './components/BoxGeneral.vue';
import EVALUACION_DE_COMPETENCIAS from './components/boxCompetencias/BoxCompetencias.vue'; 
import EVALUACION_DE_COMPANEROS from './components/boxEquipo/BoxEquipo.vue'; 
import PREPARACION_LABORAL from './components/boxPreparacionLaboral/BoxPreparacionLaboral.vue';
export default {
  components: {
    GENERAL,
    EVALUACION_DE_COMPETENCIAS,
    EVALUACION_DE_COMPANEROS,
    PREPARACION_LABORAL
    },
}
</script>

<script setup>
/* eslint-disable no-unused-vars */
/* eslint-enable no-unused-vars */
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const router = useRouter();
const route = useRoute();
const store = useStore();

const data = ref(null);

async function getLayout(){
    try {
        let req = await store.dispatch('seguimiento_alumnoLayout', route.params.alumnoId);
        data.value = req.data;
    } catch (error) {   
        console.log(error);
        store.dispatch('notifications_create', { text: t('progress.errorFetchingParticipantData') })   
    }
}

onMounted(()=>{
    getLayout();
})

</script>

<style lang="scss" scoped>

.breakpoints-container{
    position: relative;
    @media(width > 1124px){
        padding-right: 20px;
    }
    header{
        padding: 22px 0;
        h2{
            display: flex;
            justify-content: space-between;
            @media(width < 600px){
                flex-direction: column;
                gap: 12px;
            }
            div{
                display: flex;
                gap: 4px;
                align-items: flex-end;
                h3{
                    font-size: 16px;
                }
                h4{
                    padding-left: 4px;
                    font-size: 16px;
                    color: blue;
                }
            }
        }
    }
    .progreso {
        padding-bottom: 22px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

</style>