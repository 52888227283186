<template>
    <div class="detail bx-shadow">
        <i class="material-icons-outlined" :style="{backgroundColor: color}">{{ icon }}</i>
        <span class="detail__title">{{ title }}</span>
        <span class="detail__clasification" :style="{ color: color }">{{ clasification }}</span>
        <span class="detail__percentage">{{ percentage }} %</span>
    </div>
</template>

<script>
export default {
    props: ['icon', 'color', 'title', 'clasification', 'percentage']
}
</script>

<style lang="scss" scoped>

.detail{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    font-size: 14px;
    height: 230px;
    min-height: 230px;
    min-width: 230px;;
    width: 230px;
    gap: 12px;
    border-radius: 60px;
    i{
        padding: 25px;
        border-radius: 9999px;
        color: #fff;
        position: absolute;
        top: -37px;
    }
    &__title{
        font-weight: 600;
    }
    &__percentage{
        font-weight: 800;
        font-size: 24px;
        margin-top: 6px;
    }
}
</style>