{
    "global":{
        "continue": "Continuar",
        "back": "Volver",
        "filters": {
            "inProgress": "En curso",
            "finished": "Finalizado",
            "notStarted": "Sin iniciar",
            "all": "Todos",
            "complete": "Completo",
            "incomplete": "Incompleto"
        }
    },
    "navbar":{
        "home":"Inicio",
        "profile":"Perfil",
        "logout":"Salir",
        "confirmLogout":"¿Estas seguro que deseas Salir?",
        "cancelBtn":"Cancelar",
        "confirmBtn":"Confirmar"
    },
    "login":{
        "title":"Iniciar sesión",
        "startWithGoogle":"Acceder con Google",
        "forgotPassword":"¿Olvidaste tu contraseña?",
        "loginBtn":"Acceder",
        "recoveryPasswordTitle":"Recuperar contraseña",
        "backToLogin":"Volver al inicio de sesión",
        "recoveryBtn":"Recuperar",
        "followUs":"Siguenos",
        "username": "Usuario",
        "password": "Contraseña"
    },
    "home":{
        "hello":"Hola",
        "whatToDo":"¿Qué deseas realizar?",
        "goToCampus": "Ir al CAMPUS",
        "goToRuky": "Ir a RUKY",
        "myCourses": "Mis Cursos",
        "course": "Curso",
        "courseDisclaimer": "Por favor indica a qué curso quieres acceder:",
        "selectCourse": "Selecciona un curso",
        "selectLabel": "Codigo",
        "assistenceFollowing": "Asistencia",
        "progress": "Progreso",
        "assistenceEnter": "Ingresar",
        "classContent": "Contenido de la clase",
        "nextClassWhen": "Su próxima clase es el",
        "noActivities": "Esta clase no tiene actividades para realizar.",
        "noForms": "No hay formularios para mostrar.",
        "table" : {
            "thisClassTasks": "TAREAS DE ESTA CLASE",
            "filters": "Filtros",
            "status": "Estado",
            "headers": {
                "activity": "ACTIVIDAD",
                "date": "FECHA",
                "status": "ESTADO"
            },
            "apply": "Aplicar",
            "cancel": "Cancelar",
            "enter": "Ingresar",
            "see": "Ver",
            "bloqued": "Bloqueado",
            "review": "Revisar",
            "previous": "Anterior",
            "next": "Siguiente",
            "taskStoryTitle": "HISTORIAL DE TAREAS"
        },
        "formsTitle":"Encuestas y formularios para completar",
        "formsEmpty":"Buen trabajo! No tienes encuestas ni formularios pendientes para completar.",
        "loading":"Cargando...",
        "shureAccesCampus":"¿Estas seguro que deseas ir al campus?",
        "acceptBtn":"Acceder",
        "cancelBtn":"Cancelar",
        "shureAccessCreadly":"¿Estas seguro que deseas ir a Insgnias?",
        "formOpen":"VER",
        "selectOptionTitle":"Selecciona la opción a la que deseas acceder"
    },
    "profile":{
        "title":"Datos basicos",
        "fullName":"Nombre de usuario",
        "photoEdit":"Editar imagen",
        "saveChanges":"Guardar cambios",
        "acceptBtn":"Aceptar",
        "shureSaveChanges":"¿Estas seguro que deseas guardar los cambios?",
        "cancelBtn":"Cancelar",
        "saveBtn":"Guardar"
    },
    "dashboard": {
        "emptyState": "Tu curso no tiene asignado la modalidad de Talleres",
        "welcome": "Te damos la bienvenida al",
        "circleGraph": {
            "yourProgressIn": "Tu progreso en Nivel 5",
            "howItWorks": "¿Cómo funciona el nivel 5?",
            "whatIsThis": "¿Qué es esto?",
            "mandatoryWorkshops": "Talleres iniciales obligatorios",
            "chosenWorkshops": "Talleres elegidos por ti",
            "chosenWorkshopsExplanation": "Son los talleres que debes realizar en cada etapa para alcanzar tu certificación. Tú eliges los talleres que deseas hacer en cada etapa.",
            "followUpInterviews": "Entrevistas de seguimiento",
            "bonus": "Bonus",
            "bonusExplanation": "Son todos los talleres adicionales que realizas en cada etapa. En cada etapa tienes que realizar al menos 3 talleres."
        },
        "stage": {
            "stage": "Etapa",
            "workshops": "Talleres",
            "mandatoryInitials": "Iniciales obligatorios",
            "chosenByYou": "Elegidos por ti",
            "metGoal": "👍 Cumpliste el objetivo",
            "didNotMeetGoal": "😔 No alcanzaste el objetivo",
            "exceededGoal": "🙌 Superaste el objetivo",
            "allWorkshopsAreOptional": "Si trabajas todos los talleres son opcionales",
            "bonus": "Bonus",
            "inThisStage": "En esta etapa tienes que realizar todos los talleres.",
            "completeRequiredWorkshops": "Completa los talleres requeridos para poder sumar talleres adicionales.",
            "noAdditionalWorkshops": "En esta etapa no has realizado talleres adicionales.",
            "notSignedUpForAnyWorkshop": "Todavía no te has inscripto en ningún taller.",
            "interview": "Entrevista",
            "complete": "COMPLETO",
            "incomplete": "INCOMPLETO",
            "pending": "PENDIENTE"
        },
        "journey": {
            "level": "Nivel 5",
            "eachStage": "En cada etapa podrás agendar la entrevista con tu entrenador y seleccionar los talleres que quieras realizar.",
            "stage": "ETAPA",
            "interview": "Entrevista",
            "complete": "COMPLETO",
            "incomplete": "INCOMPLETO",
            "pending": "PENDIENTE",
            "viewSchedule": "Ver agenda",
            "mandatoryInitialWorkshops": "Talleres iniciales obligatorios",
            "workshops": "Talleres",
            "workshop": "Taller",
            "stageInfo": {
                "openMandatory": "Recuerda que en esta etapa, deberás realizar todos los talleres para alcanzar tu certificación.",
                "open": "Recuerda que en esta etapa, deberás realizar {count} {workshop} para alcanzar tu certificación.",
                "pending": "La oferta de talleres estará disponible los primeros días de la etapa.",
                "working": "Selecciona los talleres que desees realizar para continuar con tu formación.",
                "closed": "Esta etapa ya ha finalizado."
              },
            "modalInterview": {
                "header": "Si todavía no agendaste una entrevista, presiona CONTINUAR.",
                "body": "Si ya agendaste una entrevista y deseas modificar o cancelar la reserva, accede desde el link que figura en el email de confirmación que recibiste.",
                "cancel": "Cancelar",
                "continue": "Continuar"
            },
            "workshopCard": {
                "obligatory": "Obligatorio",
                "complete": "COMPLETO",
                "incomplete": "INCOMPLETO",
                "selectDayAndTime": "Selecciona día y horario",
                "maxCapacityReached": "Este taller alcanzó su capacidad máxima de participantes",
                "noMoreDates": "Ya no quedan fechas disponibles para este taller.",
                "reservedPlace": "Lugar reservado",
                "viewDetail": "Ver detalle",
                "bookPlace": "Reservar lugar",
                "releasePlace": "Liberar lugar",
                "confirmModal": {
                    "youWish": "¿Deseas",
                    "reserve": "reservar",
                    "release": "liberar",
                    "yourPlace": "tu lugar en el taller",
                    "ofTheDay": "del día",
                    "cancel": "Cancelar"
                },
                "loadingDates": "Cargando fechas...",
                "rememberDate": "Recuerda ingresar en la fecha y horario asignado al taller",
                "errorReserve": "Error al intentar reservar lugar.",
                "errorRelease": "Error al intentar liberar lugar."
              }
                          
        }
    },
    "selfKnowledge": {
        "title": "Eje Autoconocimiento",
        "description": "Habilidades vinculadas con la identificación, compresión y el manejo de las propias emociones.",
        "categories": {
          "personalImage": {
            "title": "1. Imagen personal",
            "description": "Prestar atención a la imagen causada, imagen que proyecto con mi postura y mi aspecto ante la cámara",
            "improvement": "respecto a la última evaluación"
          },
          "selfAwareness": {
            "title": "2. Autoconciencia",
            "description": "Tener conciencia de mis capacidades y limitaciones y como afectan a mi conducta.",
            "improvement": "respecto a la última evaluación"
          }
        },
        "indicators": "INDICADORES",
        "level": "NIVEL",
        "auto": "AUTO",
        "facilitator": "FACILITADOR",
        "evaluation": {
            "whenOnScreen": "Cuando estoy ante la pantalla mi aspecto causa buena impresión en los demas y es pertinente a la sitación en que se encuentra.",
            "postureAttitude": "Mi postura y actitud ante la cámara expresan interes y disposición a participar en todas las actividades.",
            "always": "Siempre",
            "frequently": "Frecuentemente",
            "sometimes": "A veces",
            "rarely": "Muy pocas veces",
            "never": "Nunca",
            "na": "N/A"
        }
    },
    "learningDiary": {
        "learningDiaries": "DIARIOS DE APRENDIZAJE",
        "whatHaveYouLearned": "¿QUÉ HAS APRENDIDO / DESCUBIERTO SOBRE TI?",
        "download": "Descargar",
        "viewMore": "Ver más",
        "learningDiaryHistory": "HISTORIAL DIARIOS DE APRENDIZAJE",
        "responsesOf": "respuestas de",
        "questions": "preguntas",
        "class": "CLASE",
        "date": "FECHA",
        "axis": "EJE",
        "status": "ESTADO",
        "previous": "Anterior",
        "next": "Siguiente",
        "page": "Página"
    },
    "evaluation": {
        "myAttendance": "MI ASISTENCIA",
        "learningDiary": "DIARIO DE APRENDIZAJE",
        "diaryDescription": "Es una invitación a la autoreflexión en cada clase para ayudarte a pensarte en tu evolución en el programa.",
        "viewDetail": "Ver detalle",
        "yourEvaluations": "ESTAS SON TUS EVALUACIONES",
        "detailByAxis": "DETALLE POR EJE",
        "selfKnowledge": "Autoconocimiento",
        "selfKnowledgeDescription": "Habilidades vinculadas con la identificación, comprensión y el manejo de las propias emociones.",
        "attentionToOthers": "Atención a los demás y al entorno",
        "attentionToOthersDescription": "Habilidades que permiten relacionarse mejor con los demás.",
        "commitmentAndAction": "Compromiso y acción",
        "commitmentAndActionDescription": "Habilidades vinculadas con la ejecución de una tarea.",
        "toMaintain": "A mantener",
        "toImprove": "A mejorar",
        "personalImage": "Imagen personal",
        "selfAwareness": "Autoconciencia",
        "attendanceLabels": {
            "absent": "Ausente",
            "late": "Tarde",
            "present": "Presente"
          },
        "evaluationLabels": {
            "personalImage": "Imagen personal",
            "selfAwareness": "Autoconciencia",
            "empathy": "Empatía",
            "communication": "Comunicación",
            "sociability": "Sociabilidad",
            "autonomy": "Autonomía",
            "responsibility": "Responsabilidad",
            "teamwork": "Trabajo en equipo"
        },
        "evaluationTypes": {
            "selfAssessment": "Autoevaluación",
            "toMaintain": "A mantener",
            "toImprove": "A mejorar",
            "toWorkOn": "A trabajar"
        },
        "chartOptions": {
            "never": "Nunca",
            "rarely": "Poco",
            "sometimes": "A veces",
            "often": "Frecuentemente",
            "always": "Siempre"
        }
    },
    "attendanceMonitoring": {
        "back": "Volver",
        "attendanceMonitoring": "SEGUIMIENTO DE ASISTENCIA",
        "download": "Descargar",
        "class": "CLASE",
        "date": "FECHA",
        "activeParticipants": "PARTICIPANTES ACTIVOS",
        "present": "PRESENTES",
        "absent": "AUSENTES",
        "late": "TARDE",
        "detail": "DETALLE",
        "participant": "PARTICIPANTE",
        "status": "ESTADO",
        "modifyStatusHint": "¿DESEAS MODIFICAR EL ESTADO DE UN PARTICIPANTE?",
        "modifyStatusDescription": "Presiona sobre el estado de asistencia de cada joven para modificarlo.",
        "presentWithNotice": "Presente",
        "absentWithNotice": "Ausente CON aviso",
        "absentWithoutNotice": "Ausente SIN aviso",
        "lateWithNotice": "Tarde CON aviso",
        "lateWithoutNotice": "Tarde SIN aviso",
        "comments": "Comentarios",
        "send": "Enviar",
        "previous": "Anterior",
        "next": "Siguiente",
        "page": "Página"
    },
    "monitoringActivities": {
        "back": "Volver",
        "activitiesMonitoring": "SEGUIMIENTO DE ACTIVIDADES EN CLASE",
        "form": "FORMULARIO",
        "startDate": "FECHA INICIO",
        "endDate": "FECHA FIN",
        "student": "ALUMNO / A",
        "date": "FECHA",
        "status": "ESTADO",
        "initiate": "Iniciar",
        "continue": "Continuar",
        "view": "Ver",
        "viewResponse": "Ver respuesta",
        "enableEditing": "Habilitar edición",
        "selectClosingDate": "Seleccione una fecha de cierre de formulario.",
        "dateLabel": "Fecha",
        "cancel": "Cancelar",
        "accept": "Aceptar",
        "previousPage": "Anterior",
        "nextPage": "Siguiente",
        "page": "Página"
    },
    "progress": {
        "evaluationsFor": "EVALUACIONES PARA",
        "progressOf": "PROGRESO DE",
        "courseProgress": "PROGRESO DEL CURSO",
        "detailPerStudent": "DETALLE POR PARTICIPANTE",
        "currentLevel": "NIVEL EN CURSO",
        "errorFetchingParticipantData": "Error al obtener datos del participante.",
        "fromLevel": "DESDE NIVEL",
        "table": {
            "name": "PARTICIPANTE",
            "attendance": "ASISTENCIA",
            "punctuality": "PUNTUALIDAD",
            "diaries": "DIARIOS",
            "interviews": "ENTREVISTAS",
            "CV": "CV",
            "seeMore": "Ver más"
        },
        "graph": {
            "message": {
                "COMPETENCIA_TUTOR_INCOMPLETO": {
                    "title": "Tienes una evaluación pendiente",
                    "description": "Por favor, realiza la evaluación para ver la información completa."
                },
                "COMPETENCIA_ALUMNO_INCOMPLETO": {
                    "title": "Autoevaluación del alumno pendiente",
                    "description": "Para ver la información completa, el alumno debe realizar la autoevaluación."
                },
                "EQUIPO_ALUMNO_INCOMPLETO": {
                    "title": "Evaluación de compañeros pendiente",
                    "description": "Para ver la información completa, todos los participantes deben realizar la evaluación."
                }
            }
        },
        "boxCompetencias": {
            "seeDetails": "Ver detalle",
            "evaluationsCompetencies": "EVALUACIONES DE COMPETENCIAS DE",
            "competenciesEvaluationsAllLevels": "EVALUACIONES DEL ENTRENADOR - TODOS LOS NIVELES",
            "competenciesLevelDetail": "EVALUACIÓN DEL ENTRENADOR Y AUTOEVALUACIÓN - DETALLE POR NIVEL",
            "competenciesLevelTutorDetail": "EVALUACIÓN DEL ENTRENADOR - DETALLE POR COMPETENCIA Y POR NIVEL",
            "evaluationTitle": "EVALUACIÓN DE COMPETENCIAS",
            "tutorEvaluations": "Evaluaciones del entrenador",
            "selfEvaluations": "Autoevaluaciones",
            "viewResults": "Ver resultados",
            "errorFetchingData": "Error al obtener Evaluación de competencias.",
            "noComparative": "No hay comparativo",
            "noVariation": "Sin Variaciones respecto a la última evaluación",
            "fromLastEvaluation": "respecto a la última evaluación",
            "indicators": "INDICADORES",
            "yourEvaluationAbout": "TU EVALUACION SOBRE",
            "level": "NIVEL",
            "N/A": "N/A"
        },
        "boxGeneral": {
            "sectionTitle": "GENERAL",
            "attendance": "Asistencia",
            "punctuality": "Puntualidad",
            "learningDiaries": "Diarios de aprendizaje",
            "licenses": "Licencias",
            "errorFetchingData": "Error al obtener datos.",
            "viewResults": "Ver resultados"
        },
        "boxEquipo": {
            "title": "EVALUACIÓN DE EQUIPO",
            "recordsLevel": "Fichas de equipo Nivel",
            "errorFetchingData": "Error al obtener Evaluación de Equipo."
        },
        "boxPreparacionLaboral": {
            "title": "PREPARACIÓN LABORAL",
            "betterVersion": "Mejor versión",
            "laboralExperience": "Experiencia laboral",
            "formation": "Formación",
            "CVs": "CV's",
            "simulatedInterviews": "Entrevistas simuladas",
            "viewFeedbacks": "Ver devoluciones",
            "popup": {
                "title": "Entrevistas simuladas",
                "interview": "Entrevista",
                "Interviewer": "Entrevistador",
                "Punctuality": "Puntualidad",
                "Presence": "Presencia",
                "Attitude": "Actitud",
                "NonVerbalCommunication": "Comunicación no verbal",
                "Employable": "Empleable",
                "Strenghts": "Fortalezas",
                "Weaknesses": "Debilidades",
                "Excelente": "Excelente",
                "MuyBien": "Muy bien",
                "Bien": "Bien",
                "Regular": "Regular",
                "Mal": "Mal"
            }
        }
    },
    "notifications":{
        "INVALID_CREDENTIALS" : "Credenciales inválidas",
        "MOODLE_USER_NOT_FOUND" : "El usuario del Campus no se encuentra habilitado",
        "data-updated-successfully" : "Los datos se actualizaron correctamente",
        "cant-login-with-google" : "Aún no es posible iniciar sesión con google, intente nuevamente",
        "generic-error":"Se produjo un error desconocido",
        "image-updated-successfully":"Se actualizo correctamente la foto de perfil",
        "image-updated-error":"Ocurrio un error procesando la imagen",
        "data-error": "Ha ocurrido un error al cargar los datos.",
        "message-send-ok": "Se ha enviado el mensaje correctamente.",
        "message-send-error": "Ha ocurrido un error enviando el mensaje al alumno.",
        "status-change-ok": "Se ha cambiado estado del alumno correctamente.",
        "status-change-error":"'Ha ocurrido un error cambiando el estado del alumno.",
        "date-change-ok":"La fecha se ha modificado correctamente.",
        "date-change-error": "Ha ocurrido un error modificando la fecha de entrega.",
        "login": {
            "emailWillBeSend":"Si el usuario coincide con el email ingresado se enviará un correo con la nueva clave para acceder",
            "pleaseCompleteEmail":"Complete el email para recuperar la clave"
        },
        "home":{
            "cannotAccessCreadly":"No es posible acceder a insignias en este momento"
        }
    },
    "status":{
        "FINALIZADO": "FINALIZADO",
        "EN_CURSO": "EN CURSO",
        "SIN_INICIAR": "SIN INICIAR",
        "COMPLETED": "COMPLETO",
        "INCOMPLETED": "INCOMPLETO",
        "INCOMPLETO": "INCOMPLETO",
        "INICIADO": "INCOMPLETO",
        "COMPLETADO": "COMPLETADO",
        "PENDING": "PENDIENTE",
        "PENDIENTE": "PENDIENTE",
        "0": "SIN INICIAR",
        "1": "INCOMPLETO",
        "2": "COMPLETO"
    },
    "joven":{
        "status":{
            "FINALIZADO": "COMPLETO",
            "INICIADO": "INCOMPLETO"
        }
    },
    "attendance": {
        "-1": "AUSENTE SIN AVISO",
        "0": "PRESENTE", 
        "1": "TARDE SIN AVISO",
        "2": "AUSENTE SIN AVISO",
        "3": "AUSENTE CON AVISO",
        "4": "TARDE CON AVISO",
        "5": "AUSENTE CON AVISO"
    },
    "dias": {
        "0": "Domingo",
        "1": "Lunes",
        "2": "Martes",
        "3": "Miércoles",
        "4": "Jueves",
        "5": "Viernes",
        "6": "Sábado"
    },
    "meses": {
        "0": "Enero",
        "1": "Febrero",
        "2": "Marzo",
        "3": "Abril",
        "4": "Mayo",
        "5": "Junio",
        "6": "Julio",
        "7": "Agosto",
        "8": "Septiembre",
        "9": "Octubre",
        "10": "Noviembre",
        "11": "Diciembre"
    }
}