import interceptor from '@/plugins/interceptor';
const pathController = '/Alumno'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async student_uploadPicture(_, model) {
        return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/uploadPicture`, model);
    },
    async student_updateProfileData(_, model) {
        return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/updateProfileData`, model);
    },
  }
}