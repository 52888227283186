<template>
        <h5 class="errorBox">
            <i class="material-icons">cancel</i>
            <span>
                <b>Hubo un problema al cargar la información.</b>
                Por favor, vuelve a intentarlo más tarde.
            </span> 
        </h5>
</template>

<style lang="scss" scoped>
.errorBox{
    padding: 12px 16px;
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    background: rgba(245, 112, 85, 0.2);
    .material-icons{
        color: #F57055;
        font-size: 24px;
    }
    span{
        font-weight: 400;
        font-size: 15px;
        b{
            display: block;
            font-size: 16px;
            padding: 2px 0;
            font-weight: 700;
            color: #F57055;
        }
    }
}
</style>