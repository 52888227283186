<template>
  <Loader v-if="!dataLoaded" />
  <div class="monitoring" v-else >
    <div class="monitoring__back" @click="goBack">
      <i class="material-icons">arrow_back</i> {{ $t('global.back') }}
    </div>
    
    <div class="monitoring__header">
      <strong>{{ $t('monitoringActivities.activitiesMonitoring') }}</strong>
      <div>
        <span><strong>{{ $t('monitoringActivities.form') }}:</strong> {{ activitieData.nombre }}</span>
        <span><strong>{{ $t('monitoringActivities.startDate') }}:</strong> {{ formatDate(activitieData.fechaDesde) }}</span>
        <span><strong>{{ $t('monitoringActivities.endDate') }}:</strong> {{ formatDate(activitieData.fechaHasta) || '--/--/----' }}</span>
      </div>
    </div>
    <div class="table__container bx-shadow">
      <div class="table">
        <span class="table__title">{{ $t('monitoringActivities.student') }}</span>
        <span class="table__title">{{ $t('monitoringActivities.date') }}</span>
        <!-- <span class="table__title">EJE</span>
        <span class="table__title">COMPETENCIA</span> -->
        <span class="table__title">{{ $t('monitoringActivities.status') }}</span>
        <span class="table__title"></span>
        <template v-for="(result, idx) in alumnosPagination()">
          <span class="table__data" v-if="true" :key="idx + 'student'">{{ result.nombreCompleto }}</span>
          <span class="table__data" v-if="true" :key="idx + 'date'">{{ formatDate(result.fechaDesde) }}</span>
          <!-- <span class="table__data" v-if="false" :key="idx + 'eje'">{{ result.eje }}</span>
          <span class="table__data" v-if="false" :key="idx + 'competence'">{{ result.competence }}</span> -->
          <div  class="table__data" v-if="true" :key="idx + 'status'">
            <span :class="`status--${result.status}`">
              {{ $t(`status.${result.status}`) }}
              <i class="material-icons">
                {{ result.status === 0 ? 
                    'access_time' :
                      result.status === 1 ? 'highlight_off' : 
                    'check_circle'
                }}
              </i>
            </span>
          </div>
          <div class="table__review table__data tooltip" v-if="true" :key="idx + 'check'">
            <i @click="handleMoreOptions(idx)" class="material-icons">more_vert</i>
            <div v-if="moreOptionesShowed[idx]" class="tooltip-options bx-shadow">
              <ul>
                <li v-if="result.status == 0 && result.viewMode == 'EDITABLE'" @click.stop="goForm(result)">
                  <i class="material-icons">visibility</i> {{ $t('monitoringActivities.initiate') }}
                </li>
                <li v-else-if="result.status == 1 && result.viewMode == 'EDITABLE'" @click.stop="goForm(result)">
                  <i class="material-icons">visibility</i> {{ $t('monitoringActivities.continue') }}
                </li>
                <li v-else-if="result.status == 2 || result.grupal == 1" @click.stop="goForm(result)">
                  <i class="material-icons">visibility</i> {{ $t('monitoringActivities.view') }}
                </li>
                <li v-else class="disabled">
                  <i class="material-icons">visibility</i> {{ $t('monitoringActivities.viewResponse') }}
                </li>
                <li @click="modalIsOpen[idx] = true; newDate = result.fechaHasta || ''">
                  <i class="material-icons">edit</i> {{ $t('monitoringActivities.enableEditing') }}
                </li>
              </ul>
            </div>
          </div>
          <Modal :key="idx" v-if="modalIsOpen[idx]">
            <div class="editDate__modal">
              <h3 class="editDate__title">{{ $t('monitoringActivities.selectClosingDate') }}</h3>     
              <DatePicker 
                v-model="newDate"
                id="newDate"
                :label="$t('monitoringActivities.dateLabel')"
                :from="new Date()"
              />
              <div class="editDate__actions">
                <button @click="modalIsOpen[idx] = false" class="btn">{{ $t('monitoringActivities.cancel') }}</button>
                <button @click="modalIsOpen[idx] = false; editDate(result)" class="btn">Aceptar</button>
              </div>
            </div>
          </Modal>
        </template>
        <div class="table__pagination" style="margin-top: 12px;">
          <button @click="activitieData.page--" :disabled="activitieData.page === 1" ><i class="material-icons-outlined">arrow_forward</i>{{ $t('monitoringActivities.previousPage') }}</button>
          <span style="border-bottom: 0;"><strong>{{ $t('monitoringActivities.page') }} {{ activitieData.page }}</strong> / {{ activitieData.totalPages }}</span>
          <button @click="activitieData.page++" :disabled="activitieData.totalPages === activitieData.page" >{{ $t('monitoringActivities.nextPage') }} <i class="material-icons-outlined">arrow_forward</i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="loadingModal" v-if="editDateLoading">
    <Loader />
  </div>
</template>
<script>
import { inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Loader from '../../components/Loader.vue'
import Modal from '../../components/Common/Modal.vue'
import DatePicker from '../../components/DatePicker.vue';
import persistentData from '../../plugins/persistentData';
import moment from 'moment';
import { useStore } from 'vuex';
import base64url from 'base64url';

import { useI18n } from 'vue-i18n';


export default {
  components: {
    Loader,
    Modal,
    DatePicker
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const formatDate = inject('formatDate')
    const dateInit = ref(moment().format('DD/MM/YYYY'));
    const dateEnd = ref(moment().add(1, 'week').format('DD/MM/YYYY'));
    const activitieData = ref()
    const dataLoaded = ref(false)
    const moreOptionesShowed = ref({})
    const store = useStore()
    const route = useRoute()
    const modalIsOpen = ref({})
    const newDate = ref(null)
    const editDateLoading = ref(false)
    const user = ref(persistentData.get('user', true).userData);
    const getData = async () => {
      dataLoaded.value = false
      try {
        let body = JSON.parse(base64url.decode(route.params.hash))
        const { data } = await store.dispatch('post_instancia_formulario', body)
        activitieData.value = data.Data
        activitieData.value.page = 1
        activitieData.value.totalPages = Math.ceil(data.Data.formularios?.length / 10)
      } catch (err) {
        console.log(err)
      } finally {
        dataLoaded.value = true
      }
    }

    const goForm = (form) => {
      console.log(user.value);
      let json = {
        prevUrl: route.fullPath,
        formUCode: form.formUCode,
        theme: form.Theme,
        editable: (form.viewMode == 'EDITABLE'),
        externalUser: (user.value.Role == 3 ? 'true' : 'false')
      }
      let hash = base64url(JSON.stringify(json))
      router.push(`/forms/${hash}`)
    }

    const alumnosPagination = () => {
      let start = activitieData.value.page === 1 ? 0 : (activitieData.value.page - 1) * 10
      let end = activitieData.value.page * 10
      return activitieData.value.formularios?.slice(start, end)
    }

    const handleMoreOptions = (idx) => {
      // eslint-disable-next-line no-prototype-builtins
      if (moreOptionesShowed.value.hasOwnProperty(idx)) {
        if (moreOptionesShowed.value[idx]) {
          Object.keys(moreOptionesShowed.value).forEach(v => moreOptionesShowed.value[v] = false)
          return
        }
      }
      Object.keys(moreOptionesShowed.value).forEach(v => moreOptionesShowed.value[v] = false)
      moreOptionesShowed.value[idx] = true
      return
      
    }

    const editDate = async (alumno) => {
      const req = {
        alumnoFormularioId: alumno.alumnoFormularioId,
        formularioId: alumno.formularioId,
        fechaHasta: moment(newDate.value).format('yyyy-MM-DD')
      }
      try {
        editDateLoading.value = true
        await store.dispatch('edit_fechaFormulario', req)
        
        activitieData.value.formularios =activitieData.value.formularios.map(al => { 
          return {
            ...al,
            fechaHasta: al.alumnoFormularioId === req.alumnoFormularioId ? req.fechaHasta : al.fechaHasta 
          }
        })
        store.dispatch('notifications_create', { text: t('notifications.date-change-ok') })
      } catch (err) {
        console.log(err)
        store.dispatch('notifications_create', { text: t('notifications.date-change-error') })
      } finally {
        editDateLoading.value = false
        moreOptionesShowed.value = {}
      }
    }
    

    onMounted(() => {
      getData()
    });

    function goBack() {
      router.push('/')
    }
    
    return {
      dateInit,
      modalIsOpen,
      dateEnd,
      router,
      goBack,
      formatDate,
      activitieData,
      dataLoaded,
      moreOptionesShowed,
      handleMoreOptions,
      alumnosPagination,
      newDate,
      editDate,
      editDateLoading,
      goForm
    }
  }
}
</script>
<style lang="scss">
.monitoring {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 79px);
  gap: 24px;
  width: 100%;
  padding: 24px 2%;
  max-width: 1024px;
  margin: 0 auto;

  &__back {
    color: $lightblue;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 4px;

    i {
      font-size: 16px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 24px;

    div {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 560px){
        flex-direction: column;
        gap: 24px;
      }
      span {
        font-weight: lighter;
      }
    }
  }
}

.table{
  display: grid;
  // grid-template-columns: 2fr repeat(4  , 1fr) .5fr;
  grid-template-columns: minmax(235px, 2fr) repeat(2, 1fr) .5fr;
  gap: 12px 0;
  width: 100%;
  padding: 24px 38px;
  border-radius: 5px;
  &__container{
    padding: 0 5px;
    max-height: 1100px;
    height: max-content;
    overflow: auto;
    padding-bottom: 12px;
  }
  &__title{
    font-size: 12px !important;
    font-weight: 700 !important;
  }
  &__data{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #BDBDBD;
    text-align: start;
    padding-right: 12px;
    padding-bottom: 12px;
    text-transform: capitalize;
  }
  &__review{
    transition: all .2s ease-in-out;
    &:hover{
      opacity: .8;
    }
    cursor: pointer;
    color: $blue;
    li.disabled {
      cursor: default;
      opacity: 50%;
    }
  }
  &__pagination{
    grid-column-start: 1;
    // grid-column-end: 7;
    grid-column-end: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      strong{
        font-size: 16px;
        font-weight: 600;
      }
    }
    button{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      gap: 6px;
      cursor: pointer;
      color: $lightgreen;
      &:disabled{
        color: #000;
        opacity: .8;
        cursor: default;
      }
      &:first-child{
        .material-icons-outlined{
            transform: rotateY(180deg)
        }
      }
      .material-icons-outlined{
        font-size: 16px;
      }
    }
  }
} 

.loadingModal{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
}

.editDate{
  &__modal{
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    width: 100%;
    max-width: 475px;
  }
  &__title{
    font-size: 16px;
    text-align: start;
  }
  &__actions{
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 510px) {
      flex-direction: column-reverse;
      .btn{
        width: 100% !important;
      }
    }
    .btn{
      padding: 12px 0;
      width: 45%;
      &:first-child{
        background-color: inherit;
        color: #4833E9;
      }
      &:last-child{
        background-color: #4833E9;
        color: #fff;
      }
    }
  }
}

.tooltip {
  position: relative;
  opacity: 1 !important;
  
  &-options {
    display: block;
    position: absolute;
    background: white;
    width: 180px;
    right: 0;
    top: 29px;
    padding: 8px;
    border-radius: 12px;
    z-index: 3;
    ul li {
      display: flex;
      gap: 8px;
      padding: 4px;
      color: $black;
    }
  }
}
</style>