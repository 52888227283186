<template>
  <div v-if="type.toLowerCase() === 'textarea'" class="input">
    <div class="input__textarea_title">
      <span class="input__textarea_label">{{ label }}</span>
      <span class="input__textarea_maxlength">Máx 300 caracteres</span>
    </div>
    <textarea class="input__textarea" maxlength="300" :id="id" :placeholder="placeholder" :value="modelValue"
      :disabled="disabled" @input="updateValue"></textarea>
    <div :style="{ display: 'flex', flexDirection: 'column', gap: '6px' }">
      <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
      <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
        supportText }}</span>
    </div>
  </div>

  <template v-else-if="type.toLowerCase() === 'password'">
    <div class="password_container">
      <div class="input">
        <input :class="`input__input`" :type="showPassword ? 'text' : 'password'" :placeholder="placeholder" :id="id"
          :disabled="disabled" :value="modelValue" @input="validateCharacter($event)" @focus="modelValue ? true : false"
          ref="input" />
        <label
          :class="`input__label${modelValue?.length && '--completed' || ''} ${isError && 'input__label--isError' || ''} ${disabled && 'input__label--disabled' || ''}`"
          :for="id">{{ label }} <span v-if="optional" class="input__optional">{{ 'Opcional' }}</span></label>
        <i @click="showPassword = !showPassword" class="material-icons-outlined input__tooltip">{{ showPassword ?
          'visibility_off' : 'visibility' }}</i>
      </div>
      <div v-if="showInvalidCharacter" class="invalid-character"><i class="material-icons">error</i><span>La contraseña
          solo puede contener letras y números</span></div>
      <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
      <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
        supportText }}</span>
    </div>
  </template>

  <template v-else-if="type.toLowerCase() === 'number'">
    <div style="width: 100%;">
      <div class="input">
        <input :class="`input__input`" type="number" :placeholder="placeholder" :id="id" :disabled="disabled"
          :value="modelValue" @input="updateValue" min="0" ref="input" />
        <label
          :class="`input__label${modelValue && '--completed' || ''} ${isError && 'input__label--isError' || ''} ${disabled && 'input__label--disabled' || ''}`"
          :for="id">{{ label }} <span v-if="optional" class="input__optional">{{ 'Opcional' }}</span></label>
      </div>
      <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
      <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
        supportText }}</span>
    </div>
  </template>

  <template v-else-if="type.toLowerCase() === 'time'">
    <div style="width: 100%;">
      <div class="input">
        <input :class="`input__input`" :type="!modelValue ? 'text' : 'time'" :placeholder="placeholder" :id="id"
          :disabled="disabled" :value="formatHour(modelValue, 0, gmtDate)" @input="updateValue" min="0"
          onfocus="(this.type='time')" onblur="if(!this.value) this.type = 'text'" ref="input" />
        <label
          :class="`input__label${modelValue?.length && '--completed' || ''} ${isError && 'input__label--isError' || ''} ${disabled && 'input__label--disabled' || ''}`"
          :for="id">{{ label }} <span v-if="optional" class="input__optional">{{ 'Opcional' }}</span></label>
      </div>
      <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
      <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
        supportText }}</span>
    </div>
  </template>

  <template v-else>
    <div style="width: 100%;">
      <div class="input">
        <input :class="`input__input`" type="text" :placeholder="placeholder" :id="id" :disabled="disabled"
          :value="modelValue" @input="updateValue" @focus="modelValue ? true : false" ref="input" />
        <label
          :class="`input__label${modelValue?.length && '--completed' || ''} ${isError && 'input__label--isError' || ''} ${disabled && 'input__label--disabled' || ''}`"
          :for="id">{{ label }} <span v-if="optional" class="input__optional">{{ 'Opcional' }}</span></label>
        <i @mouseover="showTooltip = true" @mouseleave="showTooltip = false" v-if="tooltipTitle || tooltipMessage"
          class="material-icons input__tooltip">error</i>
        <ToolTip v-if="showTooltip" :title="tooltipTitle" :message="tooltipMessage" />
      </div>
      <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
      <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
        supportText }}</span>
    </div>

  </template>
</template>

<script>
import { ref } from 'vue';
import moment from 'moment'

export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String
    },
    gmtDate: {
      default: 0
    },
    placeholder: {
      type: String
    },
    id: {
      required: true
    },
    ignorePasswordValidation: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    errorMessage: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    optional: {
      type: Boolean
    },
    supportText: {
      type: String
    },
    supportTextIcon: {
      type: String
    },
    modelValue: {
      required: true
    },
    tooltipTitle: {
      type: String
    },
    tooltipMessage: {
      type: String
    }
  },
  setup(props, { emit }) {
    const showTooltip = ref(false);
    const showPassword = ref(false)
    const showInvalidCharacter = ref(false)
    const input = ref(null)

    const updateValue = (event) => {
      if (props.modelValue !== event.target.value) {
        event.target.focus()
      }
      if (props.type === 'number') {
        if (event.target.value.includes('-')) {
          event.target.value = 0
        }
        emit('update:modelValue', event.target.value)
        return
      }
      if (props.type === 'time') {
        emit('update:modelValue', formatHour(event.target.value, props.gmtDate, 0))
        return
      }
      emit('update:modelValue', event.target.value)
    }

    function formatHour(hora, gmtActual, gmtDeseado) {
      let horaActual
      if (gmtDeseado === 0) {
        horaActual = moment(hora, "HH:mm").utcOffset(gmtActual, true);
      } else {
        horaActual = moment.utc(hora, "HH:mm").utcOffset(gmtActual, true);
      }

      const horaDeseada = horaActual.clone().utcOffset(gmtDeseado);

      const horaFormateada = horaDeseada.format("HH:mm");

      return horaFormateada === 'Invalid date' ? '' : horaFormateada;
    }

    function validateCharacter(event) {
      const passwordPattern = /^[a-z0-9]+$/i
      if (!passwordPattern.test(event.target.value) && event.target.value.length !== 0 && props.ignorePasswordValidation) {
        event.preventDefault();
        showInvalidCharacter.value = true;
        setTimeout(() => {
          showInvalidCharacter.value = false;
        }, 2000)
      }
      else {
        emit('update:modelValue', event.target.value)
      }
    }

    return {
      showTooltip,
      showPassword,
      validateCharacter,
      showInvalidCharacter,
      updateValue,
      input,
      formatHour
    };
  },
}
</script>

<style lang="scss" scoped>
textarea {
  resize: vertical;
  width: 100%;
}

.toolTip {
  right: 0;
  z-index: 9999;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 14px;
  right: 28px;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.password_container {
  .invalid-character {
    width: 206px;
    //height: 90px;
    position: absolute;
    left: 56px;
    top: -40px;
    padding: 8px;
    background: #fff;
    border: 1px solid;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;

    .material-icons {
      position: relative;
      padding-right: 0;
    }
  }
}

.input {
  position: relative;
  width: 100%;

  input::-webkit-input-placeholder {
    transition: color .3s;
    color: transparent;
  }

  input:focus::-webkit-input-placeholder {
    color: #7d7d7d;
  }

  &__tooltip {
    position: absolute;
    right: 28px;
    top: 14px;
    color: $darkgray;
    transform: rotate(180deg);
    transition: all .3s;

    &:hover {
      color: $black;
    }
  }

  &__textarea {
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: #f5f5f5;
    padding: 22px;
    width: 100%;

    &::placeholder {
      font-size: 12px;
      font-style: italic;
    }

    &_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
    }

    &_label {
      font-weight: 700;
      font-size: 12px;
      color: $black;
    }

    &_maxlength {
      font-size: 11px;
      font-weight: 600;
      color: #DBDBDB;
    }
  }

  &__supportText {
    font-weight: 600;
    font-size: 10px;
    color: $black;
    padding: 0 28px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;

    .material-icons {
      font-size: 16px;
    }
  }

  &__input {
    width: 100%;
    background: #f5f5f5;
    padding: 18px 28px 6px 28px;
    border: none;
    outline: none;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    transition: all .2s;
    height: 50px !important;

    &:focus+.input__label {
      transform: translateY(3px);
      font-size: 10px;
      color: $lightblue;

      .input__optional {
        font-size: 10px;
      }
    }

    &:hover {
      background-color: #DBDBDB;
    }

    &--error {
      @extend .input__input;
      outline: 1px solid $red;

      &:focus+.input__label {
        transform: translateY(3px);
        font-size: 10px;
        color: $red;
      }
    }

    &:disabled+.input__label {
      opacity: .6;
      font-weight: 600;
    }

    &--date {
      font-size: 0;

      &:focus {
        font-size: 13px;
      }
    }
  }

  &__date_icon {
    position: absolute;
    right: 28px;
    top: 14px;
    cursor: pointer;
  }

  &__calendar {
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 16px 14px;

    &_selectMonthAndYear {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 10px;
      font-size: 12px;

      span {
        font-weight: 700;
        padding: 6px 18px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ddd;
        }
      }

      .material-icons {
        cursor: pointer;
      }
    }

    &_grid {
      display: grid;
      grid-template-columns: repeat(7, minmax(21px, 1fr));
      grid-template-rows: repeat(6, minmax(21px, 1fr));
      font-size: 12px;
      text-align: center;
      align-items: center;
      justify-items: center;

      &--day {
        font-weight: 700;
        cursor: default !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      span {
        cursor: pointer;
        padding: 8px;
        border-radius: 999px;
        width: 100%;

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  &__label {
    cursor: text;
    position: absolute;
    top: 0;
    left: 28px;
    user-select: none;
    transform: translateY(12px);
    transition: transform .5s, color .3s, font-size .5s;
    line-height: 25px;
    font-size: 13px;
    font-weight: 400;

    &--completed {
      @extend .input__label;
      transform: translateY(3px);
      font-size: 10px;
      color: $lightblue;

      .input__optional {
        font-size: 10px;
      }
    }

    &--isError {
      color: $red !important;
    }

    &--disabled {
      opacity: .6;
      color: $black;
    }
  }

  &__errorMessage {
    font-weight: 600;
    font-size: 10px;
    color: $red;
    padding-left: 28px;
  }

  &__optional {
    font-size: 13px;
    font-style: italic;
    color: #7d7d7d;
    transition: font-size .5s;
  }
}
</style>