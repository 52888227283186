<template>
    <div id="pc" class="full columns center">
        <div class="profile-card columns">
            <div class="profile__head full rows">
                <img src="@/assets/icons/avatar.svg" alt="">
                <h3>{{$t('profile.title')}}</h3>
            </div>
            <div class="profile__content center">
                <div class="avatar columns">
                    <div :style="`background-image:url('${sourceProfilePicture || require('@/assets/images/avatar-test.jpg')}')`" class="img"></div>
                    <div class="avatar__camera center pointer">
                        <label for="picture" style="display: flex; align-items: center;">
                            <i class="material-icons-outlined">photo_camera</i>
                            <input id="picture" type="file" style="display: none" @change="uploadProfilePicture($event)" accept="image/png, image/jpeg">
                        </label>
                    </div>
                    
                </div>
                <div class="data center columns">
                    <h4 class="full left">{{$t('profile.fullName')}}</h4>
                    <span class="full left-c">{{user.Nombre}} {{user.Apellido}}</span>
                    <h4 class="full left">{{"Email"}}</h4>
                    <span class="full left-c">{{user.Email}}</span>
                    <!--<h4 class="full left">{{"Institucion"}}</h4>
                    <input type="text" placeholder="PircaSchool" class="full" v-model="userProfile.institucionEducativa">-->
                </div>
            </div>
            <div class="profile__button full rows">
                <button v-if="showButton" class="btn btn-small pointer edit" @click="toggleCropper">{{$t('profile.photoEdit')}}</button>
                <button class= "btn btn-small pointer save" style="background-color: #4833E9; color: #fff;" @click="
                toggleConfirmation">{{$t('profile.saveChanges')}}</button>
            </div>
            
        </div>
    <Modal  v-if="showCropper">
        <div class="center columns">
            <cropper  class="cropper" :src="sourceProfilePicture" ref="cropper" @change="changeCropper"/>
            <button class="btn btn-small btn-cropper" @click="savePictureCropper">{{$t('profile.acceptBtn')}}</button>  
        </div>
    </Modal>

    <Modal v-if="showConfirmation">
        <div class="center columns">
            <div class="text-modal center">
                <span class="full text">
                    {{$t('profile.shureSaveChanges')}}
                </span>
            </div>
            <div class="buttons center rows full">
                <button :disabled="processing" class="btn btn-small red" @click="reloadPage">
                    {{$t('profile.cancelBtn')}}
                </button>
                <button :disabled="processing" class="btn btn-small" @click="saveChanges">
                    {{$t('profile.saveBtn')}}
                </button>
            </div>
        </div>
    </Modal>
    </div>
   
    
</template>
<script>
    import { computed ,onMounted } from '@vue/runtime-core';
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n';
    import { Cropper } from 'vue-advanced-cropper'
    import { useStore } from 'vuex';
    import 'vue-advanced-cropper/dist/style.css'
    import Modal from '@/components/Common/Modal.vue'
    import { useRouter } from 'vue-router';
    
    export default {
        components: {
            Cropper,
            Modal
            
        },
        setup() {
            const store = useStore();
            const { t } = useI18n();
            const sourceProfilePicture = ref(null)
            const sourceProfilePictureCropper = ref(null)
            const showCropper = ref(false)
            const showButton = ref(false)
            const user = computed(() => store.getters.manageUser_user.userData);
            const userProfile = ref({picture: "", institucionEducativa: ""})
            const showConfirmation = ref(false)
            const processing = ref(false)
            const router = useRouter();
            
            onMounted(() => {
                sourceProfilePicture.value = `${process.env.VUE_APP_API_URL}/Alumno/imagen/${user.value.SysUserUId}?v=${Math.floor(Math.random() * 10000)}`
            });
            async function uploadProfilePicture(event) {
                console.log(event)
                sourceProfilePicture.value = await getBase64(event.target.files[0])
                //toggleEditButton()
                showButton.value = true
            }

            function getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
            function toggleEditButton() {
                showButton.value = !showButton.value
            }
            function changeCropper({ canvas }) {
                sourceProfilePictureCropper.value = canvas.toDataURL();
            }
            function toggleCropper() {
                showCropper.value = !showCropper.value
            }
            async function saveChanges() {
                try {
                    processing.value = true
                    if(sourceProfilePicture.value.startsWith("data"))
                    {
                        const request = await store.dispatch('student_updateProfileData', { Picture: sourceProfilePicture.value, InstitucionEducativa: userProfile.value.institucionEducativa});
                        console.log(request);
                        if (request.status === 200) {
                            processing.value = false
                            store.dispatch('notifications_create', { text: t(`notifications.image-updated-successfully`) })
                            //store.dispatch('notifications_create', { text: t(`notifications.data-updated-successfully`) })
                        }
                    }
                    else
                    {
                        processing.value = false
                        store.dispatch('notifications_create', { text:  t(`notifications.image-updated-error`) })
                        //store.dispatch('notifications_create', { text: t(`notifications.data-updated-successfully`) });
                    }
                } catch (e) {
                    processing.value = false
                    if(typeof(e.response.data) == 'string')
                        store.dispatch('notifications_create', { text: t(`notifications.${e.response.data}`) });
                    else
                        store.dispatch('notifications_create', { text: t(`notifications.generic-error`) });
                }
                toggleEditButton()
                toggleConfirmation()
            }
            function savePictureCropper() {
                showCropper.value = false
                sourceProfilePicture.value = sourceProfilePictureCropper.value
            }
            function toggleConfirmation() {
                showConfirmation.value = !showConfirmation.value
            }

            function reloadPage() {
                toggleConfirmation()
                router.go()
            }

            return{
                user,
                processing,
                sourceProfilePicture,
                sourceProfilePictureCropper,
                savePictureCropper,
                uploadProfilePicture,
                changeCropper, 
                showCropper,
                toggleCropper, 
                showButton,
                saveChanges,
                userProfile,
                showConfirmation,
                toggleConfirmation,
                toggleEditButton,
                reloadPage,
                router
            }
        }
    }
</script>
<style lang="scss" scoped>
    * {
        padding: 0;
        margin: 0;
    }
    
#pc {
    height: calc(100vh - 88px);
    width:100%;
    background: $lightgray;
    justify-content: flex-start;
    padding: $margin-small;
    
    .profile-card {
        max-width: 400px;
        padding: $margin-small;
        background: $white;
        width: 100%;
        border-radius: $redondeadobig;
        box-shadow: 0 4px 6px rgb(0 0 0 / 25%);
        padding: $margin-small;


        .profile__head {
            margin: $margin-xmilli;
            
            h3 {
                @include font-settings(milli, xmega);
                }
            
            img {
                margin: 0 $margin-milli;
            }
        }

        .profile__content {
            flex-direction: column;
            border-top: 1px solid $gray;
            padding: $margin-milli;
            justify-content: space-between;

            .avatar {
                height: 100%;
                padding-top: 12px;
                flex-direction: column-reverse;
                justify-content: flex-start;
                position: relative;
                
                &__camera {
                    position: absolute;
                    height: 28px;
                    width: 28px;
                    border: none;
                    z-index: 1;
                    right: 4px;
                    border-radius: 50%;
                    background: $lightblue;
                    padding: $margin-hmilli;
                    .material-icons-outlined{
                        color: #fff;
                        font-size: 18px;
                    }
                }

                .img {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    border: 1px solid $black;
                    background-size: cover;
                    background-position: center center;
                }
            }
            .data {
                padding: $margin-milli 0;
                height: 100%;
                width: 100%;
                
                h4 {
                    @include font-settings(small, mega);
                }

                span {
                    @include font-settings(milli, xmega);
                }

                input {
                    @include font-settings(milli, xmega);
                    border: none;
                    border-bottom: 1px solid $gray;
                }
            }
        }

        .profile__button {
                justify-content: space-between;
                position: relative;
                min-height: 36px;
                .save {
                    position: absolute;
                    min-width: 120px;
                    @include font-settings(small, mega);
                    right: 0;
                    padding: 8px 0;
                }
                .edit {
                    min-width: 100px;
                    position: absolute;
                    @include font-settings(small, mega);
                    left: 0;
                }
            }
    }

    @media(min-width: 960px) {
        padding-top: $margin-mega;
        height: calc(100vh - 48px);
        .edit-btn-mob {
            display:none;
        }
        .profile-card {
            max-width: 600px;
            padding: $margin-base;

            .profile__head {
                margin: 4px;
                
                h3 {
                    font-size: 18px;
                    }
                
                img {
                    margin: 0 8px;
                }
            }

            .profile__content {
                flex-direction: row;
                border-top: 1px solid $gray;
                padding: 12px;
                justify-content: space-between;

                .avatar {
                    height: 100%;
                    padding-top: 12px;
                    justify-content: flex-start;

                    &__camera {
                        height: 36px;
                        width: 36px;
                        background: $lightblue;
                        .material-icons-outlined{
                            color: #fff;
                            font-size: 20px;
                        }
                    }

                    .img {
                        height: 140px;
                        width: 140px;
                        border-radius: 50%;
                        border: 1px solid $black;
                    }
                }

                .data {
                    padding: 12px;
                    height: 100%;
                    width: 400px;
                    
                    h4 {
                        @include font-settings(small, mega);
                    }

                    span {
                        @include font-settings(milli, xmega);
                    }

                    input {
                        @include font-settings(milli, xmega);
                        border: none;
                        border-bottom: 1px solid $gray;
                    }
                }
            }

            .profile__button {
                button {
                    min-width: 160px;
                    @include font-settings(xmilli, base)
                }
            }
            
        }
    }
}

.cropper {
    width: 100%;
    max-width: 330px !important;
}

.btn-cropper {
        margin-top: $margin-base;
        min-width: 120px;
    }
.buttons {
    justify-content: space-evenly;
    button {
        min-width: 120px;
    }
}


 
</style>