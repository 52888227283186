<template>
    <div class="breakpoints-container">

        <header>
            <div class="btn-return">
                <span @click="router.push(`/`)"><i class="material-icons">arrow_back</i>{{ $t('global.back') }}</span>
            </div>
            <h2>
                <template v-if="courseData">
                    <div><h3>{{ $t('progress.courseProgress') }}</h3><h4>{{ courseData.Codigo }}</h4></div>
                    <div><h3>{{ $t('progress.currentLevel') }}:</h3><h4>{{ courseData.Nivel }}</h4></div>
                </template>
                <template v-else>
                    <Skeletor width="280" height="19" />
                    <Skeletor width="130" height="19" />
                </template>
            </h2>
        </header>

        <h2>
            <h4>{{ $t('progress.detailPerStudent') }}</h4>
            <button v-show="false"><i class="material-icons">file_download</i>Descargar informe</button>
        </h2>

        <div class="tabla__scroll-container">

            <div class="tabla">

                <div class="name">
                    <span class="th">{{ $t('progress.table.name') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">{{ part.NombreCompleto }}</span>
                </div>

                <div class="tabla__column">
                    <span class="th">{{ $t('progress.table.attendance') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="circle" :class="`--${part.Asistencia}`"></i> 
                    </span>
                </div>
                <div class="tabla__column">
                    <span class="th">{{ $t('progress.table.punctuality') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="circle" :class="`--${part.Puntualidad}`"></i> 
                    </span>
                </div>
                <div class="tabla__column">
                    <span class="th">{{ $t('progress.table.diaries') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="circle" :class="`--${part.Diarios}`"></i> 
                    </span>
                </div>
                <div class="tabla__column">
                    <span class="th">CAMPUS</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="circle" :class="`--${part.Campus}`"></i> 
                    </span>
                </div>
                <div class="avatar">
                    <span class="th">AVATAR</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        {{part.Avatar}}
                    </span>
                </div>
                <div class="tabla__column">
                    <span class="th">{{ $t('progress.table.interviews') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="status" :class="`status--${part.Entrevistas}`">{{part.Entrevistas}}</i> 
                    </span>
                </div>
                <div class="tabla__column">
                    <span class="th">{{ $t('progress.table.CV') }}</span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <i class="status" :class="`status--${part.Cv}`">{{part.Cv}}</i> 
                    </span>
                </div>

                <div class="tabla__column btn-more">
                    <span class="th"></span>
                    <span v-for="(part, idx) in participantes" :key="idx">
                        <button @click="router.push(`/seguimiento/${route.params.courseId}/alumno/${part.Id}`)">{{ $t('progress.table.seeMore') }}</button>
                    </span>
                </div>

            </div>

        </div>

        <Loader v-if="loading" />

    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Loader from '@/components/Loader.vue';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';

const router = useRouter();
const route = useRoute();
const store = useStore();

const courseData = ref(null);
const participantes = ref([]);
const loading = ref(false);

const pagination = ref({
    pageSize: 10,
    pageNumber: 0,
})
const totalResults = ref(0);
const remainResults = ref(0)

async function getParticipantes(){
    try {
        loading.value = true;
        let req = await store.dispatch('seguimiento_alumnosCurso', { courseId: route.params.courseId, pageSize: pagination.value.pageSize, pageNumber: pagination.value.pageNumber });
        courseData.value = req.data.Curso;
        participantes.value.push(...req.data.Alumnos);
        totalResults.value = req.data.TotalResults;
        remainResults.value = totalResults.value - ( (pagination.value.pageNumber+1) * pagination.value.pageSize );
        loading.value = false;
    } catch (error) {   
        console.log(error);
        store.dispatch('notifications_create', { text: 'Error al obtener participantes.' })   
    }
}

const handleScroll = () => {
  const bottomOfWindow = document.documentElement.scrollHeight - window.innerHeight;
  if (window.scrollY >= bottomOfWindow - 50) {
    if(!loading.value && remainResults.value > 0){
        pagination.value.pageNumber++; 
        getParticipantes();
    }
  }
}

onMounted(()=>{
    getParticipantes()
    window.addEventListener('scroll', handleScroll);
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});


</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.breakpoints-container{
    padding-bottom: 36px;
}

header{
    padding: 22px 0;
    h2{
        margin: 0;
        display: flex;
        justify-content: space-between;
        @media(width < 600px){
            flex-direction: column;
            gap: 12px;
        }
        div{
            display: flex;
            gap: 4px;
            align-items: flex-end;
            h3{
                font-size: 16px;
            }
            h4{
                padding-left: 4px;
                font-size: 16px;
                color: blue;
            }
        }
    }
}


h2{
    margin: 32px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    button{
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;
        font-weight: 600;
        color: $lightblue;
        .material-icons{
            font-weight: 400;
            font-size: 20px;
        }
    }
    @media(width < 600px){
        h4{
            font-size: 14px;
        }
        button{
            font-size: 13px;
            gap: 3px;
            .material-icons{
                font-size: 16px;
            }
        }
    }
}

.tabla{
    width: fit-content;
    min-width: 100%;
    display: flex;
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    &__scroll-container{
        width: 100%;
        overflow-x: auto;
        box-shadow: $boxesShadow;
        border-radius: $cardRadius;
    }
    >div{
        display: flex;
        flex-direction: column;
        &:last-child{
            border: none;
            .th{
                border-radius: 0 $cardRadius 0 0;
            }
        }
        span{
            width: 100%;
            height: 60px;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid rgba(189, 189, 189, 0.5);
            &:last-child{
                border: none;
            }
        }
    }
    .th{
        width: 100%;
        height: 52px;
        padding-bottom: 8px;
        align-items: flex-end;
        font-size: 12px;
        font-weight: 700;
        border: none;
    }
    .avatar{
        span{
            width: 100%;
            padding-left: 20px;
            justify-content: center;
            font-size: 13px;
            font-weight: 600;
        }
    }
    .name{
        flex-grow: 1;
        width: 190px;
        .th{
            border-radius: $cardRadius 0 0 0;
            font-weight: 700;
        }
        span{
            width: 100%;
            padding-left: 20px;
            justify-content: flex-start;
            font-size: 13px;
            font-weight: 600;
        } 
    }
    &__column{
        width: 116px;
        .circle{
            width: 9px;
            height: 9px;
            border-radius: 50%;
            &.--ROJO{
                background: $rojo;
            }
            &.--AMARILLO{
                background: $amarillo;
            }
            &.--VERDE{
                background: $verde;
            }
        }
        .status{
            width: fit-content;
            font-size: 10px;
            font-weight: 700;
            border-radius: 5px;
            padding: 2px 10px;
            font-style: normal;
            &--PENDIENTE{
                color: $yellow;
                background: rgba(255, 182, 0, 0.15);
            }
            &--COMPLETADO{
                color: $green;
                background: rgba(0, 197, 143, 0.10);
            }
        }
        &.btn-more{
            width: 80px;
            span{
                justify-content: flex-end;
            }
            button{
                font-size: 13px;
                color: $lightblue;
            }
        }
    }
}


</style>