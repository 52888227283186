<template>

    <div class="module-box">
        <h4 :style="levelNotAllowed ? 'margin-bottom:0px' : ''">
            {{ $t('progress.boxCompetencias.evaluationTitle') }}
            <span class="data__empty" v-if="levelNotAllowed">{{ $t('progress.fromLevel') }} {{ data.DisponibleDesdeNivel }}</span>
        </h4>
        <template v-if="!reqError">

            <div v-if="!data || (data && !levelNotAllowed)">
    
                <div class="evaluacion">

                    <div class="reference">
                        <template v-if="data">
                            <i class="material-icons">moving</i>
                            <span>{{ $t('progress.boxCompetencias.tutorEvaluations') }}</span>
                            <div class="circle" :class="`--${data.EvaluacionDeTutor.Color}`"></div>                    
                        </template>
                        <template v-else>
                            <Skeletor height="23" width="23" />
                            <Skeletor height="16" width="134" />
                            <Skeletor height="9" width="9" />
                        </template>
                    </div>
                    <div class="data">
                        <template v-if="data">
                            <span>{{data.EvaluacionDeTutor.Realizados + '/' + data.EvaluacionDeTutor.Totales}}</span>
                            <span>{{data.EvaluacionDeTutor.Porcentaje + ' %'}}</span>
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                            <Skeletor width="80" height="49" />
                        </template>
                    </div>

                </div>
    
                <div class="evaluacion">

                    <div class="reference">
                        <template v-if="data">
                            <i class="material-icons">moving</i>
                            <span>{{ $t('progress.boxCompetencias.selfEvaluations') }}</span>
                            <div class="circle" :class="`--${data.AutoEvaluacion.Color}`"></div>
                        </template>
                        <template v-else>
                            <Skeletor width="23" height="23" />
                            <Skeletor width="107" height="16" />
                            <Skeletor width="9" height="9" />
                        </template>
                    </div>
                    <div class="data">
                        <template v-if="data">
                            <span>{{data.AutoEvaluacion.Realizados + '/' + data.AutoEvaluacion.Totales}}</span>
                            <span>{{data.AutoEvaluacion.Porcentaje + ' %'}}</span>                    
                        </template>
                        <template v-else>
                            <Skeletor width="60" height="49" />
                            <Skeletor width="80" height="49" />
                        </template>
                    </div>

                </div>
                
                <span v-if="data" class="show-modal" :style="data.PuedeVerDetalle ? 'cursor:pointer; opacity:1' : 'cursor:default; opacity:.5;'"
                    @click="data.PuedeVerDetalle ? (modalDetail.render = true, modalDetail.show = true) : ''">
                    {{ $t('progress.boxCompetencias.viewResults') }}
                </span>

                <Skeletor v-else class="show-modal" width="84" height="16" />
    
            </div>
        </template>

        <ErrorBox v-else />
    </div>

    <ModalDetail v-if="modalDetail.render"
        :show="modalDetail.show"
        :studentName="props.studentName"
        @close="modalDetail.show = false"
    />
    
</template>

<script setup>
import { onMounted, ref, defineProps, watch, onBeforeUnmount } from 'vue'; 
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ModalDetail from './ModalDetail.vue';
import ErrorBox from '../ReqError.vue';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';

const store = useStore();
const route = useRoute();
const data = ref(null);
const reqError = ref(false);

const props = defineProps({
    studentName: String,
    studentLevel : Number
})

const modalDetail = ref({
    render: false,
    show: false
});

const levelNotAllowed = ref(false);

watch(()=>modalDetail.value.show, ()=>{
    modalDetail.value.show
        ? (window.scrollTo({top: 0}), document.body.style.overflowY = "hidden")
        : document.body.style.overflowY = "auto";
})

async function getData(){
    try {
        let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'EVALUACION_DE_COMPETENCIAS', type: 'GENERAL' })
        data.value = req.data;
        levelNotAllowed.value = data.value.DisponibleDesdeNivel > props.studentLevel;
    } catch (error) {
        console.log(error);
        reqError.value = true;
    }
}

onMounted(
    getData()
)
onBeforeUnmount(()=>{
    document.body.style.overflowY = "auto";
})

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.data__empty{
    padding: 4px 8px;
    margin-left: 12px;
    font-size: 12px !important;
    font-weight: 700 !important;
    color: #BDBDBD;
    background: #F6F6F6;
    border-radius: 5px;
}

</style>