<template>
  <div class="tab-content full columns" v-show="title == selectedTitle">
    <slot> </slot>
  </div>
</template>
<script>
import { inject } from "vue";

export default {
  props: ["title"],
  setup() {
    const selectedTitle = inject("selectedTitle");

    return {
      selectedTitle,
    };
  },
};
</script>
<style lang="scss" scoped>
.tab-content {
  padding: $margin-small;
  max-width: 600px;
  gap: $margin-small;

  @media (min-width: 960px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $margin-small;
  }
}
</style>
