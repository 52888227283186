<template>
    <div class="modal-detail__card">

        <header>
            <span>Indicador {{ props.data.Indicador }}</span>
            <i class="material-icons" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">help_outline</i>
        </header>

        <main>
            <span>{{ props.data.Promedio != null ? `${props.data.Promedio}%` : 'N/A' }}</span>
        </main>

        <footer>
            <template v-if="props.data.Variacion == null">
                No hay comparativo
            </template>
            <div v-else-if="props.data.Variacion == 0">
                Sin Variaciones respecto a la última evaluación
            </div>
            <div v-else>
                <span :class="props.data.Variacion > 0 ? 'VERDE' : 'ROJO'">
                    <i class="material-icons" :style="props.data.Variacion < 0 ? 'align-self:flex-end' : ''">{{ props.data.Variacion > 0 ? 'arrow_upward' : 'arrow_downward' }}</i> 
                    {{ props.data.Variacion > 0 ? props.data.Variacion : props.data.Variacion.toString().slice(1) }} puntos
                </span>
                respecto a la última evaluación
            </div>
        </footer>

        <div class="tooltip" v-if="showTooltip">
            <span>INDICADOR {{ props.data.Indicador }}</span>
            <p>{{ props.data.Descripcion }}</p>
        </div>

    </div>

</template>

<script setup>
import { defineProps, ref } from 'vue';

const props = defineProps({
    data: Object
})

const showTooltip = ref(false);

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.modal-detail__card{
    position: relative;
    header{
        i{
            color: #4833E9;
            cursor: pointer;
        }
    }
    .tooltip{
        width: 220px;
        padding: 12px;
        position: absolute;
        top: 58px;
        right: 4px;
        background: #E4E0FC;
        border-radius: 10px;
        box-shadow: $boxesShadow;
        span{
            display: block;
            padding-bottom: 6px;
            font-size: 12px;
            font-weight: 700;
        }
        p{
            font-size: 14px;
            font-weight: 400;
        }
    }
}
</style>