<template>
  <div class="page-content full center">
    <div class="tab-container full">

      <TabContainer>
        <TabChild title="Pendientes">
          <template v-for="req in documentationRequest" :key="req.id">
            <Card v-if="req.EstadoCodigo.toUpperCase() =='PENDIENTE'" :docRequest="req"></Card>
          </template>
        </TabChild>
        <TabChild title="En proceso">
          <template v-for="req in documentationRequest" :key="req.id">
            <Card v-if="req.EstadoCodigo.toUpperCase() =='ENPROCESO'" :docRequest="req"></Card>
          </template>
        </TabChild>
        <TabChild title="Aprobados">
          <template v-for="req in documentationRequest" :key="req.id">
            <Card v-if="req.EstadoCodigo.toUpperCase() =='APROBADO'" :docRequest="req"></Card>
          </template>
        </TabChild>
      </TabContainer>
    </div>
  </div>
</template>

<script>
  import TabContainer from '@/components/Common/TabContainer.vue'
  import TabChild from '@/components/Common/TabChild.vue'
  import Card from '@/components/Common/CardFiles.vue'

  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  export default {
      components: {
          TabContainer,
          TabChild,
          Card
      },
      setup() {
        const { t } = useI18n();
        const store = useStore();
        
        const loading = ref(false);
        const documentationRequest = ref([])

        onMounted(async () => {
            const request = await store.dispatch('documentation_requests');
            if (request.status === 200) {
              documentationRequest.value = request.data
            }
            else {
              store.dispatch('notifications_create', { text: t(`notifications.${request.data}`) });
              loading.value = false;
            }
        });
        return {
          documentationRequest
        }
      }
  }
</script>

<style lang="scss" scoped>
  .page-content {
        padding-top: $margin-small;
    }
</style>
