import interceptor from "@/plugins/interceptor";
const pathController = "/Facilitador";

export default {
  state() {},
  mutations: {},
  actions: {
    async get_cursos() {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/cursosGet`
      );
    },
    async get_proxima_clase(_, id) {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/proximaClaseGet/${id}`
      );
    },
    async get_formulario_curso(_, id) {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/formulariosDeCursoGet/${id}`
      );
    },
    async post_instancia_formulario(_, req) {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_URL}${pathController}/instanciasDeFormualariosGet`,
        req
      );
    },
    async get_asistencia(_, id) {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/asistenciaGet/${id}/load`
      );
    },
    async refresh_asistencia(_, id) {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/asistenciaGet/${id}/refresh`
      );
    },
    async set_asistencia(_, req) {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_URL}${pathController}/asistenciaSet`, req
      );
    },
    async edit_fechaFormulario(_, req) {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_URL}${pathController}/actualizarFechaFormularioAlumno`,
        req
      );
    },
  },
};
