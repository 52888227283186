<template>
    <form id="customLogin" ref="customLoginForm" action="https://campus.fundacionforge.org/login/index.php" method="POST">
        <input id="anchor" type="hidden" name="anchor" value="">
        <input type="hidden" name="logintoken" value="Rpe6b9fxzADVjoUPmINVHWggEbMJYWox">
        <input id="cl-username" type="hidden" name="username" :value="username" />
        <input id="cl-password" type="hidden" name="password" :value="password" />
    </form> 
</template>

<script>
import { ref } from 'vue';
export default {
  props: {
    username: String,
    password: String,
  },
  setup(){
      const customLoginForm = ref()
      function submitForm() {
          customLoginForm.value.submit();
      }
      return {
          submitForm,
          customLoginForm
      };
  } 
};
</script>

<style lang="scss">
    #customLogin {
        display:none;
    }
</style>