<template>
  <div class="page-content full center">
    <div class="full">
      <span class="page-tittle full">Detalles de lo solicitado</span>
    </div>
    <div class="card full">
      <div class="card__head full rows">
        <div class="top rows full center">
          <div class="rows full">
            <router-link to="/Files">
              <img src="@/assets/icons/arrow-left-black.svg" alt="" />
            </router-link>
            <h3>{{ mainModel.Nombre }}</h3>
          </div>
        </div>
      </div>
      <div class="card__content full">
        <div class="content full columns">
          <span class="content__tittle full">Indicaciones</span>
          <span class="content__text full">{{ mainModel.Comentario }}</span>
          <span class="content__tittle full">Tienes tiempo hasta:</span>
          <span class="content__text full red-font">{{
            mainModel.FechaLimite
          }}</span>
          <span class="content__tittle full">Estado:</span>
          <span class="content__text full">{{ mainModel.EstadoCodigo }}</span>
        </div>
      </div>
    </div>
    <div v-if="mainModel.EstadoCodigo !== 'Aprobado'" class="full center">
      <span class="page-tittle full">Archivos</span>
      <div class="card full ">
        <div class="card__head full rows">
          <div class="top rows full center">
            <div class="rows full">
              <img src="@/assets/icons/upload.svg" alt="" />
              <h3>Subir Archivos</h3>
            </div>
          </div>
        </div>
        <div class="card__content full">
          <div class="content full columns">
            <input type="file" id="myfile" name="myfile" multiple="multiple" />
            <input
              type="text"
              placeholder="Ingrese algun comentario"
              class="input full"
            />
          </div>
        </div>
        <div class="card__bottom full rows">
          <div class="btn btn-small pointer center">Subir Archivos</div>
        </div>
      </div>
    </div>

    <span v-if="mainModel.Interacciones.length !== 0" class="page-tittle full">Interacciones</span>
    <div
      v-for="interaccion in mainModel.Interacciones"
      :key="interaccion.id"
      class="card full"
    >
      <div class="card__head full rows">
        <div class="top rows full center">
          <div class="rows full">
            <h3>{{ "Fecha de Entrega: " + interaccion.FechaSubida }}</h3>
          </div>
        </div>
      </div>
      <div class="card__content full">
        <div class="content full columns">
          <span class="content__tittle full">Estado:</span>
          <span class="content__text full">{{
            interaccion.EstadoCodigo
          }}</span>
          <span class="content__tittle full">Comentario del Alumno:</span>
          <span class="content__text full">{{
            interaccion.ComentarioAlumno
          }}</span>
          <span class="content__tittle full">Comentario del Profesor:</span>
          <span class="content__text full">{{
            interaccion.ComentarioFeddback
          }}</span>
          <span class="content__tittle full">Archivos Enviados:</span>
          <div v-for="doc in interaccion.Documentos" :key="doc.id" class="full">
            <a class="content__text" :href="doc.FileName">
              {{ doc.OriginalFileName }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: {},
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const loading = ref(false);
    const mainModel = ref({
      Interacciones: [],
    });
    const idSolicitud = ref(route.params.id);

    onMounted(async () => {
      const request = await store.dispatch(
        "interactions_requests",
        idSolicitud.value
      );
      console.log(request);
      if (request.status === 200) {
        mainModel.value = request.data;
      } else {
        store.dispatch("notifications_create", {
          text: t(`notifications.${request.data}`),
        });
        loading.value = false;
      }
    });

    async function uploadFile() {
      var file = document.querySelector("#uploadFileCtrl");
      const request = await store.dispatch("interactions_uploadFile", file);

      console.log(request);
    }

    return {
      mainModel,
      idSolicitud,
      uploadFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.conteiner {
  padding: $margin-small;
  justify-content: flex-start;
  gap: $margin-small;
}

.card {
  margin: $margin-milli;
}
.interactions {
  max-width: 600px;
  gap: $margin-small;
  &__head {
    .tittle {
      text-align: left;
      @include font-settings(base, base);
    }
  }
  .card__content {
    border-top: none;
    padding: 0;
  }
}
.files {
  .file {
    border: 1px solid $gray;
    width: 40px;
    height: 40px;
    margin-right: $margin-small;
  }

  .add {
    padding: $margin-milli;
    img {
      width: 100%;
    }
  }
}
input {
  margin: $margin-milli;
}

.content {
  &__tittle {
    text-align: start;
    text-decoration: underline;
    @include font-settings(milli, xmega);
  }

  &__text {
    text-align: start;
    @include font-settings(xmilli, base);
  }
}
li {
  .content__text {
    text-decoration: underline;
  }
}
h3 {
  min-width: 200px;
  text-align: start;
}
.btn {
  min-width: 120px;
}
</style>