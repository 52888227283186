<template>

    <div class="module-box">
        <h4>{{ $t('progress.boxGeneral.sectionTitle') }}</h4>
        <div v-if="!reqError">

            <div class="evaluacion">
                <div class="reference" >
                    <template v-if="data">
                        <i class="material-icons">date_range</i>
                        <span>{{ $t('progress.boxGeneral.attendance') }}</span>
                        <div class="circle" :class="`--${data.Asistencia.Color}`"></div>                    
                    </template>
                    <template v-else>
                        <Skeletor height="23" width="23" />
                        <Skeletor height="16" width="134" />
                        <Skeletor height="9" width="9" />
                    </template>
                </div>

                
                <div class="data">
                    <template v-if="data">
                        <span class="data__number">
                            <strong :class="data.Asistencia.Licencias > 0 ? 'data__number__blue' : ''">
                                {{data.Asistencia.Presentes}}
                            </strong>/{{data.Asistencia.Totales}}
                        </span>
                        <span>{{data.Asistencia.Porcentaje + ' %'}}</span>
                        <div
                        v-if="data.Asistencia.Licencias > 0"
                        class="description">
                            {{ $t('progress.boxGeneral.licenses') }}: {{data.Asistencia.Licencias}}
                        </div>
                    </template>
                    <template v-else>
                        <Skeletor width="60" height="49" />
                        <Skeletor width="80" height="49" />
                    </template>
                </div>

                
            </div>

            <div class="evaluacion">
                <div class="reference">
                    <template v-if="data">
                        <i class="material-icons">hourglass_bottom</i>
                        <span>{{ $t('progress.boxGeneral.punctuality') }}</span>
                        <div class="circle" :class="`--${data.Puntualidad.Color}`"></div>
                    </template>
                    <template v-else>
                        <Skeletor width="23" height="23" />
                        <Skeletor width="107" height="16" />
                        <Skeletor width="9" height="9" />
                    </template>
                </div>
                <div class="data">
                    <template v-if="data">
                        <span>{{data.Puntualidad.Puntuales + '/' + data.Puntualidad.Totales}}</span>
                        <span>{{data.Puntualidad.Porcentaje + ' %'}}</span>                    
                    </template>
                    <template v-else>
                        <Skeletor width="60" height="49" />
                        <Skeletor width="80" height="49" />
                    </template>
                </div>
            </div>

            <div class="evaluacion">
                <div class="reference">
                    <template v-if="data">
                        <i class="material-icons">menu_book</i>
                        <span>{{ $t('progress.boxGeneral.learningDiaries') }}</span>
                        <div class="circle" :class="`--${data.DiariosDeAprendizaje.Color}`"></div>
                    </template>
                    <template v-else>
                        <Skeletor width="23" height="23" />
                        <Skeletor width="107" height="16" />
                        <Skeletor width="9" height="9" />
                    </template>
                </div>
                <div class="data">
                    <template v-if="data">
                        <span>{{data.DiariosDeAprendizaje.Finalizados + '/' + data.DiariosDeAprendizaje.Totales}}</span>
                        <span>{{data.DiariosDeAprendizaje.Porcentaje + ' %'}}</span>                    
                    </template>
                    <template v-else>
                        <Skeletor width="60" height="49" />
                        <Skeletor width="80" height="49" />
                    </template>
                </div>
            </div>

            <div class="evaluacion">
                <div class="reference">
                    <template v-if="data">
                        <i class="material-icons">devices</i>
                        <span>Campus</span>
                        <div class="circle" :class="`--${data.Campus.Color}`"></div>
                    </template>
                    <template v-else>
                        <Skeletor width="23" height="23" />
                        <Skeletor width="107" height="16" />
                        <Skeletor width="9" height="9" />
                    </template>
                </div>
                <div class="data">
                    <template v-if="data">
                        <span>{{data.Campus.Finalizados + '/' + data.Campus.Totales}}</span>
                        <span>{{data.Campus.Porcentaje + ' %'}}</span>                    
                    </template>
                    <template v-else>
                        <Skeletor width="60" height="49" />
                        <Skeletor width="80" height="49" />
                    </template>
                </div>
            </div>

            <div class="evaluacion">
                <div class="reference">
                    <template v-if="data">
                        <i class="material-icons">account_circle</i>
                        <span>Avatars</span>
                    </template>
                    <template v-else>
                        <Skeletor width="23" height="23" />
                        <Skeletor width="107" height="16" />
                        <Skeletor width="9" height="9" />
                    </template>
                </div>
                <div class="data">
                    <template v-if="data">                    
                        <span>{{data.Avatar.Finalizados}}</span>
                    </template>
                    <template v-else>
                        <Skeletor width="60" height="49" />
                        <Skeletor width="80" height="49" />
                    </template>
                </div>
            </div>
            
        </div>

        <ErrorBox v-else />

    </div>
    
</template>

<script setup>
import { onMounted, ref } from 'vue'; 
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';
import ErrorBox from './ReqError.vue';

const store = useStore();
const route = useRoute();
const data = ref(null);
const reqError = ref(false);

async function getData(){
    try {
        let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'GENERAL', type: 'GENERAL' })
        data.value = req.data;
        //data.value = null;
    } catch (error) {
        console.log(error);
        reqError.value = true;
    }
}

onMounted(
    getData()
)

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.module-box{
    .evaluacion{
        .reference{
            i{
                background: $gris; 
            }
        }
        .data{
           
            span{

                &.data__number {
                    strong {
                        font-size: 36px;
                        font-weight: 400;
                        &.data__number__blue{
                            color: $lightblue;
                        }
                    }
                }
            }

            .description{
                display: flex;
                align-items: center;
                font-size: 10px;
                font-weight: 400;
                color: $lightblue;
            }
        }
    }
}

</style>