<template>
    <div class="progress">

        <div class="graph">

            <div class="graph__title">{{ $t('dashboard.circleGraph.yourProgressIn') }}</div>

            <div class="graph__graph">
                <svg :width="graphSize" :height="graphSize">
                    <circle :cx="circle1.radio" :cy="circle1.radio"  :r="circle1.radio - strokeWidth" :stroke-width="strokeWidth" fill="white" :stroke="strokeBgColor" />
                    <circle :cx="circle1.radio" :cy="circle1.radio" :r="circle1.radio - strokeWidth"  :stroke="circle1.strokeColor" :stroke-width="circle1.perc ? strokeWidth : 0" fill="none" stroke-linecap="round" id="circle1"
                        :stroke-dasharray="circle1.circunf"  :stroke-dashoffset="circle1.circunf - circle1.circunf * (circle1.perc/100)"
                    />

                    <circle :cx="circle1.radio" :cy="circle1.radio"  :r="circle2.radio - strokeWidth" :stroke-width="strokeWidth" fill="white" :stroke="strokeBgColor" />
                    <circle :cx="circle1.radio" :cy="circle1.radio" :r="circle2.radio - strokeWidth"  :stroke="circle2.strokeColor" :stroke-width="circle2.perc ? strokeWidth : 0" fill="none" stroke-linecap="round" id="circle2"
                        :stroke-dasharray="circle2.circunf"  :stroke-dashoffset="circle2.circunf - circle2.circunf * (circle2.perc/100)"
                    />

                    <circle :cx="circle1.radio" :cy="circle1.radio"  :r="circle3.radio - strokeWidth" :stroke-width="strokeWidth" fill="white" :stroke="strokeBgColor" />
                    <circle :cx="circle1.radio" :cy="circle1.radio" :r="circle3.radio - strokeWidth"  :stroke="circle3.strokeColor" :stroke-width="circle3.perc ? strokeWidth : 0" fill="none" stroke-linecap="round" id="circle3"
                        :stroke-dasharray="circle3.circunf"  :stroke-dashoffset="circle3.circunf - circle3.circunf * (circle3.perc/100)"
                    />
                </svg>
                <i class="material-icons" :style="{color: strokeBgColor}">military_tech</i>
            </div>

        </div>

        <div class="data">
            <div class="data__title"><span @click="emit('tutorial')">{{ $t('dashboard.circleGraph.howItWorks') }}</span></div>
            <div class="data__item" :style="{height: (graphSize)/4+4 +'px'}"><i class="material-icons">add_to_queue</i><h3>{{ $t('dashboard.circleGraph.mandatoryWorkshops') }}</h3><span :style="{borderColor: circle1.strokeColor}"><strong>{{props.data.TalleresIniciales.Realizados}}</strong>/{{props.data.TalleresIniciales.Totales}}</span></div>
            <div class="data__item" :style="{height: (graphSize)/4+4 +'px' }" :class="{'working' : props.data.EstaTrabajando}">
                <i class="material-icons">add_to_queue</i><h3 class="w-info">{{ $t('dashboard.circleGraph.chosenWorkshops') }}</h3><span :style="{borderColor: circle2.strokeColor}"><strong>{{props.data.TalleresElegidos.Realizados}}</strong>/{{props.data.TalleresElegidos.Totales}}</span>
                <label class="info__label" @mouseover="infoTooltip = 'elegidos'" @mouseleave="infoTooltip = ''">{{ $t('dashboard.circleGraph.whatIsThis') }}</label>
                <div class="info__box" v-show="infoTooltip == 'elegidos'">
                    <h6>{{ $t('dashboard.circleGraph.chosenWorkshops') }}</h6>
                    <p>{{ $t('dashboard.circleGraph.chosenWorkshopsExplanation') }}.</p>
                </div>
            </div>
            <div class="data__item" :style="{height: (graphSize)/4+4 +'px'}"><i class="material-icons">group</i><h3>{{ $t('dashboard.circleGraph.followUpInterviews') }}</h3><span :style="{borderColor: circle3.strokeColor}"><strong>{{props.data.Entrevistas.Realizados}}</strong>/{{props.data.Entrevistas.Totales}}</span></div>
            <div class="data__item" :style="{height: (graphSize)/4+4 +'px'}">
                <i class="material-icons">star</i><h3 class="w-info">{{ $t('dashboard.circleGraph.bonus') }}</h3><span :style="{border: 'none'}"><strong>+{{props.data.Bonus}}</strong></span>
                <label class="info__label" @mouseover="infoTooltip = 'bonus'" @mouseleave="infoTooltip = ''">{{ $t('dashboard.circleGraph.whatIsThis') }}</label>
                <div class="info__box" v-show="infoTooltip == 'bonus'">
                    <h6>{{ $t('dashboard.circleGraph.bonus') }}</h6>
                    <p>{{ $t('dashboard.circleGraph.bonusExplanation') }}</p>
                </div>
            </div>
        </div>
        

    </div>

</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line no-unused-vars
const { t } = useI18n();

const emit = defineEmits(['tutorial']);
const props = defineProps({
    data: Object,
})
const infoTooltip = ref('');
let graphSize = 280;
let strokeWidth = 18;
if(document.body.offsetWidth < 600) {
    graphSize = document.body.offsetWidth*0.33;
    strokeWidth = 8;
    strokeWidth = 9;
}

const strokeBgColor = 'rgba(217, 217, 217, 0.29)';

const circle1 = ref({
    radio: graphSize/2,
    strokeColor: '#EABEFF',
    perc: props.data.TalleresIniciales.Porcentaje,
    circunf: 0
})
 const circle2 = ref({
    radio: (circle1.value.radio - strokeWidth*2),
    strokeColor: '#8AD7FC',
    perc: props.data.TalleresElegidos.Porcentaje,
    circunf: 0
})
const circle3 = ref({
    radio: (circle2.value.radio - strokeWidth*2),
    strokeColor: '#BDF4A2',
    perc: props.data.Entrevistas.Porcentaje,
    circunf: 0
}) 

onMounted(()=>{  
    circle1.value.circunf = document.getElementById('circle1').getTotalLength();
    circle2.value.circunf = document.getElementById('circle2').getTotalLength();
    circle3.value.circunf = document.getElementById('circle3').getTotalLength();
})

</script>

<style lang="scss" scoped>

.progress{
    flex-grow: 1;
    max-width: 760px;
    padding: 20px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 36px;
    border-radius: 20px;
    box-shadow: 5px 5px 35px 0px rgba(224, 224, 224, 0.50);
    background: #fff;
    @media(width < 600px){
        width: 100%;
        padding: 12px;
        justify-content: space-between;
        gap: 4px;
        //background: none;
        //box-shadow: none;
    }    
}

.graph{
    &__title{
        padding-bottom: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
         @media(width < 600px){
            font-size: 12px;
            max-width: 140px;
            padding-bottom: 12px;
        } 
    }
    &__graph{
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
            position: absolute;
            font-size: 42px;
            @media(width < 600px){
                font-size: 32px;
            } 
        }
        svg{
            transform: rotate(-90deg);
        }
    }
}

.data{
    &__title{
        padding-bottom: 10px;
        text-align: right;
        span{
            color: #4833E9;
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
            @media(width < 600px){
                font-size: 12px;
            } 
        }
    }
    &__item{
        position: relative;
        display: flex;
        gap: 22px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #D9D9D9;
        &.working{
            i,h3,span{
                opacity: .4;
            }
        }
        @media(width < 600px){
            gap: 8px;
            height: 34px!important;
        }     
        i { 
            color: #aaa;
            @media(width < 600px){
                font-size: 16px;
            }            
        }
        h3{
            flex-grow: 1;
            font-size: 16px;
            @media(width < 600px){
                font-size: 12px;
                font-weight: normal;
                &.w-info{
                    margin-bottom: 14px;
                }
            }   
        }
        span{
            max-width: 77px;
            min-width: 77px;
            padding: 6px 0;
            text-align: center;
            font-size: 16px;
            border: 2px solid;
            border-radius: 20px;
            justify-self: flex-end;
            @media(width < 600px){
                max-width: 45px;
                min-width: 45px;
                width: 45px;
                padding: 2px 0;
                font-size: 12px;
            }  
        }
        .info{
            &__label{
                position: absolute;
                left: 44px;
                bottom: 6px;
                color: #4833E9;
                font-size: 12px;
                cursor: pointer;
            }
            &__box{
                width: 260px;
                position: absolute;
                left: 44px;
                top: 76px;
                padding: 10px;
                background: #fff;
                box-shadow: 5px 5px 35px 0px rgba(217, 217, 217, .68);
                border-radius: 10px;
                z-index: 1;
                h6{
                    margin: 0;
                    padding-bottom: 6px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #4833E9;
                }
                p{
                    font-size: 13px;
                    font-weight: 400;
                    color: #212121;
                }
            }
            @media(width < 600px){
                &__label{
                    left: 24px;
                    bottom: 2px;
                    font-size: 10px;
                }
                &__box{
                    left: -16px;
                    top: 36px;
                    padding: 8px;
                }
            }
        }
    }
}


</style>