<template>
  <div class="card__links">
    <h3>{{$t('login.followUs')}}</h3>
    <div class="card__links__container">
        <ul >
            <li>
                <a href="https://www.facebook.com/ForgeFundacion">
                    <img src="@/assets/images/facebook.png" alt="">
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/fondation-forge">
                    <img src="@/assets/images/linkedin.png" alt="">
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/fundacionforge/">
                    <img src="@/assets/images/instagram.png" alt="">
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/user/ForgeFondation">
                    <img src="@/assets/images/youtube.png" alt="">
                </a>
            </li>   
        </ul>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss">
*,
*:before,
*:after {
  box-sizing: border-box;
}

.card__links {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    width: 100%;
    font-size: 16px;
    padding: 12px;
  }

  &__container {
    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      li {
        display: inline-block;
        justify-content: center;
        margin: 4px;
        a img {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
}
</style>