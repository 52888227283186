<template>
  <div class="home">
    <div class="breakpoints-container">
      <div class="home__content">
        <div class="home__content__image">
          <img class="home__ilustration" v-if="user.Role === 1" src="../../assets/images/image-home-joven.svg" alt="" />
          <img class="home__ilustration" v-else src="../../assets/images/image-home.svg" alt="" />
        </div>
        <div class="home__card bx-shadow">
          <h3 class="home__card_title">
            <strong>
              {{ $t('home.hello') }}, {{ user.Nombre }}.</strong>
              {{ $t('home.whatToDo') }}
          </h3>
          <div clasS="home__card_buttons">
            <a v-if="user.MoodleAdmin" :class="`btn`" @click="accesMoodleAdmin" >
              {{ $t('home.goToCampus') }}
            </a>
            <a v-else :class="`btn ${!moodleLoginUrl && 'disabled' || ''}`" target="_blank" :href="moodleLoginUrl" >
              {{ $t('home.goToCampus') }}
            </a>
            
            <a v-if="user.Role === 1" :class="`btn ${(!rukyUrl) && 'disabled' || ''}`" target="_blank" :href="rukyUrl">
              {{ $t('home.goToRuky') }}
            </a>
            <a v-else :disabled="loadingCurses || !courses?.Data" :class="`btn ${(loadingCurses || !courses?.Data || !courses?.Data.length > 0) && 'disabled' || ''}`" @click="!loadingCurses ? modalCourse = true : ''">
              {{ $t('home.myCourses') }}
            </a>
          </div>
        </div>
      </div>
      <Modal v-if="modalCourse"  >
        <div class="columns" style="padding: 18px; padding-top: 0;">
          <div class="text-modal" style="text-align: left">
            <span class="full" style="font-weight: bold">{{ $t('home.course') }}</span>
          </div>
          <div class="text-modal">
            <span>{{ $t('home.courseDisclaimer') }}</span>
          </div>
          <Select
            id="course"
            :label="$t('home.selectCourse')"
            :values="courses.Data"
            valueLabel="Codigo"
            valueProp="Id"
            v-model="selectedCourse"
          />
          <div class="buttons center rows full">
            <button class="btn-simple lightblue" @click="closeModal" style="width: fit-content">{{$t('home.cancelBtn')}}</button>
            <button :disabled="loadingNextClass" class="btn btn-small white bg-lightblue" @click="selectCourse">{{$t('global.continue')}} <i class="material-icons">arrow_forward</i></button>
          </div>
        </div>
      </Modal>
      <div class="home__content">
        <Loader v-if="user.Role != 1 && loadingNextClass" />
        <div class="table-course bx-shadow" v-else-if="user.Role != 1 && courseClass"> 
          <div class="table-course__header">
            {{courseClass.titulo}} - {{ courseClass.formattedDate }}
          </div>
          <div class="table-course__content">
            <template v-if="statusCourse != 'comming'"> 
              <div>
                {{ $t('home.assistenceFollowing') }}
                <button :disabled="!courseClass.isToday" @click="courseClass.isToday && router.push(`/attendanceMonitoring/${ courseClass.id }`)" :class="`btn ${!courseClass.isToday && 'disabled' || ''}`">
                  {{ $t('home.assistenceEnter') }}
                </button>
              </div>
              <div>
                {{ $t('home.classContent') }}
                <a target="_blank" :href="courseClass.materialesURL || null" :class="`btn ${!courseClass.materialesURL && 'disabled' || ''}`">{{ $t('home.assistenceEnter') }}</a>
              </div>
              <div>
                {{ $t('home.progress') }}
                <a :href="`/seguimiento/${selectedCourse}`" class="btn">{{ $t('home.assistenceEnter') }}</a>
              </div>
            </template>
            <div v-else>
              <span>
                {{ $t('home.nextClassWhen') }} <strong>{{ nextDate }} 16:00</strong>
              </span>
            </div>
          </div>
        </div>
      </div>

      <template v-if="user.Role == 1 && statusCourse">
        <PresentInfo :status="statusCourse" :givePresent="givePresent" :isPresent="present" :isLoading="isPresentLoading" />
      </template>

      <!-- TABLA FORMULARIOS ACTUALES  -->
      <template v-if="((user.Role == 1 && present) || (user.Role != 1 && selectedCourse && statusCourse != 'comming' && courseClass)) ">

          <div class="header">
            <strong>{{ $t('home.table.thisClassTasks') }}</strong>
            <div v-if="widthSize > 520" class="header__filters">
              <div  class="dropdown">
                <input type="checkbox" id="filter-state" ref="filterRef">
                <label class="dropdown__face" for="filter-state">
                    {{filterTypes.find(fil => fil.value === filter)?.label}} <i class="material-icons">keyboard_arrow_down</i>
                </label>
                <ul class="dropdown__items">    
                    <li @click.stop="filter = fil.value; filterRef.checked = false" v-for="(fil, idx) in filterTypes" :key="idx" >{{ fil.label }}</li>
                </ul>
              </div>
            </div>
            <i v-else @click.stop="filterModal = true" class="material-icons-outlined header_filterButton">filter_list</i>
          </div>
    
          <Modal v-if="filterModal && widthSize <= 520" >
            <span class="full text black" style="font-weight: 700; text-align: start; display: flex; align-items: center; gap: 6px;">
              <i style="font-size: 18px;" class="material-icons-outlined">tune</i>{{ $t('home.table.filters') }}
            </span>
            <div style="display: flex; flex-direction: column; gap: 6px; width: 70vw;">
              <Select
                :label="filterTypes.find(fil => fil.value === filter)?.label"
                :id="$t('home.table.status')"
                v-model="preselectFilter"
                :values="filterTypes"
                valueProp="value"
                valueLabel="label"
              />
            </div>
            <div style="display: flex; flex-direction: column; gap: 12px; width: 100%; margin-top: 12px;">
              <button @click="filter = preselectFilter; filterModal = false" class="btn bg-blue white">{{ $t('home.table.apply') }}</button>
              <button @click.stop="preselectFilter = undefined ;filterModal = false" class="btn-simple">{{ $t('home.table.cancel') }}</button>
            </div>
          </Modal>
        
        <Loader v-if="formsLoading"/>
        <div class="table bx-shadow" v-else-if="(user.Role === 1 && statusCourse !== undefined && present) || (user.Role !== 1 && selectedCourse && courseClass)">
          <span class="table__title">{{ $t('home.table.headers.activity') }}</span>
          <span class="table__title">{{ $t('home.table.headers.date') }}</span>
          <span class="table__title">{{ $t('home.table.headers.status') }}</span>
          <span class="table__title"></span>
          <h3 v-if="!thisClassFormPagination()?.length" class="table__noResults">{{ $t('home.noActivities') }}</h3>
          <template v-for="(form, idx) in thisClassFormPagination()" :key="idx">
            <span class="table__data" v-if="true" :key="idx + 'form'">{{ form.titulo || form.nombre }}</span>
            <span class="table__data" v-if="true" :key="idx + 'date'">{{ formatDate(form.fechaDesde) }}</span>
            <div  class="table__data" v-if="true" :key="idx + 'status'">
              <span :class="`status--${form.status}`">
                {{ $t(`status.${form.status}`) }}
                <i class="material-icons">
                  {{
                    form.status
                      ? 'check_circle' 
                        : 'highlight_off'
                  }}
                </i>
              </span>
            </div>

            <span class="table__review table__data" v-if="user.Role === 1 && form.editable" @click="goToForm(form)" :key="idx + 'check'">{{ $t('home.table.enter') }}</span>
            <span class="table__review table__data" v-else-if="user.Role === 1 && !form.editable" @click="goToForm(form)" :key="idx + 'checkV'">{{ $t('home.table.see') }}</span>
            <span class="table__review table__data" v-else @click="goToMonitoring(form)" :key="idx + 'checkT'">{{ $t('home.table.review') }}</span>
          </template>
          <div class="table__pagination">
            <button @click="thisClassForms.page - 1 >= 1 && thisClassForms.page--" :disabled="thisClassForms.page === 1" ><i class="material-icons-outlined">arrow_forward</i>{{ $t('home.table.previous') }}</button>
            <span style="border-bottom: 0;"><strong>Página {{thisClassForms.page}}</strong> / {{ thisClassForms.totalPages }}</span>
            <button :disabled="thisClassForms.page === thisClassForms.totalPages" @click="thisClassForms.page + 1 <= thisClassForms.totalPages && thisClassForms.page++ ">{{ $t('home.table.next') }} <i class="material-icons-outlined">arrow_forward</i></button>
          </div>
        </div>
      </template>

        <!-- TABLA HISTORIAL DE FORMULARIOS  -->
        <template v-if="((user.Role !== 1 && selectedCourse && courseClass) || user.Role === 1) && !formsLoading">

            <div class="header">
              <strong>{{ $t('home.table.taskStoryTitle') }}</strong>
              <div v-if="widthSize > 520" class="header__filters">
                <div  class="dropdown">
                  <input type="checkbox" id="historical-filter-state" ref="filterRef">
                  <label class="dropdown__face" for="historical-filter-state">
                      {{ filterTypes?.find(fil => fil.value === historicalFilter)?.label }} <i class="material-icons">keyboard_arrow_down</i>
                  </label>
                  <ul class="dropdown__items">    
                      <li @click.stop="historicalFilter = fil.value; filterRef.checked = false" v-for="(fil, idx) in filterTypes" :key="idx" >{{ fil.label }}</li>
                  </ul>
                </div>
              </div>
              <i v-else @click.stop="filterModal = true" class="material-icons-outlined header_filterButton">filter_list</i>
            </div>

            <Modal v-if="filterModal && widthSize <= 520" >
              <span class="full text black" style="font-weight: 700; text-align: start; display: flex; align-items: center; gap: 6px;">
                <i style="font-size: 18px;" class="material-icons-outlined">tune</i>{{ $t('home.table.filters') }}
              </span>
              <div style="display: flex; flex-direction: column; gap: 6px; width: 70vw;">
                <Select
                  :label="filterTypes.find(fil => fil.value === historicalFilter)?.label"
                  :id="$t('home.table.status')"
                  v-model="preselectFilter"
                  :values="filterTypes"
                  valueProp="value"
                  valueLabel="label"
                />
              </div>
              <div style="display: flex; flex-direction: column; gap: 12px; width: 100%; margin-top: 12px;">
                <button @click="historicalFilter = preselectFilter; filterModal = false" class="btn bg-blue white">{{ $t('home.table.apply')}}</button>
                <button @click.stop="preselectFilter = undefined; filterModal = false" class="btn-simple">{{ $t('home.table.cancel')}}</button>
              </div>
            </Modal>

          <div class="table bx-shadow">
            <span class="table__title">{{ $t('home.table.headers.activity') }}</span>
            <span class="table__title">{{ $t('home.table.headers.date') }}</span>
            <span class="table__title">{{ $t('home.table.headers.status') }}</span>
            <span class="table__title"></span>
            <h3 v-if="!historicalFormsPagination()?.length" class="table__noResults">{{ $t('home.noForms')}}</h3>
            <template v-for="(form, idx) in historicalFormsPagination()" :key="idx">
              <span class="table__data" v-if="true" :key="idx + 'form'">{{ form.titulo || form.nombre }}</span>
              <span class="table__data" v-if="true" :key="idx + 'date'">{{ formatDate(form.fechaDesde) }}</span>
              <div  class="table__data" v-if="true" :key="idx + 'status'">
                <span :class="`status--${form.status}`">
                  {{ $t(`status.${form.status}`) }}
                  <i class="material-icons">
                    {{
                      form.status
                      ? 'check_circle'
                      : 'highlight_off'
                    }}
                  </i>
                </span>
              </div>
              <span class="table__review table__data" v-if="user.Role === 1 && form.editable" @click="goToForm(form)" :key="idx + 'check'">{{ $t('home.table.enter') }}</span>
              <span class="table__review table__data" v-else-if="user.Role === 1 && !form.editable && form.status == 'FINALIZADO'" @click="goToForm(form)" :key="idx + 'checkV'">{{ $t('home.table.see') }}</span>
              <span class="table__review table__data" v-else-if="user.Role != 1" @click="goToMonitoring(form)" :key="idx + 'checkT'">{{ $t('home.table.review') }}</span>
              <span class="table__review table__data" v-else :key="idx + 'checkEmpty'" style='cursor:default; color:#333;'>{{ $t('home.table.bloqued') }}</span>
            </template>
            <div class="table__pagination">
              <button @click="historicalForms.page - 1 >= 1 && historicalForms.page--" :disabled="historicalForms.page === 1" ><i class="material-icons-outlined">arrow_forward</i>{{ $t('home.table.previous') }}</button>
              <span style="border-bottom: 0;"><strong>Página {{ historicalForms.page }}</strong> / {{ historicalForms.totalPages }}</span>
              <button :disabled="historicalForms.page === historicalForms.totalPages" @click="historicalForms.page + 1 <= historicalForms.totalPages && historicalForms.page++">{{ $t('home.table.next') }} <i class="material-icons-outlined">arrow_forward</i></button>
            </div>
          </div>
        </template>
      </div>

      

    <Modal v-if="moodleModal">
      <div class="center columns">
        <div class="text-modal center">
          <span class="full text">{{$t('home.shureAccesCampus')}}</span>
        </div>
        <div class="buttons center rows full">
          <button class="btn btn-small red" @click="closeModal">{{$t('home.cancelBtn')}}</button>
          <button class="btn btn-small" @click="openMoodle">{{$t('home.acceptBtn')}}</button>
        </div>
      </div>
    </Modal>
    <Modal v-if="creadlyModal">
      <div class="center columns">
        <div class="text-modal center">
          <span class="full text">{{$t('home.shureAccessCreadly')}}</span>
        </div>
        <div class="buttons center rows full">
          <button class="btn btn-small red" @click="closeModal">{{$t('home.cancelBtn')}}</button>
          <button class="btn btn-small" @click="openCreadly">{{$t('home.acceptBtn')}}</button>
        </div>
      </div>
    </Modal>
    </div>


    

  <ExternalLogin
    ref="externalLoginCtrl"
    :username="userCredentials.username"
    :password="userCredentials.password"
  />
</template>
<script>
import Modal from "@/components/Common/Modal.vue";
import PresentInfo from '@/components/Common/PresentInfo.vue';
import ExternalLogin from "@/components/Common/ExternalLogin.vue";
import Loader from '@/components/Loader.vue';
import {  inject,ref } from "vue";
import { onMounted } from '@vue/runtime-core';
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
// import { computed } from "@vue/runtime-core";
import base64url from 'base64url'
import Select from "../../components/Select.vue";
import moment from 'moment';
import persistentData from '../../plugins/persistentData';

export default {
  components: {
    Modal,
    PresentInfo,
    Select,
    ExternalLogin,
    Loader
},
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    // const route = useRoute();
    const store = useStore();
    const widthSize = inject('widthSize');
    const user = ref(persistentData.get('user', true).userData);
    const userCredentials = ref(JSON.parse(base64url.decode(persistentData.get('tokenValidation'))));
    const creadlyModal = ref(false);
    const moodleModal = ref(false);
    const menuMode = ref(false);
    const openByPopUp = ref(false);
    const modalCourse = ref(false);
    const moodleLoginUrl = ref(null);
    const loading = ref(true);
    const pendingForms = ref([]);  
    const rukyUrl = ref(null)
    const formatDate = inject('formatDate')
    const courseClass = ref()
    const filterRef = ref()
    const filterModal = ref(false);
    const preselectFilter = ref()
    const filterTypes = ref()
    const filter = ref(undefined)
    const historicalFilter = ref(undefined)
    const selectedCourse = ref(store.state.ManageUser.course);
    const statusCourse = ref(undefined);
    const nextDate = ref(moment().format('DD/MM/YYYY'))
    const loadingCurses = ref(false)
    const loadingNextClass = ref(false)
    const jovenNextClass = ref()
    const courses = ref()
    const present = ref()
    const pageLoaded = ref(false)
    const isPresentLoading = ref(false)
    const momentjs = () => moment()
    const formsLoading = ref(true)
    const thisClassForms = ref({})
    const historicalForms = ref({})
    const externalLoginCtrl = ref();

    const getCursos = async () => {
      try {
        loadingCurses.value = true
        const { data } = await store.dispatch('get_cursos')
        courses.value = data
        if(data != null && data.Data != null && data.Data.length == 0)
        {
          //window.location.href = moodleLoginUrl.value;
        }
      } catch (err) {
        console.log(err)
      } finally {
        loadingCurses.value = false
      }
    }

    const getNextClass = async () => {
      try {
        const { data } = await store.dispatch('get_proxima_clase_joven')
        jovenNextClass.value = data.Data
        if(data.Data != null)
        {
          present.value = (data.Data.asistencia === 1 || data.Data.asistencia === 0 || data.Data.asistencia === 4) ? true : false
          let fechaClase = moment.utc(jovenNextClass.value.fechaUTC)
          let fechaHasta = moment.utc(jovenNextClass.value.fechaHastaUTC);
          let fechaActualUTC = moment().utc();

          if (fechaClase.format('l') !== fechaActualUTC.format('l')) statusCourse.value = undefined
          else if(fechaActualUTC < fechaClase) statusCourse.value = 'comming'
          else if (fechaActualUTC.diff(fechaClase,'minutes') < 10 ) statusCourse.value = 'started'
          else if (fechaActualUTC.diff(fechaHasta,'seconds') > 0) statusCourse.value = 'finished'
          else statusCourse.value = 'already_started'
          
          //statusCourse.value = 'already_started'
                  
        }
        getJovenForms()
      } catch (err) {
        console.log(err)
      }
    }

    const getJovenForms = async () => {
      try {
        formsLoading.value = true
        const { data } = await store.dispatch('joven_instanciasFormulario')
        if(jovenNextClass.value != null)
        {
          // Formularios de esta clase :
          thisClassForms.value.formularios = data.Data.formularios.filter(form => form.claseId === jovenNextClass.value.id)  
          thisClassForms.value.totalPages = Math.ceil(thisClassForms.value.formularios.length / 10) || 1
          thisClassForms.value.page = 1  
        }
        
        // Formularios historicos

        historicalForms.value.formularios = data.Data.formularios.filter(form => jovenNextClass.value == null || form.claseId !== jovenNextClass.value.id)
        historicalForms.value.totalPages = Math.ceil(historicalForms.value.formularios.length / 10) || 1
        historicalForms.value.page = 1

      } catch (err) {
        console.log(err)
      } finally {
        formsLoading.value = false
      }
    }

    const thisClassFormPagination = () => {
      let start = thisClassForms.value.page === 1 ? 0 : (thisClassForms.value.page - 1) * 10
      let end = thisClassForms.value.page * 10
      let formularios = filter.value ? thisClassForms.value.formularios?.filter(form => form.status === filter.value) : thisClassForms.value.formularios
      return formularios?.slice(start, end)
    }

    const historicalFormsPagination = () => {
      let start = historicalForms.value.page === 1 ? 0 : (historicalForms.value.page - 1) * 10
      let end = historicalForms.value.page * 10
      let formularios = historicalFilter.value ? historicalForms.value.formularios?.filter(form => form.status === historicalFilter.value) : historicalForms.value.formularios
      return formularios?.slice(start, end)
    }

    const givePresent = async () => {
      if(present.value === true) return
      try { 
        isPresentLoading.value = true
        await store.dispatch('dar_presente', jovenNextClass.value.id)
        getJovenForms()
        present.value = true
      } catch (err) {
        console.log(err)
      } finally {
        isPresentLoading.value = false
      }
    }

    const goForm = (uCode, FilialId) => {
      FilialId = 9
      window.open(`${process.env.VUE_APP_INSCRIPCION_URL}/Form/StartForm/?formUCode=${uCode}&callbackURL=JS:window.vueApp.redirectFromForm()&mod=campus&fCode=${FilialId}`, "_blank").focus()
    }
    
    onMounted(async () => {
      try {
        const requestMoodle = await store.dispatch("security_moodleAccess");
        moodleLoginUrl.value = requestMoodle.data.Data.UrlSSO;
      } catch (err) {
        store.dispatch('notifications_create', { text: t(`notifications.${err}`) });
      }
      try {
        const res = await store.dispatch("security_rukyAccess");
        let code = base64url(JSON.stringify(res.data.Data))
        if(user.value.NivelPosicion >= 3)
        {
          rukyUrl.value = `${process.env.VUE_APP_RUKY_URL}login/campus?identifier=${code}`
        }
      } catch (err) {
        store.dispatch('notifications_create', { text: t(`notifications.${err}`) });
      }
      if(selectedCourse.value) selectCourse()
      if (user.value.Role !== 1) {
        getCursos()
        filterTypes.value = [
          {
            value: 'EN_CURSO',
            label: t('global.filters.inProgress'),
          },
          {
            value: 'FINALIZADO',
            label: t('global.filters.finished'),
          },
          {
            value: 'SIN_INICIAR',
            label: t('global.filters.notStarted'),
          },
          {
            value: undefined,
            label: t('global.filters.all'),
          }
        ]
      }
      else {
        getNextClass()
        filterTypes.value = [
          {
            value: 'FINALIZADO',
            label: t('global.filters.complete'),
          },
          {
            value: 'INICIADO',
            label: t('global.filters.incomplete'),
          },
          {
            value: undefined,
            label: t('global.filters.all'),
          }
        ]
      }
    });

    function accesMoodleAdmin() {
      try {
        externalLoginCtrl.value.submitForm();
        router.push("/");
      } catch (e) {
        store.dispatch("notifications_create", {
          text: t(`notifications.${e.response.data}`),
        });
      }
    }

    function checkOS() {
      if (
        navigator.platform != "Win16" &&
        navigator.platform != "Win32" &&
        navigator.platform != "Android"
      ) {
        openByPopUp.value = true;
      }
    }

    function handleMenu() {
      menuMode.value = !menuMode.value;
    }
    function handleLogout() {
      router.push("/Login");
    }

    function handleMoodle() {
      checkOS();
      if (openByPopUp.value) {
        openModal(moodleModal);
      } else {
        openMoodle();
      }
    }

    function handleCreadly() {
      checkOS();
      if (openByPopUp.value) {
        openModal(creadlyModal);
      } else {
        openCreadly();
      }
    }
    function closeModal() {
      modalCourse.value = false;
      creadlyModal.value = false;
      moodleModal.value = false;
    }

    const processing = ref(false);

    function openModal(modal) {
      modal.value = true;
    }

    async function openMoodle() {
      try {
        processing.value = true;
        const request = await store.dispatch("security_moodleAccess");
        if (request.status === 200) {
          if (openByPopUp.value) {
            closeModal(moodleModal);
            window.open(request.data.Data.UrlSSO, "_self").focus();
          } else {
            closeModal(moodleModal);
            window.open(request.data.Data.UrlSSO, "_blank").focus();
          }
          processing.value = false;
        }
      } catch (e) {
        processing.value = false;
        store.dispatch("notifications_create", {
          text: t(`notifications.${e.response.data.Message}`),
        });
      }
    }

    async function openCreadly() {
      processing.value = true;
      try {
        const request = await store.dispatch("security_creadlyAccess");
        if (request.status === 200) {
          if (request.data.Data.SSO == "CANNOT_ACCESS_CREADLY") {
            store.dispatch("notifications_create", {
              text: t(`notifications.home.cannotAccessCreadly`),
            });
            processing.value = false;
          } else {
            window.open(request.data.Data.UrlSSO, "_blank").focus();
            processing.value = false;
          }
        } else {
          closeModal(creadlyModal);
          if (openByPopUp.value) {
            window.open(request.data.Data.UrlSSO, "_self").focus();
          } else {
            window.open(request.data.Data.UrlSSO, "_blank").focus();
          }
          processing.value = false;
        }
      } catch (e) {
        processing.value = false;
        store.dispatch("notifications_create", {
          text: t(`notifications.${e.response.data.Message}`),
        });
      }
    }

    function goToForm(form) {
      const hash = base64url(JSON.stringify({prevUrl: route.fullPath, 
                                              formUCode :form.formUCode, 
                                              editable: form.editable, 
                                              theme:form.Theme }))
      router.push(`/forms/${hash}`)
    }

    function goToMonitoring(form) {
      const { formularioCfgId, cursoId, claseId, claseNivelId } = form
      const body = {
        formularioCfgId,
        cursoId,
        claseId,
        claseNivelId
      }
      const hash = base64url(JSON.stringify(body))
      router.push(`/monitoringActivities/${hash}`)
    }

    const selectCourse = async () => {
      if(!selectedCourse.value) return
      try {
        loadingNextClass.value = true
        closeModal();
        const { data: nextClass } = await store.dispatch('get_proxima_clase', selectedCourse.value)
        const { data: formsData } = await store.dispatch('get_formulario_curso', selectedCourse.value)
        store.commit('setCourse', selectedCourse.value)
        // Formularios para esta clase : 
        thisClassForms.value.formularios = formsData.Data.filter(form => form.claseId === nextClass.Data.id)
        thisClassForms.value.totalPages = Math.ceil(thisClassForms.value.formularios.length / 10) || 1
        thisClassForms.value.page = 1

        // Formularios historicos

        historicalForms.value.formularios = formsData.Data.filter(form => form.claseId !== nextClass.Data.id)
        historicalForms.value.totalPages = Math.ceil(historicalForms.value.formularios.length / 10) || 1
        historicalForms.value.page = 1
        courseClass.value = nextClass.Data
        let date = new Date(nextClass.Data.fecha)
        let isToday = moment(date).format('l') === moment().format('l')
        courseClass.value.formattedDate = moment(date).format('DD/MM/YYYY hh:mm')
        courseClass.value.isToday = isToday
      } catch (err) {
        console.log(err)  
      } finally {
        loadingNextClass.value = false
        formsLoading.value = false
      }
    }
    return {
      creadlyModal,
      moodleModal,
      menuMode,
      handleCreadly,
      openModal,
      openByPopUp,
      givePresent,
      checkOS,
      historicalForms,
      handleMenu,
      handleLogout,
      thisClassFormPagination,
      openMoodle,
      processing,
      goToForm,
      openCreadly,
      handleMoodle,
      historicalFilter, 
      externalLoginCtrl,
      modalCourse,
      closeModal,
      loading,
      pendingForms,
      moodleLoginUrl,
      accesMoodleAdmin,
      userCredentials,
      user,
      rukyUrl,
      formatDate,
      widthSize,
      filterModal,
      goToMonitoring,
      goForm,
      selectCourse,
      selectedCourse,
      statusCourse,
      nextDate,
      filterTypes,
      loadingCurses,
      courses,
      loadingNextClass,
      courseClass,
      pageLoaded,
      jovenNextClass,
      present,
      isPresentLoading,
      router,
      momentjs,
      formsLoading,
      filter,
      filterRef,
      preselectFilter,
      thisClassForms,
      historicalFormsPagination
    };
  },
};
</script>
<style lang="scss" scoped>
@import '../../styles/main.scss';

.home{
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 79px);
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  padding: 24px 0px;
  padding-bottom: 40px;
  //max-width: 1024px;
  //margin: 0 auto;
  .dropdown__face{
    width: 135px;
  }

  &__ilustration{
    @media (max-width: 960px){
      display: none;
    }
  }
  &__content{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 36px;
    width: 100%;
    @media screen and (max-width: $break-md){
      gap: 0px;
    }
    &__image {
      //width: 50%;
      //display: flex;
      //justify-content: center;
      @media screen and (max-width: $break-md){
        width: 0;
      }
    }
    .home__card{
      margin-bottom: 26px;
      border-radius: 5px;
      min-width: 40%;
      width: 96%;
      max-width: 580px;
      &_title{
        font-size: 12px;
        font-weight: 400;
        padding: 24px 0;
        padding-left: 12px;
        background-color: $blue;
        color: #fff;
        border-radius: 5px 5px 0 0;
        strong{
          font-size: 12px;
          font-weight: 600
        }
      }
      &_buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 34px 0;
        .btn{
          width: 250px;
          @media (max-width: 350px){
            width: 150px;
          }
          &:first-child{
            background-color: $lightblue;
            color: #fff;
          }
          &:last-child{
            background-color: $yellow;

          }
        }
      }
    }
  }
}


.table{
  display: grid;
  // grid-template-columns: 2fr repeat(4, minmax(150px, 1fr)) .5fr;
  grid-template-columns: minmax(200px, 2fr) repeat(2, minmax(100px, 1fr)) .5fr;
  gap: 12px 0;
  width: 100%;
  padding: 24px 38px;
  border-radius: 5px;
  overflow-x: auto;
  &__noResults{
    grid-column-start: 1;
    grid-column-end: 5;
    display: flex;
    justify-content: center;
    margin: 12px 0;
    font-size: 16px;
    font-weight: 500;
  }
  &__title{
    font-size: 12px !important;
    font-weight: 700 !important;
    padding-right: 12px;
  }
  &__data{
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #BDBDBD;
    text-align: start;
    padding-right: 12px;
    padding-bottom: 12px;
  }
  &__review{
    transition: all .2s ease-in-out;
    &:hover{
      opacity: .8;
    }
    cursor: pointer;
    color: $lightblue;
  }
  &__pagination{
    grid-column-start: 1;
    // grid-column-end: 7;
    grid-column-end: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span{
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      strong{
        font-size: 16px;
        font-weight: 600;
      }
    }
    button{
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      gap: 6px;
      cursor: pointer;
      color: $lightgreen;
      &:disabled{
        color: #000;
        opacity: .8;
        cursor: default;
      }
      &:first-child{
        .material-icons-outlined{
            transform: rotateY(180deg)
        }
      }
      .material-icons-outlined{
        font-size: 16px;
      }
    }
  }
}
.btn {
  @include font-settings(xmilli, base); 
  padding: 18px 0;
  height: 50px  ;
}
.buttons {
  justify-content: space-evenly;
  button {
    min-width: 120px;
  }
}
.text-modal {
  .text {
    padding: $margin-kilo $margin-small;
  }
}

.table-course {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  &__header{
    padding: 12px 38px;
    background: $blue;
    color: white;
    font-size: 14px;
    font-weight: normal;
  }
  &__content div {
    font-size: 14px;
    font-weight: lighter;
    padding: 12px 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #BDBDBD;
    @media (max-width: 500px){
      flex-direction: column;
      text-align: center;
      gap: 8px;
    }
    &:last-child {
      border: none;
    }

    .btn {
      height: auto;
      width: fit-content;
      padding: 8px 40px;
      background: $lightblue;
      color: white;
      transition: all .2s ease;
      &:hover{
        opacity: .6;
        cursor: pointer;
      }
    }
  }
}
.header{
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  &__filters{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  &_filterButton{
    font-size: 20px;
    cursor: pointer;
  }
}
.btn.disabled {
  pointer-events: none;
}
</style>

