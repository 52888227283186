<template>
    <div class="evaluation">
        <h2>{{ $t('evaluation.myAttendance') }}</h2>
        <div class="evaluation__assistance">
                <div class="evaluation__pieDashboard bx-shadow">
                    <PieChart :chartData="assistData" :options="dashOptions" width="300" height="300" />
                </div>
                <div class="evaluation__dairy bx-shadow">
                    <span style="font-weight: 700;">{{ $t('evaluation.learningDiary') }}</span>
                    <p>{{ $t('evaluation.diaryDescription') }}</p>
                    <a>{{ $t('evaluation.viewDetail') }}</a>
                </div>
        </div>

        <h2>ADOLFO, {{ $t('evaluation.yourEvaluations') }}</h2>
        <div class="bx-shadow evaluation__barGraphic">
            <BarChart :chartData="evaluationData" :options="evaluationOptions" />
        </div>

        <h2>{{ $t('evaluation.detailByAxis') }}</h2>
        <div class="bx-shadow evaluation__detail">
            <div class="evaluation__detail-text">
                <span style="font-weight: 700;">{{ $t('evaluation.selfKnowledge') }}</span>
                <p>{{ $t('evaluation.selfKnowledgeDescription') }}</p>
                <router-link to="/selfknowledgeAxis">{{ $t('evaluation.viewDetail') }}</router-link>
            </div>
            <div class="evaluation__detail-cards">
                <DetailCard color="#00C58F" icon="badge" title="{{ $t('evaluation.personalImage') }}" clasification="{{ $t('evaluation.toMaintain') }}" percentage="95" />
                <DetailCard color="#FF3B66" icon="psychology" title="{{ $t('evaluation.selfAwareness') }}" clasification="{{ $t('evaluation.toImprove') }}" percentage="45" />
            </div>
        </div>

        <div class="bx-shadow evaluation__detail">
            <div class="evaluation__detail-text">
                <span style="font-weight: 700;">{{ $t('evaluation.attentionToOthers') }}</span>
                <p>{{ $t('evaluation.attentionToOthersDescription') }}</p>
                <router-link to="/selfknowledgeAxis">{{ $t('evaluation.viewDetail') }}</router-link>
            </div>
            <div class="evaluation__detail-cards">
                <DetailCard color="#00C58F" icon="badge" title="{{ $t('evaluation.personalImage') }}" clasification="{{ $t('evaluation.toMaintain') }}" percentage="95" />
                <DetailCard color="#FF3B66" icon="psychology" title="{{ $t('evaluation.selfAwareness') }}" clasification="{{ $t('evaluation.toImprove') }}" percentage="45" />
                <DetailCard color="#FF3B66" icon="psychology" title="{{ $t('evaluation.selfAwareness') }}" clasification="{{ $t('evaluation.toImprove') }}" percentage="45" />
            </div>
        </div>

        <div class="bx-shadow evaluation__detail">
            <div class="evaluation__detail-text">
                <span style="font-weight: 700;">{{ $t('evaluation.commitmentAndAction') }}</span>
                <p>{{ $t('evaluation.commitmentAndActionDescription') }}</p>
                <router-link to="/selfknowledgeAxis">{{ $t('evaluation.viewDetail') }}</router-link>
            </div>
            <div class="evaluation__detail-cards">
                <DetailCard color="#00C58F" icon="badge" title="{{ $t('evaluation.personalImage') }}" clasification="{{ $t('evaluation.toMaintain') }}" percentage="95" />
                <DetailCard color="#FF3B66" icon="psychology" title="{{ $t('evaluation.selfAwareness') }}" clasification="{{ $t('evaluation.toImprove') }}" percentage="45" />
                <DetailCard color="#FF3B66" icon="psychology" title="{{ $t('evaluation.selfAwareness') }}" clasification="{{ $t('evaluation.toImprove') }}" percentage="45" />
            </div>
        </div>

    </div>
</template>
<script>
import { BarChart, PieChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import DetailCard from '../../components/DetailCard.vue';
Chart.register(...registerables);

import { useI18n } from 'vue-i18n';


export default {
    components: { PieChart, BarChart, DetailCard },
    setup() {
        const { t } = useI18n();

        const assistData = {
            labels: [
                t('evaluation.attendanceLabels.absent'),
                t('evaluation.attendanceLabels.late'),
                t('evaluation.attendanceLabels.present')
            ],
            datasets: [
                {
                    data: [30, 40, 110],
                    backgroundColor: ['#FF3B66', '#FFB600', '#00C58F'],
                    pointStyleWidth: 20
                },
            ],
        }

        const evaluationData = {
            labels: Object.values(t('evaluation.evaluationLabels')),
            datasets: [
                {
                    type: 'bar',
                    label: t('evaluation.evaluationTypes.selfAssessment'),
                    data: [100, 70, 70, 50, 70, 80, 55, 30],
                    backgroundColor: ['#E9E9F5'],
                    borderRadius: 50,
                    barThickness: 26,
                    pointRadius: 50,
                    pointStyleWidth: 20
                },
                {
                    label: t('evaluation.evaluationTypes.toMaintain'),
                    data: [75, null, 70, null, 70, null, null, null],
                    backgroundColor: ['#00C58F'],
                    borderRadius: 50,
                    barThickness: 26,
                    pointRadius: 50,
                    pointStyleWidth: 20
                },
                {
                    label: t('evaluation.evaluationTypes.toImprove'),
                    data: [null, 50, null, null, null, null, null, 25],
                    backgroundColor: ['#FF3B66'],
                    borderRadius: 50,
                    barThickness: 26,
                    pointRadius: 50,
                    pointStyleWidth: 20
                },
                {
                    label: t('evaluation.evaluationTypes.toWorkOn'),
                    data: [null, null, null, 35, null , 75, 40, null],
                    backgroundColor: ['#FFB600'],
                    borderRadius: 50,
                    barThickness: 26,
                    pointRadius: 50,
                    pointStyleWidth: 20
                }
            ]
        }

        const dashOptions = {
            plugins: {
                legend: {
                    position: 'right',
                    labels: {
                        usePointStyle: true
                    }
                }
            },
        }

        const evaluationOptions = {
            plugins: {
                legend: {
                    position: 'right',
                    labels: {
                        usePointStyle: true
                    }
                }
            },
            skipNull: true,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        barThickness: 2
                    }
                ],
                y: {
                    beginAtZero: true,
                    ticks: {
                        stepSize: 25,
                        callback: function (value) {
                            if (this.getLabelForValue(value) == 0) return t('evaluation.chartOptions.never')
                            else if (this.getLabelForValue(value) == 25) return t('evaluation.chartOptions.rarely')
                            else if (this.getLabelForValue(value) == 50) return t('evaluation.chartOptions.sometimes')
                            else if (this.getLabelForValue(value) == 75) return t('evaluation.chartOptions.often')
                            else if (this.getLabelForValue(value) == 100) return t('evaluation.chartOptions.always')
                            else return ''
                        }
                    }
                }
            }
        }

        return {
            assistData,
            evaluationOptions,
            evaluationData,
            dashOptions
        }
    }
}
</script>
<style lang="scss" scoped>
.evaluation{
    display: flex;
    flex-direction: column;
    gap: 38px;
    min-height: 100vh;
    padding: 12px 5%;
    h2{
        font-weight: 600;
        font-size: 18px;
    }
    &__detail{
        display: flex;
        justify-content: space-between;
        padding: 68px 40px;
        padding-bottom: 34px;
        align-items: center;
        min-height: 330px;
        height: fit-content;
        width: 100%;
        background-color: $lightgray;
        border-radius: 40px;
        gap: 12px;
        position: relative;
        @media (max-width: 915px){
            flex-direction: column;
            gap: 24px;
            &-cards{
                width: 100%;
            }
        }
        &-text{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly  ;
            font-size: 18px;
            width: 50%;
            min-width: 25%;
            height: 230px;
            @media (max-width: 915px){
                width: 100%;
                text-align: center;
            }
            a{
                cursor: pointer;
                font-weight: 600;
                font-size: 16px;
                color: $blue;
            }
        }
        &-cards{
            display: flex;
            align-items: center;
            gap: 29px;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width: 915px){
                width: 100%;
                text-align: center;
            }
        }
    }
    &__assistance{
        display: flex;
        align-items: center;
        gap: 20px;
        min-height: 300px;
        @media (max-width: $break-sm){
            flex-direction: column;
        }
    }
    &__pieDashboard{
        width: calc(50% - 10px);
        border-radius: 10px;
        height: 100%;
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: $break-sm){
            width: 100%;
        }
    }
    &__dairy{
        width: calc(50% - 10px);
        border-radius: 10px;
        background-color: $lightgray;
        height: 100%;
        min-height: 300px;
        font-weight: 400;
        font-size: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 35px;
        gap: 12px;
        a{
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
            color: $blue;
        }
        @media (max-width: $break-sm){
            width: 100%;
        }
    }
    &__barGraphic{
        padding: 12px;
    }

}
</style>