<template>
  <div class="selfknowledge">
    <h1>{{ $t('selfKnowledge.title') }}</h1>
    <p>{{ $t('selfKnowledge.description') }}</p>

    <div class="selfknowledge__container-table">
      <div class="selfknowledge__container-table__header">
        <strong>{{ $t('selfKnowledge.categories.personalImage.title') }}</strong>
        <span>{{ $t('selfKnowledge.categories.personalImage.description') }}</span>
        <h2>95%</h2>
        <span class="text-green">+10% {{ $t('selfKnowledge.categories.personalImage.improvement') }}</span>
      </div>
      <table class="selfknowledge__container-table__table bx-shadow">
        <tr>
          <th rowspan=2>{{ $t('selfKnowledge.indicators') }}</th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 2 <strong class="text-green percentage">85%</strong>
          </th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 4 <strong class="text-green percentage">95%</strong>
          </th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 5
          </th>
        </tr>
        <tr>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.whenOnScreen') }}</td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.frequently') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.postureAttitude') }}</td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.frequently') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.whenOnScreen') }}</td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.postureAttitude') }}</td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.frequently') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
      </table>
    </div>

    <div class="selfknowledge__container-table">
      <div class="selfknowledge__container-table__header">
        <strong>{{ $t('selfKnowledge.categories.selfAwareness.title') }}</strong>
        <span>{{ $t('selfKnowledge.categories.selfAwareness.description') }}</span>
        <h2>45%</h2>
        <span class="text-red">+10% {{ $t('selfKnowledge.categories.selfAwareness.improvement') }}</span>
      </div>
      <table class="selfknowledge__container-table__table bx-shadow">
        <tr>
          <th rowspan=2>{{ $t('selfKnowledge.indicators') }}</th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 2 <strong class="text-red percentage">25%</strong>
          </th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 4 <strong class="text-red percentage">45%</strong>
          </th>
          <th colspan="2">
            {{ $t('selfKnowledge.level') }} 5
          </th>
        </tr>
        <tr>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
          <th>{{ $t('selfKnowledge.auto') }}</th>
          <th>{{ $t('selfKnowledge.facilitator') }}</th>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.whenOnScreen') }}</td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-red">{{ $t('selfKnowledge.evaluation.rarely') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.postureAttitude') }}</td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-red">{{ $t('selfKnowledge.evaluation.rarely') }}</span></td>
          <td><span class="pill pill-green">{{ $t('selfKnowledge.evaluation.always') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.whenOnScreen') }}</td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-red">{{ $t('selfKnowledge.evaluation.rarely') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
        <tr>
          <td>{{ $t('selfKnowledge.evaluation.postureAttitude') }}</td>
          <td><span class="pill pill-red">{{ $t('selfKnowledge.evaluation.rarely') }}</span></td>
          <td><span class="pill pill-red">Nunca</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill pill-yellow">{{ $t('selfKnowledge.evaluation.sometimes') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
          <td><span class="pill">{{ $t('selfKnowledge.evaluation.na') }}</span></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

// eslint-disable-next-line no-unused-vars
const { t } = useI18n();
</script>
<style lang="scss">
  .selfknowledge {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 79px);
    gap: 24px;
    width: 100%;
    padding: 24px 8%;

    &__container-table {
      margin-bottom: 24px;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        background: #f5f5f5;
      }

      &__table {
        width: 100%;

        th, td {
          border-bottom: 2px solid #dedede;
          border-left: 2px solid #dedede;
          margin: 0;
          padding: 16px;
        }

        .pill {
          display: block;
          text-align: center;
          font-weight: bold;
          width: 160px;
          padding: 4px 12px;
          border-radius: 12px;
          color: #0000007c;

          &-green {
            color: $green;
            background: #e5f9f4;
          }
          &-yellow {
            color: $yellow;
            background: #fff4d9;
          }
          &-red {
            color: $red;
            background: #ffe2e8;
          }
        }
      }
    }

    .text-green {
      color: $green;
    }

    .text-red {
      color: $red;
    }

    .percentage {
      margin-left: 8px;
    }
  }
</style>