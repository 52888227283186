<template>
  <div class="learning">
    <div class="learning__back" @click="goBack">
      <i class="material-icons">arrow_back</i> {{ $t('global.back') }}
    </div>

    <div class="learning__header">
      <strong>{{ $t('learningDiary.learningDiaries') }}</strong>
    </div>

    <div class="bx-shadow learning__dairy">
      <div class="learning__dairy__header">
        <div>NOMBRE, {{ $t('learningDiary.whatHaveYouLearned') }}</div>
        <a>{{ $t('learningDiary.download') }}</a>
      </div>

      <div class="learning__dairy__container">
        <div><i>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam qui voluptates quasi non explicabo? Nostrum distinctio nisi tempore ipsam dolores, illum quasi omnis! Vitae, consequuntur eveniet. Praesentium sunt aliquid voluptate!"</i></div>
        <div><i>"Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam qui voluptates quasi non explicabo? Nostrum distinctio nisi tempore ipsam dolores, illum quasi omnis! Vitae, consequuntur eveniet. Praesentium sunt aliquid voluptate!"</i></div>
      </div>

      <div class="learning__dairy__footer">
        <a>{{ $t('learningDiary.viewMore') }}</a>
      </div>
    </div>


    <div class="learning__header">
      <strong>{{ $t('learningDiary.learningDiaryHistory') }}</strong>
      <span class="learning__header__text">
        <strong>45</strong> {{ $t('learningDiary.responsesOf') }}
        <strong>51</strong> {{ $t('learningDiary.questions') }}
      </span>
    </div>
    <div class="table bx-shadow">
      <span class="table__title">{{ $t('learningDiary.class') }}</span>
      <span class="table__title">{{ $t('learningDiary.date') }}</span>
      <span class="table__title">{{ $t('learningDiary.axis') }}</span>
      <span class="table__title">{{ $t('learningDiary.status') }}</span>
      <span class="table__title"></span>

      <template v-for="(result, idx) in experimentalResults">
        <span class="table__data" v-if="true" :key="idx + 'className'">{{ result.className }}</span>
        <span class="table__data" v-if="true" :key="idx + 'date'">{{ formatDate(result.date) }}</span>
        <span class="table__data" v-if="true" :key="idx + 'eje'">{{ result.eje }}</span>
        <div class="table__data" v-if="true" :key="idx + 'status'">
          <span :class="`status--${result.status.toLowerCase()}`">
            {{ $t(`status.${result.status.toLowerCase()}`) }}
            <i class="material-icons">
              {{ result.status.toLowerCase() === 'completed' ? 'check_circle' : 'highlight_off' }}
            </i>
          </span>
        </div>
        <span class="table__review table__data" v-if="true" :key="idx + 'check'">
          <i class="material-icons">more_vert</i>
        </span>
      </template>
      <div class="table__pagination">
        <button :disabled="true"><i class="material-icons-outlined">arrow_forward</i>{{ $t('learningDiary.previous') }}</button>
        <span style="border-bottom: 0;"><strong>{{ $t('learningDiary.page') }} 01</strong> / 03</span>
        <button>{{ $t('learningDiary.next') }} <i class="material-icons-outlined">arrow_forward</i></button>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const router = useRouter();
    const formatDate = inject('formatDate')
    const experimentalResults = ref([
      {
        className: 'Nombre de la clase',
        date: new Date(),
        eje: 'Atención a los demás y al entorno',
        competence: 'Empatía',
        status: 'completed'
      },
      {
        className: 'Nombre de la clase',
        date: new Date(),
        eje: 'Atención a los demás y al entorno',
        competence: 'Empatía',
        status: 'incompleted'
      },
      {
        className: 'Nombre de la clase',
        date: new Date(),
        eje: 'Atención a los demás y al entorno',
        competence: 'Empatía',
        status: 'completed'
      },
      {
        className: 'Nombre de la clase',
        date: new Date(),
        eje: 'Atención a los demás y al entorno',
        competence: 'Empatía',
        status: 'completed'
      },
    ]);

    function goBack() {
      router.go(-1)
    }
    
    return {
      goBack,
      formatDate,
      experimentalResults
    }
  }
}
</script>

<style lang="scss" scoped> 

.learning {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 79px);
  gap: 24px;
  width: 100%;
  padding: 24px 8%;

  &__back {
    color: $blue;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 4px;

    i {
      font-size: 16px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    div {
      display: flex;
      justify-content: space-between;

      span {
        font-weight: lighter;
      }
    }

    &__text {
      font-size: 16px;
      
      strong {
        font-size: 16px;
        color: $blue;
      }
    }
  }

  &__dairy {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    margin-bottom: 24px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__container {
      display: flex;
      flex-direction: column;
      gap: 32px;

      div {
        padding-bottom: 24px;
        border-bottom: 1px solid #BDBDBD;
        
        i {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: center;
    }

    &__header a, &__footer a {
      color: $blue;
      font-size: 16px;
    }
  }

  .table{
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr) .5fr;
    gap: 12px 0;
    width: 100%;
    padding: 24px 38px;
    border-radius: 5px;
    &__title{
      font-size: 12px !important;
      font-weight: 700 !important;
    }
    &__data{
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid #BDBDBD;
      text-align: start;
      padding-right: 12px;
      padding-bottom: 12px;
    }
    &__review{
      transition: all .2s ease-in-out;
      &:hover{
        opacity: .8;
      }
      cursor: pointer;
      color: $blue;
    }
  
    &__pagination{
      grid-column-start: 1;
      grid-column-end: 6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        strong{
          font-size: 16px;
          font-weight: 600;
        }
      }
      button{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        gap: 6px;
        cursor: pointer;
        color: $lightgreen;
        &:disabled{
          color: #000;
          opacity: .8;
          cursor: default;
        }
        &:first-child{
          .material-icons-outlined{
              transform: rotateY(180deg)
          }
        }
        .material-icons-outlined{
          font-size: 16px;
        }
      }
    }
  } 
}

</style>