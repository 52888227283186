<template>
    <div v-if="(values.length > 6 && mode === 'simple') || (isDropdown && mode === 'simple')" style="width: 100%">
        <div class="dropdown">
            <input type="checkbox" :id="`select--${id}`" ref="dropdown">
            <input @click="onChangeSearch" class="dropdown__search" v-model="search" :id="`dropdown_search--${id}`">
            <label class="dropdown__face" :for="`select--${id}`">
                <label :for="`dropdown_search--${id}`" v-if="search?.length === 0"
                    :class="`dropdown__label ${isError && 'dropdown__label--error' || ''}`">{{ label }}</label>
                <span class="material-icons">expand_more</span>
            </label>
            <ul class="dropdown__items">
                <template v-if="typeof values[0] === 'object'">
                    <li @click="selectOption(option)" v-for="(option, idx) in filterValues()" :key="idx">{{
                        option[valueLabel] }}</li>
                </template>
                <template v-else>
                    <li @click="selectOption(option)" v-for="(option, idx) in filterValues()" :key="idx">{{ option }}</li>
                </template>
            </ul>
        </div>
        <span v-if="isError && errorMessage" class="dropdown__error">{{ errorMessage }}</span>
        <span v-if="supportText?.length" class="input__supportText">{{ supportText }}</span>

    </div>

    <template v-else-if="values.length >= 1 && values.length <= 6 && mode === 'simple'">
        <div style="width: 100%;">
            <div :class="`select${disabled && '--disabled' || ''}`">
                <span class="select__label">{{ label.toUpperCase() }}</span>
                <div class="select__options">
                    <div v-for="(value, idx) in values" :key="idx" class="select__option">
                        <template v-if="typeof value === 'object'">
                            <input @click="selectOption(value)" :id="`${id}--rb${idx}`" type="radio" :name="`${id}--rb`"
                                :disabled="disabled" :checked="modelValue === value[valueProp]">
                            <label @click="selectOption(value[valueProp])" :for="`${id}--rb${idx}`">{{ value[valueLabel]
                            }}</label>
                        </template>
                        <template v-else>
                            <input @click="selectOption(value)" :id="`${id}--rb${idx}`" type="radio" :name="`${id}--rb`"
                                :disabled="disabled" :checked="modelValue === value">
                            <label @click="selectOption(value)" :for="`${id}--rb${idx}`">{{ value }}</label>
                        </template>
                    </div>
                </div>
            </div>
            <span v-if="isError" class="dropdown__error">{{ errorMessage }}</span>
            <span v-if="supportText?.length" class="input__supportText">{{ supportText }}</span>
        </div>

    </template>

    <template v-else-if="(mode === 'multi' && isDropdown) || (mode === 'multi' && values.length > 6)">
        <div style="width: 100%;">
            <div class="dropdown" ref="multiselect">
                <input type="checkbox" :id="`dropdown--${id}`" ref="multiselectDropdown">
                <input @click="onChangeSearch" class="dropdown__search" :id="`dropdown_search--${id}`" v-model="search">
                <label class="dropdown__face" :for="`dropdown--${id}`">
                    <label :for="`dropdown_search--${id}`" v-if="search?.length === 0"
                        :class="`dropdown__label ${isError && 'dropdown__label--error' || ''}`">{{ label }} {{ hideSelected
                            && `(${selected.length})` || '' }}</label>
                    <span class="material-icons">expand_more</span>
                </label>
                <ul class="dropdown__items">
                    <template v-if="valueProp">
                        <li class="dropdown__withInput" v-for="(option, idx) in filterValues()" :key="idx + id">
                            <input class="dropdown" @change="selectOption(option[valueProp])" type="checkbox" :id="idx + id"
                                :checked="selected.includes(option[valueProp])" />
                            <label :for="idx + id">{{ option[valueLabel] }}</label>
                        </li>
                    </template>
                    <template v-else>
                        <li class="dropdown__withInput" v-for="(option, idx) in filterValues()" :key="idx + id">
                            <input class="dropdown" @change="selectOption(option)" type="checkbox" :id="idx + id"
                                :checked="selected.includes(option)" />
                            <label :for="idx + id">{{ option }}</label>
                        </li>
                    </template>
                </ul>
            </div>
            <div v-if="selected.length > 0 && !hideSelected" class="selected">
                <span v-for="(select, idx) in selected" :key="idx + 'selecteds'" class="selected__pills">{{ valueProp ?
                    values.find(value => value[valueProp] === select)[valueLabel] : select }} <i class="material-icons"
                        @click="selectOption(select)">close</i></span>
            </div>
            <span v-if="isError" class="dropdown__error">{{ errorMessage }}</span>
            <span v-if="supportText?.length" class="input__supportText">{{ supportText }}</span>
        </div>
    </template>
    <template v-else-if="mode === 'multi' && values.length >= 2 && values.length <= 6">
        <div style="width: 100%;">
            <div>
                <span class="select__label">{{ label }}</span>
                <ul class="multiselect__list">
                    <li v-for="(value, idx) in values" :key="idx">
                        <template v-if="valueProp">
                            <input @click="selectOption(value[valueProp])" type="checkbox" :id="`${idx}--${id}`"
                                :checked="selected.includes(value[valueProp])" />
                            <label @click="selectOption(value[valueProp])" :for="`${idx}--${id}`">{{ value[valueLabel]
                            }}</label>
                        </template>
                        <template v-else>
                            <input @click="selectOption(value)" type="checkbox" :id="`${idx}--${id}`"
                                :checked="selected.includes(value)" />
                            <label @click="selectOption(value)" :for="`${idx}--${id}`">{{ value }}</label>
                        </template>
                    </li>
                </ul>
            </div>
            <span v-if="isError" class="dropdown__error">{{ errorMessage }}</span>
            <span v-if="supportText?.length" class="input__supportText">{{ supportText }}</span>
        </div>

    </template>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';

export default {
    props: {
        mode: {
            type: String,
            default: 'simple'
        },
        label: {
            type: String
        },
        placeholder: {
            type: String
        },
        id: {
            required: true
        },
        isError: {
            type: Boolean
        },
        errorMessage: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        optional: {
            type: Boolean
        },
        supportText: {
            type: String
        },
        values: {
            type: Array
        },
        modelValue: {},
        valueProp: {},
        valueLabel: {},
        isDropdown: {
            type: Boolean,
            default: true
        },
        hideSelected: {
            type: Boolean,
            default: false
        }
    },

    setup(props, { emit }) {
        const isOpen = ref(false)
        const selected = ref([])
        const search = ref('')
        const dropdown = ref(null)
        const multiselect = ref(null)
        const multiselectDropdown = ref(null)

        const selectOption = (option) => {
            if (props.mode === 'multi') {
                if (selected.value.length === 0) {
                    selected.value.push(option)
                    emit('update:modelValue', selected.value)
                }
                else {
                    let index = selected.value.findIndex(select => select === option)
                    index === -1 && selected.value.push(option) || selected.value.splice(index, 1)
                    emit('update:modelValue', selected.value)

                }
            }
            else if (props.mode === 'simple' && props.valueProp) {
                selected.value = option[props.valueProp]
                search.value = `${option[props.valueLabel]}`
                emit('update:modelValue', selected.value)
                const input = document.getElementById(`select--${props.id}`)
                if (props.values.length >= 3 || props.mode === 'simple') input.checked = false
            }
            else if (props.mode === 'simple') {
                selected.value = option
                search.value = `${option}`
                emit('update:modelValue', selected.value)
                const input = document.getElementById(`select--${props.id}`)
                if (props.values.length >= 3) input.checked = false
            }
        }

        const filterValues = () => {
            let text = search.value.length && search?.value?.toLowerCase()?.trim() || ''
            if (props.valueProp && selected.value !== [] && props.mode === 'simple') {
                let selectOption = props.values.find(value => value[props.valueProp] === selected.value)
                if (selectOption) {
                    text = selectOption[props.valueLabel] === search.value ? '' : text
                }
            }
            else if (selected.value !== [] && props.mode === 'simple') {
                text = selected.value === search.value ? '' : text
            }

            return props.values.filter((item) => {
                if (props.valueProp) {
                    let itemLowerCase = item[props.valueLabel]?.toLowerCase().trim();
                    let words = itemLowerCase?.split(' ')
                    return words?.some((word) => {
                        return word.indexOf(text) > -1
                    })
                }
                let itemLowerCase = toString(item).toLowerCase().trim();
                let words = itemLowerCase.split(' ')
                return words.some((word) => {
                    return word.indexOf(text) > -1
                })
            })
        }

        const onChangeSearch = () => {
            if (search.value.trim().length === 0 && props.mode === 'simple') {
                emit('update:modelValue', undefined)
                selected.value = []
            }
        }

        onMounted(() => {
            if (props.modelValue !== null && props.modelValue !== undefined) {
                if (props.mode === 'simple') {
                    if (Array.isArray(props.modelValue)) {
                        search.value = props.valueProp && props.values.find(value => value[`${props.valueProp}`] === props.modelValue[0])[`${props.valueLabel}`] || props.modelValue[0]
                        selected.value = props.valueProp && props.values.find(value => value[`${props.valueProp}`] === props.modelValue[0])[`${props.valueProp}`] || props.modelValue[0]
                    }
                    else {
                        search.value = props.valueProp && props.values.find(value => value[`${props.valueProp}`] === props.modelValue)[`${props.valueLabel}`] || props.modelValue
                        selected.value = props.valueProp && props.values.find(value => value[`${props.valueProp}`] === props.modelValue)[`${props.valueProp}`] || props.modelValue
                    }
                }
                else if (props.mode === 'multi') {
                    selected.value = props.modelValue
                }
            }

            document.addEventListener('click', (e) => {
                if (e.target !== dropdown.value) {
                    dropdown.value.checked = false
                }
            })
        })

        onUnmounted(() => {
            document.removeEventListener('click', (e) => {
                if (e.target !== dropdown.value) {
                    dropdown.value.checked = false
                }
            })
        })

        const openDropdown = () => dropdown.value.checked = true

        return {
            isOpen,
            selected,
            selectOption,
            search,
            dropdown,
            openDropdown,
            filterValues,
            multiselect,
            multiselectDropdown,
            onChangeSearch
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

input[type='radio']:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #000;
}

input[type='radio']:checked:before {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid $green;
}

input[type='radio']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 20px;
    position: relative;
    background-color: $green;
    content: '';
    display: inline-block;
    visibility: visible;
    border: none;
    top: -20px;
    left: 4px;

    @media (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        top: -19px;
    }
}

.input__supportText {
    font-weight: 600;
    font-size: 10px;
    color: $black;
    padding: 0 28px;
    margin-top: 6px;
}

.multiselect {
    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 10px;

        li {
            display: flex;
            align-items: center;
            gap: 21px;
            font-weight: 400;
            font-size: 12px;
        }
    }
}

.selected {
    margin-top: 6px;
    padding: 0px 16px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    &__pills {
        padding: 6px 10px 6px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        font-size: 13px;
        background-color: #F6F6F6;
        border: 1px solid #000;
        border-radius: 50px;
        min-width: 150px;
        gap: 12px;

        .material-icons {
            font-size: 16px;
            cursor: pointer;
        }
    }
}

.select {
    &__label {
        font-weight: 500;
        font-size: 12px;
    }

    &__options {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 12px;
    }

    &__option {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
        font-size: 13px;
        cursor: pointer;

        label {
            cursor: pointer;
        }
    }

    &--disabled {
        opacity: .6;
    }
}

.dropdown {
    position: relative;
    // min-width: 150px;
    width: 100%;
    cursor: pointer;

    &__face,
    &__items,
    &__items--deleted {
        background-color: #F6F6F6;
        border-radius: 25px;
        padding: 14px 28px;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $black;
        user-select: none;
        border: none !important;
    }

    &__search {
        display: block !important;
        position: absolute;
        top: 18px;
        left: 28px;
        background: inherit;
        border: none;
        max-width: 50%;
        z-index: 999;
    }

    &__error {
        font-weight: 600;
        font-size: 10px;
        color: $red;
        padding-left: 28px;
    }

    &__label {
        cursor: text;
        position: absolute;
        top: 4px;
        left: 28px;
        user-select: none;
        transform: translateY(10px);
        transition: all .3s;
        line-height: 25px;
        font-size: 13px;
        color: $black;

        &--completed {
            @extend .dropdown__label;
            transform: translateY(-2px);
            font-size: 10px;
            color: $green;
        }

        &--error {
            color: $red !important;
        }
    }

    &__withInput {
        display: flex;
        align-items: center;
        gap: 20px;

        input {
            display: inline-block !important;
        }

        label {
            cursor: pointer;
        }
    }

    &__face {
        padding: 14px 28px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 53px;

        .material-icons {
            font-size: 18px;
            transition: all .3s;
            position: absolute;
            top: 18px;
            right: 28px;
        }
    }

    &__items,
    &__items--deleted {
        padding: 20px;
        background-color: #f5f5f5;
        margin: 0;
        position: absolute;
        top: 30%;
        width: 100%;
        gap: 10px;
        list-style: none;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s cubic-bezier(0.93, 0.88, 0.1, 0.8);

        li {
            transition: all .2s;
        }

        li:hover {
            color: rgba($color: #000000, $alpha: .9);
        }
    }

    input {
        display: none;

        &:checked~.dropdown__items {
            top: calc(100% + 10px);
            visibility: visible;
            opacity: 1;
            background-color: #F6F6F6;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            z-index: 9999;

            li:hover {
                font-weight: 600;
            }
        }

        &:checked~.dropdown__face {
            .material-icons {
                transform: rotateX(180deg);
            }
        }
    }
}</style>