import interceptor from "@/plugins/interceptor";
const pathController = "/joven";

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    async get_proxima_clase_joven() {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/proximaClaseGet`
      );
    },
    async dar_presente(_, alumnoid) {
      return await interceptor.authenticate.post(
        `${process.env.VUE_APP_API_URL}${pathController}/darPresente/${alumnoid}`
      );
    },
    async joven_instanciasFormulario() {
      return await interceptor.authenticate.get(
        `${process.env.VUE_APP_API_URL}${pathController}/instanciasDeFormualariosGet`
      );
    },
  },
};
