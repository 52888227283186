import { createApp } from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import Router from './router'
import Store from './store'
import i18n from './i18n'
import gAuth from 'vue3-google-auth'; 
const $gAuth = gAuth.createGAuth({
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email openid',
  prompt: 'select_account',
});
window.i18n = i18n;

window.vueApp = createApp(App)
    .use(i18n)
    .use(Store)
    .use(Router)
    .use($gAuth)
    .mount('#app')
