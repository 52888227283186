<template>
  <div style="width: 100%">
    <div class="input" ref="datepicker">
      <i @blur="showCalendar = false" @keypress.esc="showCalendar = false"
        @click="showCalendar = !disabled && !showCalendar || false"
        class="material-icons-outlined input__date_icon">event</i>

      <input @input="updateValue" placeholder="dd/mm/aaaa"
        :class="`input__input${modelValue?.length && '--completed' || ''}`" :value="inputDateValue" :id="id"
        :disabled="disabled" maxlength="10" />

      <label :class="`input__label${modelValue?.length && '--completed' || ''} ${isError && 'input__label--error' || ''}`"
        :for="id">{{ label }} <span v-if="optional" class="input__optional">{{ 'Opcional' }}</span></label>

      <div @blur="showCalendar = !showCalendar" v-if="showCalendar" class="input__calendar">
        <div class="input__calendar_selectMonthAndYear">
          <i @click="changeDataInView(false)" class="material-icons">keyboard_double_arrow_left</i>
          <span @click="changeView" v-if="whatViewInDiv === 'calendar'">{{ `${calendarFormat.months[date.month]}
                      ${date.year}` }}</span>
          <span @click="changeView" v-else-if="whatViewInDiv === 'years'">{{ `${Math.floor(date.year / 10) * 10} -
                      ${Math.floor(date.year / 10) * 10 + 9}` }}</span>
          <span @click="changeView" v-else>{{ calendarFormat.months[date.month] }}</span>
          <i @click="changeDataInView(true)" class="material-icons">keyboard_double_arrow_right</i>
        </div>
        <div v-if="whatViewInDiv === 'calendar'" class="input__calendar_grid">
          <span class="input__calendar_grid--day" v-for="(day, idx) in calendarFormat.days" :key="idx">{{ day
          }}</span>
          <span v-for="(numberDay, idx) in getCalendarNumbers(date.month, date.year)" :key="idx"
            :style="[numberDay.month === date.month ? null : { opacity: '0.6' }]"
            :class="numberDay.day === date.day && new Date(modelValue).getMonth() === numberDay.month && numberDay.year === new Date(modelValue).getFullYear() ? 'input__calendar_grid--selected' : ''"
            @click="pickDate(numberDay)">
            {{ numberDay.day }}
          </span>
        </div>
        <div v-else-if="whatViewInDiv === 'years'" class="input__years_grid">
          <span @click="pickYear(year)" v-for="(year, idx) in getDecade(date.year)"
            :style="[idx === 0 || idx === 11 ? { opacity: '0.6' } : null]"
            :class="year === date.year ? 'input__calendar_grid--selected' : ''" :key="idx">
            {{ year }}
          </span>
        </div>
        <div v-else class="input__years_grid">
          <span @click="pickMonth(idx)" v-for="(month, idx) in calendarFormat.months"
            :class="idx === date.month ? 'input__calendar_grid--selected' : ''" :key="idx">
            {{ month.slice(0, 3) }}
          </span>
        </div>
      </div>
    </div>
    <span v-if="isError && errorMessage" class="input__errorMessage">{{ errorMessage }}</span>
    <span v-if="supportText?.length" class="input__supportText"><i class="material-icons">{{ supportTextIcon }}</i> {{
      supportText }}</span>
  </div>
</template>

<script>
import moment from 'moment';
import { inject, onMounted, ref, watch } from 'vue';

export default {
  props: {
    label: {
      type: String
    },
    id: {
      required: true
    },
    isError: {
      type: Boolean
    },
    errorMessage: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    optional: {
      type: Boolean
    },
    modelValue: {
      required: true
    },
    supportText: {},
    supportTextIcon: {},
    from: {
      type: Date
    },
    to: {
      type: Date
    }
  },
  setup(props, { emit }) {
    const showCalendar = ref(false)
    const placeholder = ref('dd/mm/aaaa')
    // Date actual en el datePicker (No es el dia pickeado especificametne)
    const date = ref({
      day: undefined,
      month: undefined,
      year: undefined
    })
    // Date si hay un "hasta"
    const toDate = ref({
      day: undefined,
      month: undefined,
      year: undefined
    })
    // Date si hay un "desde"
    const fromDate = ref({
      day: undefined,
      month: undefined,
      year: undefined
    })
    const formatDate = inject('formatDate')
    const datepicker = ref()
    const inputDateValue = ref()
    const decade = ref(0)
    const calendarFormat = ref({
      days: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
    })

    const views = ['calendar', 'years', 'months']

    const whatViewInDiv = ref('calendar')

    const isValidDate = (inputDateValue) => !isNaN(Date.parse(inputDateValue))

    const changeView = () => {
      whatViewInDiv.value = whatViewInDiv.value === views[views.length - 1] ? views[0] : views[views.findIndex(view => view === whatViewInDiv.value) + 1]
    }


    const getDecade = (year) => {
      const initialDecade = Math.floor((year + (decade.value * 10)) / 10) * 10;
      const years = [];
      for (let i = 0; i < 10; i++) {
        const yearActual = initialDecade + i;
        years.push(yearActual);
      }
      years.unshift(years[0] - 1)
      years.push(years[years.length - 1] + 1)

      return years;
    }

    const getCalendarNumbers = (mes, año) => {
      const firstDay = new Date(año, mes, 1).getDay();
      const daysInMonth = new Date(año, mes + 1, 0).getDate();
      const daysToShow = [];

      // si el mes no arranca un lunes muestra los numeros del mes anterior
      if (firstDay !== 1) {
        const daysInPreviousMonth = new Date(año, mes, 0).getDate(); // cantidad de días en el mes anterior
        const daysToShowFromPreviousMonth = firstDay === 0 ? 6 : firstDay - 1;
        for (let i = daysInPreviousMonth - daysToShowFromPreviousMonth + 1; i <= daysInPreviousMonth; i++) {
          daysToShow.push({ day: i, month: mes - 1 < 0 ? 11 : mes - 1, year: mes - 1 > 0 ? año : año - 1 });
        }
      }
      for (let i = 1; i <= daysInMonth; i++) {
        daysToShow.push({ day: i, month: mes, year: año });
      }

      // Si el mes no termina en domingo muestra los dias del mes siguiente
      const lastDay = new Date(año, mes, daysInMonth).getDay();
      if (lastDay !== 0) {
        const daysToShowFromNextMonth = 7 - lastDay;
        for (let i = 1; i <= daysToShowFromNextMonth; i++) {
          daysToShow.push({ day: i, month: mes + 1 == 12 ? 0 : mes + 1, year: mes + 1 > 11 ? año + 1 : año });
        }
      }
      return daysToShow;
    }

    const pickMonth = (month) => {
      if (props.from) {
        if (date.value.year === fromDate.value.year && month < fromDate.value.month) return
        else {
          whatViewInDiv.value = 'calendar'
          date.value.month = month
          pickDate(date)
        }
      }
      if (props.to) {
        if (date.value.year === toDate.value.year && month > toDate.value.month) return
        else {
          whatViewInDiv.value = 'calendar'
          date.value.month = month
          pickDate(date)
        }
      }
      else {
        whatViewInDiv.value = 'calendar'
        date.value.month = month
        pickDate(date)
      }
    }

    const pickYear = (year) => {
      if (props.from) {
        if (year >= fromDate.value.year) {
          whatViewInDiv.value = 'calendar'
          date.value.year = year
          pickDate(date)
        }
      }
      if (props.to) {
        if (year <= toDate.value.year) {
          whatViewInDiv.value = 'calendar'
          date.value.year = year
          pickDate(date)
        }
      }
      else {
        whatViewInDiv.value = 'calendar'
        date.value.year = year
        pickDate(date)
      }
      decade.value = 0
    }

    const changeDataInView = upgrade => {
      if (whatViewInDiv.value === 'calendar') {
        if (!upgrade && props.from && date.value.year === fromDate.value.year && date.value.month - 1 < fromDate.value.month) return
        if (upgrade && props.to && date.value.year === toDate.value.year && date.value.month + 1 > toDate.value.month) return
        if (upgrade) {
          if (date.value.month === 11) {
            date.value.month = 0
            date.value.year++
            return
          }
          date.value.month++
          return
        }
        else {
          if (date.value.month === 0) {
            date.value.month = 11
            date.value.year--
            return
          }
          date.value.month--
          return
        }
      }
      else if (whatViewInDiv.value === 'years') {
        // Bloquea la accion si intenta ver decadas que tenga años menores o mayores a los parametros puestos en props (from | to)
        if (!upgrade && props.from && date.value.year + ((decade.value - 1) * 10) < fromDate.value.year) return
        if (upgrade && props.to && date.value.year + ((decade.value + 1) * 10) > toDate.value.year) return
        decade.value = upgrade ? decade.value + 1 : decade.value - 1
      }
      else if (whatViewInDiv.value === 'months') {
        if (!upgrade && props.from && date.value.year === fromDate.value.year && date.value.month - 1 < fromDate.value.month) return
        if (upgrade && props.to && date.value.year === toDate.value.year && date.value.month + 1 > toDate.value.month) return
        date.value.month =
          date.value.month === 11 && upgrade ? date.value.month = 0
            : date.value.month === 0 && !upgrade ? date.value.month = 11
              : upgrade ? date.value.month + 1 : date.value.month - 1
      }
    }

    const pickDate = (day) => {
      // Formateo para devolver bien el valor del input date
      if (day.day && day.month >= 0 && day.year) {
        if (props.from) {
          if (new Date(day.year, day.month, day.day) < props.from) return
        }
        else if (props.to) {
          if (new Date(day.year, day.month, day.day) > props.to) return
        }
        const datePicked = `${day.year}-${day.month + 1 < 10 ? '0' + (day.month + 1) : day.month + 1}-${day.day < 10 ? '0' + day.day : day.day}`
        if (day.month !== date.value.month) date.value.month = day.month // en caso de que seleccione algun numero grisado (de otro mes al que esta parado), lo lleva a ese mes
        emit('update:modelValue', datePicked)
        date.value.day = day.day
        date.value.month = day.month
        date.value.year = day.year
        inputDateValue.value = formatDate(datePicked)
        showCalendar.value = false
        emit('date-selected');
      }
    }

    const updateValue = (event) => {
      // Obtenemos el valor del input
      let fecha = event.target !== undefined && event.target.value || event

      if (fecha.length < 8) {
        inputDateValue.value = fecha
        date.value.day = undefined
        emit('update:modelValue', null)
        return
      }

      // Quitamos los caracteres que no sean números
      fecha = fecha.replace(/\D/g, '');

      // Formateamos la fecha
      fecha = fecha.substr(0, 2) + '/' + fecha.substr(2, 2) + '/' + fecha.substr(4);

      if (fecha.length === 10) {
        const newArray = fecha.split('/')
        if ((parseInt(newArray[1]) - 1) > 11 || (parseInt(newArray[0])) > 31 || (parseInt(newArray[0])) < 1) {
          inputDateValue.value = ''
          return
        }
        if (props.from && new Date(parseInt(newArray[2]), parseInt(newArray[1]) - 1, parseInt(newArray[0])) < props.from) {
          inputDateValue.value = ''
          return
        }
        if (props.to && new Date(parseInt(newArray[2]), parseInt(newArray[1]) - 1, parseInt(newArray[0])) > props.to) {
          inputDateValue.value = ''
          return
        }
        date.value.day = parseInt(newArray[0])
        date.value.month = parseInt(newArray[1]) - 1
        date.value.year = parseInt(newArray[2])
        pickDate(date.value)
        inputDateValue.value = fecha
      }
    }
    // Si el valor cambia y es diferente a nulo, formateara y asi mostrara correctamente al usuario 
    // Usado para cuando ya viene un valor preestablecido
    watch(() => props.modelValue, newValues => {
      if (newValues !== null) {
        let fecha = moment(newValues)
        inputDateValue.value = fecha.format('DD/MM/YYYY')
      }
    });

    onMounted(() => {
      if (props.modelValue) {
        const value = moment(props.modelValue)
        date.value.day = value.date()
        date.value.month = value.month()
        date.value.year = value.year()
      }
      else {
        const now = new Date()
        date.value.month = now.getMonth()
        date.value.year = now.getFullYear()
      }
      if (props.modelValue) inputDateValue.value = moment(props.modelValue).format('DD/MM/YYYY')
      if (props.from) {
        fromDate.value.day = props.from.getDate()
        fromDate.value.month = props.from.getMonth()
        fromDate.value.year = props.from.getFullYear()
      }
      if (props.to) {
        toDate.value.day = props.to.getDate()
        toDate.value.month = props.to.getMonth()
        toDate.value.year = props.to.getFullYear()
      }
    })


    return {
      showCalendar,
      calendarFormat,
      getCalendarNumbers,
      date,
      pickDate,
      inputDateValue,
      whatViewInDiv,
      changeView,
      getDecade,
      isValidDate,
      placeholder,
      updateValue,
      changeDataInView,
      datepicker,
      formatDate,
      pickMonth,
      pickYear,
      decade
    }
  }
}
</script>

<style lang="scss" scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.input {
  position: relative;
  width: 100%;

  &__supportText {
    font-weight: 600;
    font-size: 10px;
    color: $black;
    padding: 0 28px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;

    .material-icons {
      font-size: 16px;
    }
  }

  &__input {
    width: 100%;
    background: #f5f5f5;
    padding: 18px 28px 6px 28px;
    border: none;
    outline: none;
    border-radius: 50px;
    font-weight: 400;
    line-height: 25px;
    transition: all .2s;
    font-size: 0;

    &--completed {
      @extend .input__input;
      font-size: 13px;
    }

    &:focus+.input__label {
      transform: translateY(3px);
      font-size: 10px;
      color: #009696;

      .input__optional {
        font-size: 10px;
      }
    }

    &:focus {
      font-size: 13px;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &--error {
      @extend .input__input;
      outline: 1px solid $red;

      &:focus+.input__label {
        transform: translateY(3px);
        font-size: 10px;
        color: $red;
      }
    }

    &:disabled+.input__label {
      opacity: .6;
      font-weight: 600;
    }

    &--date {
      font-size: 0;

      &:focus {
        font-size: 13px;
      }
    }
  }

  &__date_icon {
    position: absolute;
    right: 28px;
    top: 14px;
    cursor: pointer;
    z-index: 9;
  }

  &__years {
    &_grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(42px, 1fr));
      grid-template-rows: repeat(3, minmax(42px, 1fr));
      font-size: 12px;
      text-align: center;
      align-items: center;
      justify-items: center;

      span {
        cursor: pointer;
        padding: 8px;
        border-radius: 999px;
        width: 100%;

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  &__calendar {
    min-width: 161px;
    width: max-content;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 16px 14px;
    position: absolute;
    z-index: 999;
    left: 25px;

    &_selectMonthAndYear {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 10px;
      font-size: 12px;

      span {
        font-weight: 700;
        padding: 6px 0;
        width: 155px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #ddd;
        }
      }

      .material-icons {
        cursor: pointer;
        user-select: none;
      }
    }

    &_grid {
      display: grid;
      grid-template-columns: repeat(7, minmax(21px, 1fr));
      grid-template-rows: repeat(5, minmax(21px, 1fr));
      font-size: 12px;
      text-align: center;
      align-items: center;
      justify-items: center;

      &--selected {
        background-color: #009696;
        color: #fff;

        &:hover {
          background-color: #009696 !important;
        }
      }

      &--day {
        font-weight: 700;
        cursor: default !important;

        &:hover {
          background-color: inherit !important;
        }
      }

      span {
        cursor: pointer;
        padding: 8px;
        border-radius: 999px;
        width: 100%;

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  &__label {
    cursor: text;
    position: absolute;
    top: 0;
    left: 28px;
    user-select: none;
    transform: translateY(12px);
    transition: transform .5s, color .3s, font-size .5s;
    line-height: 25px;
    font-size: 13px;

    &--completed {
      @extend .input__label;
      transform: translateY(3px);
      font-size: 10px;
      color: #009696;

      .input__optional {
        font-size: 10px;
      }
    }

    &--error {
      @extend .input__label;
      color: $red !important;
    }
  }

  &__errorMessage {
    font-weight: 600;
    font-size: 10px;
    color: $red;
    padding: 0 28px;
  }

  &__optional {
    font-size: 13px;
    font-style: italic;
    color: #7d7d7d;
    transition: font-size .5s;
  }
}</style>