import interceptor from '@/plugins/interceptor';
const pathController = '/seguimiento'

export default {
  actions: {
        async seguimiento_alumnosCurso(_, params) {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/cursos/${params.courseId}/alumnos?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`);
        },
        async seguimiento_alumnoLayout(_, id) {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/alumnos/${id}/layout`);
        },
        async seguimiento_modulos(_, params) {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/alumnos/${params.alumnoId}/modulos/${params.moduloCode}?tipo=${params.type}`);
        },
        async seguimiento_detalleCompetencia(_, params) {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/alumnos/${params.alumnoId}/competencias/${params.name}`);
        },
    }
}
