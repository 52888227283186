import persistentData from "@/plugins/persistentData";
import moment from "moment";
import { useRouter, useRoute } from 'vue-router';

let intervalRefreshToken = null;

export default {
  state: () => ({
    logged: false,
    user: {},
    course: null
  }),
  getters: {
    manageUser_logged: state => state.logged,
    manageUser_user: state => state.user
  },
  
  mutations: { 
    manageUser_setLogged(state, value) {
      state.logged = value;
    },
    manageUser_setUser(state, value) {
      state.user = value;
    },
    setCourse(state, courseId) {
      state.course = courseId
    },
    removeCourse(state) {
      state.course = null
    }
  },
  actions: {
    manageUser_active({ commit, dispatch }) {
      if (persistentData.get('token')) {
        // Validate tokenExpiration
        const validateToken = async function() {
          const expiredToken = moment(persistentData.get('tokenExpiration')).diff(moment());
          if (expiredToken < 0) {
            try {
              const request = await dispatch('security_refreshToken', {value: persistentData.get('token')});
              if (request.status === 200) {
                persistentData.set('token', request.data.token);
                persistentData.set('tokenExpiration', request.data.expirationTime);
              } else {
                dispatch('manageUser_disabled');
              }
            } catch (error) {
              dispatch('manageUser_disabled');
            }
          }
        }

        validateToken();
        if (intervalRefreshToken) clearInterval(intervalRefreshToken)
        
        intervalRefreshToken = setInterval(() => {
          validateToken();
        }, 10000);
        
        const user = JSON.parse(persistentData.get('user'))
        commit('manageUser_setLogged', true);
        commit('manageUser_setUser', user);
        let lang = (user.userData.PaisCodigo && user.userData.PaisCodigo.toUpperCase() == 'BR'?"PT":'ES');
        lang = lang.toLowerCase();

        persistentData.set('language',lang );

        window.i18n.global.locale.value = lang;
      } 
      else {
        const router = useRouter();
        const route = useRoute();
        setTimeout(() => {
          if(route.name != 'USER_REGISTRATION')
            router.push('/login')
        },0)
      }
    },
    manageUser_disabled({ commit }) {
      persistentData.remove('token');
      persistentData.remove('tokenExpiration');
      persistentData.remove('user');
      if (intervalRefreshToken) clearInterval(intervalRefreshToken);
      commit('manageUser_setLogged', false);
      commit('removeCourse')
    },
    manageUser_defineOrganization(_,organizationId) {
      persistentData.set('organizationId', organizationId);
    }
  }
}