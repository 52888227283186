import interceptor from '@/plugins/interceptor';
const pathController = '/SolicitudDocumentacion'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async documentation_requests() {
      if(process.env.OFFLINE_MODE == 'enabled')
      {
        return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/solicitudes`)
        /* return {
          status: 200,
          data: [
          {   
            id: 1,
            fechaVencimiento  : "17/20/2021",
            titulo: "Foto de ambos lados del DNI",
            estado:"PENDIENTE",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
              id: 17,
              estado: "PENDIENTE",
              titulo: "ANARCO",
              about: "Adipisicing id nostrud Lorem magna sint commodo. Dolore nulla occaecat excepteur consectetur ut sint dolor. Pariatur minim esse qui anim cillum sint cupidatat cillum eiusmod elit ullamco ex adipisicing. Fugiat dolore aliqua sint velit elit dolore sint nulla.\r\n",
              fechaVencimiento: "Thu Dec 25 1980"
            },
            {
              id: 13,
              estado: "EN PROCESO",
              titulo: "DADABASE",
              about: "Ipsum duis cupidatat amet nisi minim sint culpa est consectetur. Esse nostrud eu quis cillum in pariatur. Minim commodo cupidatat eu aliqua ad ad nisi amet est. Reprehenderit reprehenderit ea anim ad culpa ex nisi in ad ipsum nostrud voluptate. Do aliquip nisi commodo in deserunt id non qui eiusmod laborum cupidatat quis. Lorem mollit laborum ad mollit eu reprehenderit ipsum. Magna sit dolor nulla mollit sit quis fugiat non cillum pariatur aliqua amet sint dolor.\r\n",
              fechaVencimiento: "Fri Feb 11 2005"
            },
            {
              id: 8,
              estado: "EN PROCESO",
              titulo: "PAWNAGRA",
              about: "Aute occaecat cillum occaecat minim tempor et nisi. Quis excepteur incididunt anim et. Consectetur laboris pariatur et sunt elit adipisicing. Mollit reprehenderit aute aliquip consectetur amet Lorem non irure occaecat. Quis minim in irure deserunt culpa officia. Cillum nisi nisi mollit do officia ex aliqua non.\r\n",
              fechaVencimiento: "Mon Dec 27 1993"
            },
            {
              id: 23,
              estado: "APROBADO",
              titulo: "TERSANKI",
              about: "Officia duis irure exercitation veniam Lorem aliquip cupidatat consequat laboris. Ex laborum aliquip aute velit officia aliqua commodo dolore minim reprehenderit. Cillum ad Lorem culpa veniam ullamco. Excepteur eu exercitation cillum dolor tempor anim deserunt sint amet consectetur. Excepteur veniam ea cillum anim enim minim. Dolore anim laborum pariatur sit veniam cillum aute.\r\n",
              fechaVencimiento: "Mon Oct 11 1993"
            },
            {
              id: 7,
              estado: "APROBADO",
              titulo: "TEMORAK",
              about: "Elit sint laborum reprehenderit tempor nisi exercitation reprehenderit. Incididunt exercitation ipsum nostrud ullamco aliquip mollit adipisicing in. Exercitation incididunt incididunt ad fugiat consectetur irure reprehenderit et. Et ex excepteur aliqua ea nisi ipsum. Nostrud proident in exercitation ipsum.\r\n",
              fechaVencimiento: "Sun Aug 23 1992"
            },
            {
              id: 25,
              estado: "PENDIENTE",
              titulo: "ILLUMITY",
              about: "Et nulla aute aliquip aute laboris excepteur officia. Et consequat non ipsum eiusmod ad esse pariatur. Ipsum aliqua exercitation minim consectetur duis et.\r\n",
              fechaVencimiento: "Mon Feb 25 2013"
            },
            {
              id: 16,
              estado: "PENDIENTE",
              titulo: "DAYCORE",
              about: "Dolore do irure id cillum aliqua id. Sunt magna exercitation magna velit in occaecat amet esse commodo officia consectetur pariatur incididunt. Nulla occaecat exercitation exercitation deserunt consequat non labore enim proident laboris. Exercitation est aliquip nulla irure eu id minim.\r\n",
              fechaVencimiento: "Tue Nov 18 2014"
            },
            {
              id: 7,
              estado: "APROBADO",
              titulo: "ACUSAGE",
              about: "Ad voluptate sint exercitation nostrud minim culpa non occaecat in culpa. Laborum ea cillum dolor laborum veniam sunt consectetur magna. Esse irure veniam laboris consectetur amet mollit enim tempor cupidatat duis incididunt sunt.\r\n",
              fechaVencimiento: "Thu Apr 15 1993"
            },
            {
              id: 4,
              estado: "EN PROCESO",
              titulo: "SATIANCE",
              about: "Enim anim est officia ipsum amet dolor reprehenderit laborum ea pariatur deserunt elit enim cillum. Excepteur proident duis velit reprehenderit laborum est irure exercitation fugiat aute officia. Et qui adipisicing sunt cupidatat cillum adipisicing. Dolor anim cupidatat dolor quis commodo tempor.\r\n",
              fechaVencimiento: "Thu Aug 29 2002"
            },
            {
              id: 11,
              estado: "PENDIENTE",
              titulo: "BIOLIVE",
              about: "Consequat nostrud consectetur sit anim culpa qui ex proident anim cillum. Adipisicing proident ullamco amet commodo eu labore consectetur. Eu quis officia dolor non cillum magna eiusmod occaecat irure adipisicing ut. Exercitation fugiat pariatur laborum non fugiat labore do amet deserunt amet proident sint. Consequat ea excepteur proident esse enim mollit tempor cillum consequat consequat sit culpa ex sunt.\r\n",
              fechaVencimiento: "Sat May 25 1996"
            },
            {
              id: 7,
              estado: "EN PROCESO",
              titulo: "CORPULSE",
              about: "Lorem elit qui amet est do non excepteur occaecat sint. Aliquip esse ex officia officia nisi fugiat est amet velit. Exercitation ea esse ipsum cupidatat. Fugiat Lorem consequat quis deserunt sint ad Lorem sint magna do et Lorem exercitation. Et esse cillum cillum aliqua.\r\n",
              fechaVencimiento: "Mon Apr 11 2011"
            },
            {
              id: 10,
              estado: "EN PROCESO",
              titulo: "ENERFORCE",
              about: "Nulla quis fugiat ut do enim consectetur cupidatat nostrud dolor eiusmod eiusmod. Velit do quis irure reprehenderit sunt qui velit sint tempor consequat irure. Consectetur cillum eu cillum duis fugiat elit fugiat id enim.\r\n",
              fechaVencimiento: "Thu Mar 10 1994"
            },
            {
              id: 17,
              estado: "PENDIENTE",
              titulo: "EARBANG",
              about: "Id exercitation labore irure veniam voluptate labore proident commodo occaecat eiusmod non. Amet reprehenderit sit nisi labore nisi ullamco amet cillum deserunt est anim. Lorem elit cillum amet esse laboris Lorem duis ea elit eu cillum. Lorem dolore cillum ea eiusmod quis aute pariatur dolor duis duis. Proident id consectetur nostrud mollit culpa in in eiusmod ut sunt sit. Veniam ullamco dolor mollit sit in deserunt culpa consectetur consectetur.\r\n",
              fechaVencimiento: "Wed Jan 03 1996"
            },
            {
              id: 16,
              estado: "EN PROCESO",
              titulo: "DANCITY",
              about: "Consequat consequat ad sint enim adipisicing ad pariatur consequat ea incididunt Lorem magna ut. Nisi labore laboris ut nostrud et cupidatat. Aliqua sunt dolor sit pariatur magna consectetur proident. Pariatur irure cillum pariatur laborum dolor nostrud mollit aliqua enim ullamco mollit. Enim aliqua occaecat sit aliqua culpa magna ipsum.\r\n",
              fechaVencimiento: "Wed Aug 29 1973"
            },
            {
              id: 10,
              estado: "PENDIENTE",
              titulo: "XINWARE",
              about: "Commodo sit enim laborum aliqua eiusmod eu ut labore consequat ullamco. Nisi exercitation officia commodo dolore eu laboris irure ipsum dolore. Nisi officia amet nisi velit nostrud et ex dolore. Ad nulla veniam ut est do ea eu. Amet cillum excepteur fugiat proident est. Officia aliquip mollit consequat aliqua ad deserunt et cupidatat irure labore pariatur commodo aute non.\r\n",
              fechaVencimiento: "Thu Mar 03 2011"
            }
          ]
        } */
      }
      else
      {
        return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/solicitudes`);
      }
    }
  }
}