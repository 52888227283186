<template>
  <div class="workshopcard" :class="{'slotCard' : data.Estado == 'slot'}" v-if="data">
    <template v-if="data.Estado == 'slot'">
      <h2 class="workshopcard__title">{{data.Titulo}}</h2>
      <p>{{data.Descripcion}}</p>
      <span class="workshopcard__type" 
        :style="[{'color': data.Categoria == 'Trabajo' ? '#4833E9' : '#FF3B66'},{'border' :  data.Categoria == 'Trabajo' ? '1px solid #4833E9' : '1px solid #FF3B66'}]">
        {{data.Categoria}}
      </span>
    </template>

    <template v-else>
      <div class="workshopcard__img" :style="{'background-color' : !data.Imagen ? '#D9D9D9' : ''}">
        <span class="workshopcard__type" :style="{'color': data.Categoria == 'Trabajo' ? '#4833E9' : '#FF3B66'}">{{data.Categoria}}</span>
        <span v-if="props.obligatorio" class="workshopcard__type" style="color:#00C58F; left:90px;">{{ t('dashboard.journey.workshopCard.obligatory') }}</span>
        <img v-if="data.Imagen" :src="`${env.VUE_APP_API_TALLERES_IMAGENES}/${data.Imagen}`" alt="">
      </div>
      <h2 class="workshopcard__title">
        {{data.Titulo}} 
        <span v-if="data.Estado == 'COMPLETADO'" style="color:#00C58F">{{ t('dashboard.journey.workshopCard.complete') }}<i class="material-icons">check_circle</i></span>
        <span v-if="data.Estado == 'INCOMPLETO'" style="color:#FF3B66">{{ t('dashboard.journey.workshopCard.incomplete') }}<i class="material-icons">highlight_off</i></span>
      </h2>
      <h2 v-if="props.obligatorio" class="workshopcard__description">{{data.Descripcion}}</h2>
      
      <div class="select-date" v-if="data.Estado == 'DISPONIBLE' && !props.obligatorio">
        <span @click="getDates()">
          <template v-if="selectDate.selected">{{selectDate.selected.Fecha}}</template>
          <template v-else>{{ t('dashboard.journey.workshopCard.selectDayAndTime') }}<i class="material-icons">expand_more</i></template>
        </span>
        <ul class="select-date__options" v-if="selectDate.showDates">
          <li v-for="(date,idx) in selectDate.dates" :key="idx" @click="selectDate.selected = date; selectDate.showDates = false">{{date.Fecha}}</li>
        </ul>
        <ul v-if="selectDate.loading" class="select-date__loading">{{ t('dashboard.journey.workshopCard.loadingDates') }}</ul>
      </div>
 
      <span v-if="data.Estado == 'LLENO'" class="workshopcard__lleno">
        {{ t('dashboard.journey.workshopCard.maxCapacityReached') }}
      </span>

      <span v-if="data.Estado == 'VENCIDO'" class="workshopcard__lleno">
        {{ t('dashboard.journey.workshopCard.noMoreDates') }}
      </span>

      <span v-if="data.Estado == 'RESERVADO'" class="pill-reservado">{{ t('dashboard.journey.workshopCard.reservedPlace') }}</span>

      <span v-if="data.Estado == 'RESERVADO' || data.Estado == 'COMPLETADO' || data.Estado == 'INCOMPLETO'" class="workshopcard__date"
        :style="{color : data.Estado == 'INCOMPLETO' ? '#FF3B66' : ''}"
      >
        {{data.Fecha}}
      </span>

      <div class="workshopcard__buttons" v-if="(data.Estado == 'DISPONIBLE' || data.Estado == 'RESERVADO') && !props.obligatorio">
        <button @click="modalDetail = true">
          {{ t('dashboard.journey.workshopCard.viewDetail') }}
        </button>
        <button @click="modalConfirm = true" :disabled="data.Estado == 'DISPONIBLE' && !selectDate.selected" :class="{'reservado': data.Estado == 'RESERVADO'}">
          {{ data.Estado == 'DISPONIBLE' ? t('dashboard.journey.workshopCard.bookPlace') : t('dashboard.journey.workshopCard.releasePlace') }}
        </button>
      </div>

      <button v-else @click="modalDetail = true" class="workshopcard__btn-detail" >
        {{ t('dashboard.journey.workshopCard.viewDetail') }}
      </button>
    </template>

  </div> <!-- /workshopcard -->

  <div class="overlay" v-if="modalConfirm">
    <div class="modal-confirm">
      <p>{{ t('dashboard.journey.workshopCard.confirmModal.youWish') }} {{data.Estado == 'DISPONIBLE' ? t('dashboard.journey.workshopCard.confirmModal.reserve') : t('dashboard.journey.workshopCard.confirmModal.release') }} {{ t('dashboard.journey.workshopCard.confirmModal.yourPlace') }}<br><i>{{data.Titulo}}</i><br> {{ t('dashboard.journey.workshopCard.confirmModal.ofTheDay') }} <i>{{data.Estado == 'DISPONIBLE' ? selectDate.selected.Fecha : data.Fecha}}</i> ?</p>
      <div class="modal-confirm__buttons">
        <button @click="modalConfirm = false">{{ t('dashboard.journey.workshopCard.confirmModal.cancel') }}</button>
        <button @click="data.Estado == 'DISPONIBLE' ? reservarLugar() : liberarLugar()">
          <!-- {{ data.Estado == 'DISPONIBLE' ? 'Reservar lugar' : 'Liberar lugar' }} -->
          {{ data.Estado == 'DISPONIBLE' ? t('dashboard.journey.workshopCard.bookPlace') : t('dashboard.journey.workshopCard.releasePlace') }}
        </button>
      </div>
    </div>
  </div>

  <div class="overlay" v-if="modalDetail">
    <div class="modal-detail">
      <i class="material-icons close" @click="modalDetail = false">close</i>
      <div class="video-container">
        <iframe width="560" height="315" frameborder="0" :src="data.Media"></iframe>
      </div>
      <h3>{{data.Titulo}}</h3>
      <h4>{{data.Descripcion}}</h4>
      <div class="link-zoom" v-if="data.Estado == 'RESERVADO' && data.Link">
        <h3>Zoom <span>{{ t('dashboard.journey.workshopCard.rememberDate') }}</span></h3>
        <a :href="data.Link" target="_blank">{{ data.Link }}</a>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import FormatDate from '@/plugins/formatDate.js'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const env = process.env;
const props = defineProps({
  data: Object,
  desde: String,
  hasta: String,
  obligatorio: Boolean,
})
const emit = defineEmits(['reload']);

const data = ref(props.data);
const selectDate = ref({
  showDates: false,
  dates: [],
  selected: null,
  loading: false
})

async function getDates(){
  if(selectDate.value.showDates){
    selectDate.value.showDates = false;
    selectDate.value.selected = null;
    return;
  }
  else{
    selectDate.value.loading = true;
    try {
      let req = await store.dispatch('nivelCinco_talleres_fechas', { IdDefinicion: data.value.IdDefinicion, desde: props.desde, hasta: props.hasta });
      selectDate.value.dates = req.data.Fechas;
      selectDate.value.dates.forEach( date => {
        date.Fecha = FormatDate(date.Fecha, t);
        date.Fecha += ` (${date.CodigoPais})`
      });
      selectDate.value.showDates = true;
      selectDate.value.loading = false;
    } catch (error) {
      console.log(error)
    }
  }
}

const modalConfirm = ref(false);
const modalDetail = ref(false);

async function reservarLugar(){
  try {
    await store.dispatch('nivelCinco_talleres_reservar',selectDate.value.selected.IdTallerInstancia);
  } catch (error) {
    console.log(error);
    store.dispatch("notifications_create", { text: t('dashboard.journey.workshopCard.errorReserve') });
  } finally {
    modalConfirm.value = false;
    emit('reload');
  }
}
async function liberarLugar(){
  try {
    await store.dispatch('nivelCinco_talleres_liberar',data.value.IdInscripcion);
  } catch (error) {
    console.log(error);
    store.dispatch("notifications_create", { text: t('dashboard.journey.workshopCard.errorRelease') });
  } finally {
    modalConfirm.value = false;
    emit('reload');
  }
}

/*
onMounted(()=>{
  data.value = null;
  data.value = props.data;
   if(data.value.Fecha){
    data.value.Fecha = FormatDate(data.value.Fecha, t);
    data.value.Fecha += ` (${data.value.CodigoPais})`
  } 
})
*/
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.modal-confirm,.modal-detail{
  width: 100%;
  margin: 0 1%;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 5px 5px 35px 0px rgba(217, 217, 217, 0.50);
}
.modal-detail{
  position: relative;
  max-width: 780px;
  padding: 3%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .close{
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 26px;
    cursor: pointer;
  }
  .video-container {
    width: 100%;
    height: fit-content;
    iframe {
      width: 100%;
      //height: auto;
      overflow-y: visible !important;
    }
  }
  h3{
    font-size: 18px;
    font-weight: 700;
  }
  h4{
    font-size: 16px;
    font-weight: 400;
  }
  .link-zoom{
    h3{
      margin: 6px 0;
      display: flex;
      align-items: center;
      gap: 6px;
      span{
        padding: 4px;
        padding-bottom: 3px;
        color: $lightblue;
        font-size: 10px;
        font-weight: 400;
        background-color: rgba(72, 51, 233, 0.05);
        border-radius: 4px;
      }
    }
    a{
      display: flex;
      //align-items: center;
      gap: 4px;
      font-size: 15px;
      font-weight: 400;
      text-decoration: underline;
    }
  }
}
.modal-confirm{
  max-width: 480px;
  padding: 32px;
  p{
    padding-bottom: 26px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    i{
      font-weight: 700;
      color: $lightblue;
    }
  }
  &__buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 44px;
    button{
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      &:last-child{
        height: 45px;
        padding: 0 29px;
        border-radius: 44px;
        background: #FF3B66;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        color: #fff;     
      }
    }
  }
}

.workshopcard{
  width: 100%;
  max-width: 340px;
  min-width: 310px;
  max-height: 305px;
  min-height: 305px;
  padding: 10px;
  padding-bottom: 21px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
  &__type{
    position: absolute; 
    top: 9px; 
    left: 6px; 
    width: 73px; 
    padding: 5px 10px;
    background-color: #fff; 
    border-radius: 50px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }
  &__img{
    width: 100%;
    height: 105px;
    border-radius: 4px;
    position: relative;
    img{
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
  &__title{
    display: flex;
    justify-content: space-between;
    //align-items: center;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
    span{
      display: flex;
      width: 92px;
      height: 20px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 4px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
      font-size: 10px;
      font-weight: 600;
      .material-icons{
        font-size: 12px;
      }
    }
  }
  &__description{
    //width: 200px; /* Ancho fijo del contenedor */
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Número de líneas a mostrar */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
  }
  &__content{
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  &__date{
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: $lightblue;
  }
  .pill-reservado{
    width: fit-content;
    padding: 5px 10px;
    background-color: #fff; 
    border-radius: 50px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    background: rgba(0, 197, 143, 0.10);
    color: $green;
  }
  .select-date{
    position: relative;
    span{
      display: flex;
      width: 100%;
      padding: 12px 26px;
      justify-content: space-between;
      align-items: center;
      border-radius: 50px;
      background: #F6F6F6;
      font-size: 13px;
      font-weight: 700;
      cursor: pointer;     
    }
    ul{
      position: absolute;
      top: 52px;
      width: 100%;
      padding: 4px 8px;
      background: #F6F6F6;
      border-radius: 12px;
      z-index: 1;
      li{
        padding: 1px 0;
        margin: 2px 0;
        font-size: 13px;
        cursor: pointer;
      }
    }
    &__loading{
      font-size: 13px;
    }
  }
  &__btn-detail{
    border-radius: 44px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    color: $lightblue;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 10px 0;
    width: 100% !important;
    font-size: 14px;
    font-weight: 600;
    margin-top: 8px;
  }
  &__buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;
    button{
      width: 147px;
      font-weight: 600;
      font-size: 14px;
      &:first-child{
        color: $lightblue;
        text-decoration-line: underline;
      }
      &:last-child{
        background-color: $lightblue;
        color: #fff;
        border-radius: 44px;
        padding: 10px 0;
        &.reservado{
          background: #fff;
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
          color: $lightblue;
        }
      }
      &:disabled{
        cursor: default;
      }
    }
  }
  &__lleno{
    width: 100%;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    color: #00000033;
    height: 40px;
    display: flex;
    align-items: center;
  }
  &.slotCard{
    justify-content: center;
    align-items: center;
    gap: 24px;
    opacity: .6;
    .workshopcard__type{
      position: static;
    }
  }
}
</style>