<template>

    <div class="module-box">
        <h4>{{ $t('progress.boxEquipo.title') }}</h4>
        <div v-if="!reqError">
            <template v-if="data">
                <div class="evaluacion" v-for="(level, levelIdx) in data.Niveles" :key="levelIdx">
                    <div class="reference" >
                        <i class="material-icons">grading</i>
                        <span>{{ $t('progress.boxEquipo.recordsLevel') + ` ${level.Nivel}` }}</span>
                    </div>
      
                    <div class="data">
                        <span class="data__empty" v-if="level.Cantidad == null">{{ $t('progress.fromLevel') }} {{ level.Nivel }}</span>
                        <span v-else>{{ level.Cantidad }}</span>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="evaluacion" v-for="num in 2" :key="num">
                    <div class="reference">
                        <Skeletor height="23" width="23" />
                        <Skeletor height="16" width="134" />
                        <Skeletor height="9" width="9" />
                    </div>
      
                    <div class="data">
                        <Skeletor width="60" height="49" />
                    </div>
                </div>
            </template>
            
            <!-- <span class="show-modal"
                @click="modalDetail.render = true; modalDetail.show = true;">
                {{ $t('progress.boxCompetencias.viewResults') }}
            </span> -->

            <span v-if="data" class="show-modal" :style="data.PuedeVerDetalle ? 'cursor:pointer; opacity:1' : 'cursor:default; opacity:.5;'"
                @click="data.PuedeVerDetalle ? (modalDetail.render = true, modalDetail.show = true) : ''">
                {{ $t('progress.boxCompetencias.viewResults') }}
            </span>
            <Skeletor v-else class="show-modal" width="84" height="16" />

        </div>
        <ErrorBox v-else />
    </div>

    <ModalDetail v-if="modalDetail.render"
        :show="modalDetail.show"
        :studentName="props.studentName"
        @close="modalDetail.show = false"
    />
    
</template>

<script setup>
import { onMounted, ref, defineProps, watch, onBeforeUnmount } from 'vue'; 
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ModalDetail from './ModalDetail.vue';
import ErrorBox from '../ReqError.vue';
import { Skeletor } from 'vue-skeletor';
import 'vue-skeletor/dist/vue-skeletor.css';

const props = defineProps({
    studentName: String
})

const store = useStore();
const route = useRoute();
const data = ref(null);
const reqError = ref(false);

const modalDetail = ref({
    render: false,
    show: false
});

watch(()=>modalDetail.value.show, ()=>{
    modalDetail.value.show
        ? (window.scrollTo({top: 0}), document.body.style.overflowY = "hidden")
        : document.body.style.overflowY = "auto";
})


async function getData(){
    try {
        let req = await store.dispatch('seguimiento_modulos', { alumnoId: route.params.alumnoId, moduloCode: 'EVALUACION_DE_COMPANEROS', type: 'GENERAL' })
        data.value = req.data;
    } catch (error) {
        console.log(error);
        reqError.value = true;
    }
}

onMounted(
    getData()
)
onBeforeUnmount(()=>{
    document.body.style.overflowY = "auto";
})

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.module-box{
    .evaluacion{
        .reference{
            i{
                background: #E9BC79; 
            }
        }
        .data{
            &__empty{
                padding: 4px 8px;
                font-size: 12px !important;
                font-weight: 700 !important;
                color: #BDBDBD;
                background: #F6F6F6;
                border-radius: 5px;
            }
        }
    }
}

</style>