<template>
  <div v-if="type === 'dots'" class="dots"></div>
  <div v-else class="loader__container">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {
  props: ['type'],
  setup() {

  },
}
</script>

<style lang="scss" scoped>
.loader__container {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    width: 14.4px;
    height: 14.4px;
    border-radius: 14.4px;
    box-shadow: 36px 0px 0 0 rgba(125, 125, 125, 0.2), 29.2px 21.2px 0 0 rgba(125, 125, 125, 0.4), 11.16px 34.2px 0 0 rgba(125, 125, 125, 0.6), -11.16px 34.2px 0 0 rgba(125, 125, 125, 0.8), -29.2px 21.2px 0 0 #7d7d7d;
    animation: spinner-b87k6z 1.2s infinite linear;
  }

  @keyframes spinner-b87k6z {
    to {
      transform: rotate(360deg);
    }
  }

}

.dots {
  width: 56px;
  height: 13.4px;
  background: radial-gradient(circle closest-side, #7d7d7d 90%, #0000) 0% 50%,
    radial-gradient(circle closest-side, #7d7d7d 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, #7d7d7d 90%, #0000) 100% 50%;
  background-size: calc(100%/3) 100%;
  background-repeat: no-repeat;
  animation: dots-zcf63l 1s infinite linear;
}

@keyframes dots-zcf63l {
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%;
  }

  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%;
  }

  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%;
  }
}
</style>