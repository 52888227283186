<template>
  <div class="page-content full center">
    <div class="card full">
      <div class="card__head full rows">
        <div class="top rows full center">
          <div class="rows full">
            <img src="@/assets/icons/pen.svg" alt="" />
            <h3>Solicitud de carta</h3>
            <div class="full right-c">
              <router-link to="/requestedLetters">
                <img src="@/assets/icons/x-black.svg" alt="" class="pointer" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="card__content full">
        <select
          name="cartas"
          id="cartas"
          v-model="selectedForm"
          class="dropdown full"
        >
          <option disabled selected hidden>Seleccione una opcion...</option>
          <option
            v-for="option in lettersRequests"
            :key="option.Id"
            :value="option"
          >
            {{ option.Nombre }}
          </option>
        </select>
        <div v-if="!selectedForm" class="tittle full center">
          <span class="">
            Una vez seleccionada alguna opcion se mostraran los campos a
            completar
          </span>
        </div>
        <div v-else class="tittle full center">
          <span class="full"> Por favor, completa los siguientes datos </span>

          <Form
            v-if="selectedForm"
            :selectedForm="selectedForm"
            @send="sendData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Form from "@/components/Common/Form.vue";

import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default {
  components: {
    Form,
  },
  data() {
    return {
      selectedForm: null,
    };
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const loading = ref(false);
    const lettersRequests = ref([]);

    onMounted(async () => {
      const request = await store.dispatch("letters_configuraciones");
      if (request.status === 200) {
        lettersRequests.value = request.data;
      } else {
        store.dispatch("notifications_create", {
          text: t(`notifications.${request.data}`),
        });
        loading.value = false;
      }
    });

    function sendData(data) {
      data["ConfiguracionId"] = data["Id"];
      console.log(data);
      const request = store.dispatch("letters_solicitud", data);
      console.log(request);
      store.dispatch("notifications_create", {
        text: "Se ha enviado la solicitud",
      });
      /* router.go(-1) */
      router.push("/RequestedLetters");
    }
    return {
      lettersRequests,
      sendData,
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__content {
    padding: $margin-milli $margin-milli 0;
  }
}
.rows {
  h3 {
    min-width: 120px;
  }
}
</style>