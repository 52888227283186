<template>
  <div class="card full">
    <div class="card__head full rows">
      <div class="top rows full center">
        <div
          v-if="docRequest.EstadoCodigo.toUpperCase() == 'PENDIENTE'"
          class="rows"
        >
          <img src="@/assets/icons/pendding.svg" alt="" />
          <h3>Pendiente</h3>
        </div>
        <div
          v-if="docRequest.EstadoCodigo.toUpperCase() == 'ENPROCESO'"
          class="rows full"
        >
          <img src="@/assets/icons/clock.svg" alt="" />
          <h3>En proceso</h3>
        </div>
        <div
          v-if="docRequest.EstadoCodigo.toUpperCase() == 'APROBADO'"
          class="rows full"
        >
          <img src="@/assets/icons/folder-check.svg" alt="" />
          <h3>Aprobado</h3>
        </div>
        <div
          v-if="docRequest.EstadoCodigo.toUpperCase() == 'PENDIENTE'"
          class="timeleft full"
        >
          <span class="timeleft__text red-font"
            >Vence: {{ docRequest.FechaLimite }}</span
          >
        </div>
      </div>
    </div>
    <div class="card__content full">
      <div class="tittle full center">
        <span class="">{{ docRequest.Nombre }}</span>
      </div>
      <div class="indications columns">
        <span class="indications__text">
          {{ docRequest.Comentario }}
        </span>
      </div>
    </div>
    <div class="card__bottom full rows">
      <div></div>
      <router-link :to="`/Files/Details/${docRequest.Id}`">
        <div class="btn btn-small pointer center">Ver</div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    docRequest: Object,
  },
  setup() {
    return {};
  },
};
</script>


<style lang="scss" scoped>
</style>