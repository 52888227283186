<template>
  <div class="present_info" :class="status" v-if="status">
    <div class="present_info__description">
      <i class="material-icons">{{ status === 'finished' ? 'alarm_off' : 'schedule' }}</i>
      <div>
        <strong>
          Tu clase de hoy
          {{ status == 'comming' ? 'comenzará en breve' : status == 'started' ? 'ha comenzado' : status === 'finished' ? 'ha finalizado' : 'ha comenzado hace un rato' }}
        </strong><br>
        <span v-if="status == 'comming'">Recuerda dar el presente una vez iniciada la clase.</span>
        <span v-if="status == 'started'">Recuerda dar el presente para visualizar los contenidos a trabajar en la clase.</span>
        <span v-if="status == 'already_started'">Recuerda dar el presente dentro de los 10 minutos de iniciada la clase.</span>
        <span v-if="status == 'finished'">Recuerda completar las actividades que tu facilitador indicó en clase.</span>
      </div>
    </div>
    <button :disabled="isLoading" class="btn" :class="{'present': isPresent, 'presentDisabled': isLoading }" v-if="status != 'comming' && status !== 'finished'" @click="present">{{ isPresent ? 'Estoy Presente' : 'Dar el presente' }} <i class="material-icons" v-if="present">done</i></button>
  </div>
</template>
<script>
import { watch } from 'vue';


export default {
  props: {
    status: {
      type: String,
    },
    givePresent: {
      type: Function
    },
    isPresent: {
      type: Boolean
    },
    isLoading: {
      type: Boolean
    }
  },
  setup(props) {
    const present = () => props.givePresent()
    watch(() => props.isPresent, () => props.isPresent)

    return {
      present
    }
  }
}
</script>
<style lang="scss" scoped>

  .presentDisabled{
    opacity: .6;
    cursor: default;
  }
  .present_info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 38px;
    margin: 12px 0;
    border-radius: 5px;
    @media (max-width: $break-sm){
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      >button{
        align-self: center;
      }
    }
    
    &.comming, &.already_started {
      background: #edebfd;

      i, strong {
        color: #4833e9;
      }
    }
    &.finished {
      background: rgba(246, 46, 83, 0.15);;

      i, strong {
        color: #F62E53;
      }
    }
    &.started {
      background: #d9f6ee;
      
      i, strong {
        color: #00c58f;
      }
    }
    
    &__description {
      display: flex;
      gap: 8px;

      * {
        font-size: 16px;
      }

      i {
        font-size: 20px;
        line-height: 22px;
      }

      span {
        font-weight: lighter;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      padding: 12px;
      width: 100%;
      max-width: 250px;
      @media (max-width: $break-sm){
        width: 100%;
      }

      &.present {
        background: #00c58f;
        color: white;
        gap: 8px;

        i {
          color: white;
          font-size: 16px;
        }
      }
    }
  }
</style>