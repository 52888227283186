<template>
  <Dashboard v-if="dataLoaded && !dataError" 
    :data="progresoData" 
    :selectedEtapa="selectedEtapa.Numero"
    @tutorial="modalTutorial = true"
    @changeEtapa="(etapaNumero) => selectedEtapa = data.Etapas.find(et => et.Numero == etapaNumero)"
  />
  <div class="recorrido breakpoints-container">
    <template v-if="dataLoaded && !changingEtapa">
      <header>
        <h3>{{ $t('dashboard.journey.level') }}</h3>
        <div class="info">{{ $t('dashboard.journey.eachStage') }}</div>
        <div class="tabs">
          <span v-for="(etapa,idx) in progresoData.Etapas" :key="idx" @click="selectedEtapa = data.Etapas[idx]" class="tabs__etapa" :class="{'-selected': selectedEtapa.Numero == etapa.Numero}">
            {{ $t('dashboard.journey.stage') }} {{etapa.Numero}}
          </span>
        </div>
        <div class="select-etapa">
          <span @click="selectedEtapa.showOptions ? selectedEtapa.showOptions = false : selectedEtapa.showOptions = true">{{ $t('dashboard.journey.stage') }} {{selectedEtapa.Numero}} <i class="material-icons">expand_more</i></span>
          <div class="select-etapa__options" v-if="selectedEtapa.showOptions">
            <span v-for="(etapa,idx) in progresoData.Etapas" :key="idx" @click="selectedEtapa = data.Etapas[idx]; selectedEtapa.showOptions = false">{{ $t('dashboard.journey.stage') }} {{etapa.Numero}}</span>
          </div>
        </div>
      </header>

      <section>

        <div class="etapa-label"><h3>{{ $t('dashboard.journey.stage') }} {{selectedEtapa.Numero}} </h3><span>{{formatDate(selectedEtapa.Desde)}} al {{formatDate(selectedEtapa.Hasta)}}</span></div>

        <h3>{{ $t('dashboard.journey.interview') }}</h3>

        <div class="entrevista">
          <span v-if="selectedEtapa.Entrevista.Estado == 'COMPLETO'" style="color:#00C58F">{{ $t('dashboard.journey.complete') }}<i class="material-icons">check_circle</i></span>
          <span v-if="selectedEtapa.Entrevista.Estado == 'INCOMPLETO'" style="color:#FF3B66">{{ $t('dashboard.journey.incomplete') }}<i class="material-icons">highlight_off</i></span>
          <span v-if="selectedEtapa.Entrevista.Estado == 'PENDIENTE'" style="color:#FFB600">{{ $t('dashboard.journey.pending') }}<i class="material-icons">highlight_off</i></span>
          <div>
            <h3>{{ $t('dashboard.journey.stage')}} {{selectedEtapa.Numero}}</h3>
            <h4>{{ $t('dashboard.journey.interview')}}</h4>
          </div>
          <button v-if="selectedEtapa.Entrevista.PuedeVerAgenda" @click="selectedEtapa.Entrevista.Url ? modalEntrevista = selectedEtapa : ''" :disabled="!selectedEtapa.Entrevista.Url">{{ $t('dashboard.journey.viewSchedule') }}</button>
        </div>

        <h3 v-if="selectedEtapa.EsDeTalleresObligatorios">{{ $t('dashboard.journey.mandatoryInitialWorkshops') }}</h3>
        <h3 v-else>{{ $t('dashboard.journey.workshops') }}</h3>

        <div class="info-box">
          <template v-if="selectedEtapa.Informacion == 'ABIERTO' && selectedEtapa.EsDeTalleresObligatorios">
            {{ $t('dashboard.journey.stageInfo.openMandatory') }}
          </template>
          <template v-else-if="selectedEtapa.Informacion == 'ABIERTO'">
            {{ $t('dashboard.journey.stageInfo.open', { count: selectedEtapa.CantidadTalleresRequerida, workshop: $t('dashboard.journey.workshop', selectedEtapa.CantidadTalleresRequerida) }) }}
          </template>
          <template v-else-if="selectedEtapa.Informacion == 'PENDIENTE'">
            {{ $t('dashboard.journey.stageInfo.pending') }}
          </template>
          <template v-else-if="selectedEtapa.Informacion == 'TRABAJANDO'">
            {{ $t('dashboard.journey.stageInfo.working') }}
          </template>
          <template v-else-if="selectedEtapa.Informacion == 'CERRADO'">
            {{ $t('dashboard.journey.stageInfo.closed') }}
          </template>
        </div>

        <div class="recorrido__cards" v-if="selectedEtapa.PuedeVerTalleres">
          <template v-for="(taller,tallerIdx) in selectedEtapa.Talleres" :key="tallerIdx">
            <WorkshopCard 
              :data="taller"
              :desde="selectedEtapa.Desde"
              :hasta="selectedEtapa.Hasta"
              :obligatorio="selectedEtapa.EsDeTalleresObligatorios"
              @reload="reload()"
            />
          </template>
        </div>
        
      </section>

      <div class="overlay" v-if="modalEntrevista">
        <div class="modal-entrevista">
          <strong>{{ $t('dashboard.journey.modalInterview.header') }}</strong>
          <p>{{ $t('dashboard.journey.modalInterview.body') }}</p>
          <div class="buttons">
            <button @click="modalEntrevista = null">{{ $t('dashboard.journey.modalInterview.cancel') }}</button>
            <a :href="modalEntrevista.Entrevista.Url" target="_blank"><button @click="modalEntrevista = null">{{ $t('dashboard.journey.modalInterview.continue') }}</button></a>
          </div>
        </div>
      </div>

      <div class="overlay" v-if="modalTutorial">
        <div class="modal-tutorial">
          <i class="material-icons close" @click="modalTutorial = false">close</i>
          <div class="video-container">
            <iframe height="315" frameborder="0" :src="tutorialUrl"></iframe>
          </div>
        </div>
      </div>
    </template>
    <Loader v-else-if="!dataError" />
    <div class="empty-state" v-if="dataError"><span>{{ $t('dashboard.emptyState') }}</span></div>
  </div>

</template>

<script setup>
import { onMounted, ref, inject, watch } from 'vue';
import WorkshopCard from '@/components/WorkshopCard.vue';
import { useStore } from 'vuex';
import Loader from '@/components/Loader.vue';
import Dashboard from '@/components/RecorridoDashboard/Dashboard.vue';
import customFormatDate from '@/plugins/formatDate.js'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = useStore();
const formatDate = inject('formatDate');
const isMobile = ref();
const progresoData = ref();
const data = ref();
const dataLoaded = ref(false);
const dataError = ref(false);
const selectedEtapa = ref(null);
const modalEntrevista = ref(false);
const modalTutorial = ref(false);
const tutorialUrl = ref('');
const changingEtapa = ref(false);

watch(selectedEtapa, ()=>{
  changingEtapa.value = true;
  setTimeout(()=>{
    changingEtapa.value = false;
  },1000)
})

async function getProgreso(){
  try {
    let req = await store.dispatch('nivelCinco_progreso');
    progresoData.value = req.data;
    if(!progresoData.value.Etapas.length){
      dataError.value = true;
      return
    }
  } catch (error) {
    console.log(error);
    store.dispatch('notifications_create', { text: 'Error al obtener datos del progreso'})
  }
}

async function getRecorrido(){
  dataLoaded.value = false;
  try {
    let req = await store.dispatch('nivelCinco_recorrido');
    data.value = req.data;
    if(!data.value.Etapas.length){
      dataError.value = true;
      return
    }
    else{
      data.value.Etapas.forEach(et => et.Talleres.forEach(taller => {
        if(taller.Fecha) taller.Fecha = customFormatDate(taller.Fecha, t) + ` (${taller.CodigoPais})`
      }))
      selectedEtapa.value = data.value.Etapas[0];
      dataLoaded.value = true;   
      if(data.value.EsPrimerLogin) modalTutorial.value = true;
    }
  } catch (error) {
    console.log(error);
    store.dispatch('notifications_create', { text: 'Error al obtener datos de recorrido'})
  }
}

const reload = async()=>{
  let scrollPosition = window.scrollY;
  await getProgreso();
  await getRecorrido();
  window.scrollTo(0, scrollPosition);
}

onMounted(async()=>{
  await getProgreso();
  await getRecorrido();
  let container = document.querySelectorAll('.recorrido__cards');
  container.forEach(cont => cont.scrollLeft = 80);
  isMobile.value = window.innerWidth < 1000;
  tutorialUrl.value = isMobile.value ? "https://view.genial.ly/65779f279736960014585f7b" : 'https://view.genial.ly/65779616619bca00158562ab';
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 1000 
    tutorialUrl.value = isMobile.value ? "https://view.genial.ly/65779f279736960014585f7b" : 'https://view.genial.ly/65779616619bca00158562ab';
  });
})

</script>

<style lang="scss" scoped>

.empty-state{
  margin: 56px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  span{
    padding: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $red;
    font-weight: 600;
    background: rgba(245, 112, 85, 0.3);;
    border-radius: 4px;
  }
}

.modal-tutorial{
  position: relative;
  max-width: 1420px;
  width: 100%;
  border-radius: 10px;
  overflow:hidden;
  background: #FFF;
  box-shadow: 5px 5px 35px 0px rgba(217, 217, 217, 0.50);
  
  .close{
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 26px;
    cursor: pointer;
    color: #fff;
    background: rgba(0,0,0,0.3);
    border-radius: 4px;
    padding: 4px;
  }
  .video-container {
    width: 100%;
    height: fit-content;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 70vh;
      display: block;
      
      @media(width < 600px){
        height: 100vh;
      }
    }
  }
}

.modal-entrevista{
  width: 100%;
  max-width: 540px;
  padding: 30px 20px;
  margin: 0 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 5px 5px 35px 0px rgba(217, 217, 217, 0.50);
  font-size: 15px;
  .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 28px;
    button{
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      &:last-child{
        height: 45px;
        padding: 0 29px;
        border-radius: 44px;
        background: #FF3B66;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        color: #fff;     
      }
    }
  }
}

.recorrido{
  display: flex;
  flex-direction: column;
  &__cards{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    >div{
      display: flex;
      justify-content: center;
    }
    justify-items: center;
    @media (width < 1100px){
      display: flex;
      gap: 16px;
      overflow-x: auto;
    }
  }
  section,header{
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding: 36px 0;
    h3{
      font-size: 20px;
      font-weight: 600;
    }
    .etapa-label{
      display: flex;
      align-items: center;
      gap: 16px;
      flex-wrap: wrap;
      h3{
        font-size: 20px;
        font-weight: 600;
      }
      span{
        font-size: 20px;
        font-weight: 500;
        color: #BDBDBD;     
      }
    }
    .entrevista{
      position: relative;
      width: 100%;
      max-width: 336px;
      padding: 32px;
      padding-top: 42px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;
      background: #FFF;
      box-shadow: 5px 5px 35px 0px rgba(224, 224, 224, 0.50);  
      span{
        position: absolute;
        top: 14px;
        right: 8px;
        display: flex;
        width: 92px;
        height: 20px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 4px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);
        font-size: 10px;
        font-weight: 600;
        .material-icons{
          font-size: 12px;
        }
      }
      h3{
        margin-bottom: 16px;
        color: $lightblue;
        font-size: 18px;
        font-weight: 400;
      }
      h4{
        font-size: 20px;
        font-weight: 700;
      }
      button{
        margin-top: 22px;
        padding: 10px 0;
        border-radius: 44px;
        background: $lightblue;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
        font-size: 16px;
        font-weight: 600;  
        color: #fff;
        text-align: center;
        &:disabled{
          cursor: default;
        }
      }
    }
    .info-box{
      padding: 18px 10px;
      background: rgba(72, 51, 233, 0.10);
      span{
        color: $lightblue;
        font-weight: 600;
      }
    }
  }
  header{
    padding-bottom: 16px;
    gap: 18px;
    h3{
      font-size: 24px;
    }
    .info{
      font-size: 20px;
      font-weight: 400;
    }
  }
  .tabs{
    width: 100%;
    display: flex;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    @media( width < 1000px){
      display: none;
    }
    &__etapa{
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 280px;
      padding: 12px 0;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      &.-selected{
        color: #4833E9;
        border-bottom: 2px solid #4833E9;
        cursor: default;
      }
    }
  }
  .select-etapa{
    width: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
    @media( width > 1000px){
      display: none;
    }
    >span{
      position: relative;
      width: 100%;
      padding: 16px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      color: #4833E9;
      .material-icons{
        position: absolute;
        right: 32px;
        color: black;
      }
    }
    &__options{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      span{
        width: 100%;
        padding: 8px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }
}

</style>