<template>
    <div class="overlay">
        <div class="detalle-competencia">
            <i class="material-icons close" @click="emit('close')">close</i>
            <h3>{{ $t('progress.boxCompetencias.yourEvaluationAbout') }} <span>{{ props.name }}</span></h3>

            <div class="tabla__scroll-container" v-if="data">
                <div class="tabla" >
                    <div class="indicadores">
                        <span class="th">{{ $t('progress.boxCompetencias.indicators') }}</span>
                        <span v-for="(indic, indicIdx) in data.Indicadores" :key="indicIdx">{{ indic }}</span>
                    </div>
    
                    <div class="nivel" v-for="(nivel, nivelIdx) in data.Niveles" :key="nivelIdx">
                        <span class="th">{{ $t('progress.boxCompetencias.level') }} {{ nivel.Nivel }} <b v-if="nivel.ValorGeneral != null">{{ nivel.ValorGeneral }}%</b></span>
                        <span v-for="(resp, respIdx) in nivel.Respuestas" :key="respIdx">
                            <b v-if="resp.Texto" :class="resp.Color">{{ resp.Texto }}</b>
                            <template v-else>{{ $t('progress.boxCompetencias.N/A') }}</template>
                        </span>
                    </div>
                </div>
            </div>
    
            <Loader v-else />

        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits } from 'vue'; 
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Loader from '@/components/Loader.vue';

const store = useStore();
const route = useRoute();
const data = ref(null);

const props = defineProps({
    name: String
})
const emit = defineEmits(['close']);

async function getData(){
    try {
        let req = await store.dispatch('seguimiento_detalleCompetencia', { alumnoId: route.params.alumnoId, name: props.name })
        data.value = req.data;
    } catch (error) {
        console.log(error);
        store.dispatch('notifications_create', { text: 'Error al obtener Evaluación de competencias.' });
    }
}

onMounted(
    getData()
)

</script>

<style lang="scss" scoped>
@import "@/views/Seguimiento/common.scss";

.overlay{
    z-index: 999;
}
.loader__container{
    width: 912px;
    max-width: 100%;
}
.detalle-competencia{
    width: fit-content;
    max-width: 98%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    padding: 42px 32px 42px 32px;
    background: #fff;
    box-shadow: $boxesShadow;
    border-radius: $cardRadius;
    z-index: 999;
    &::-webkit-scrollbar {
    width: 10px; /* Ancho de la barra de desplazamiento */
    }
    &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb */
    border-radius: 5px; /* Radio de borde del thumb */
    }
    &::-webkit-scrollbar-track {
        margin: 6px 0;
    }
    .close{
        position: absolute;
        top: 14px;
        right: 14px;
        font-size: 28px;
        cursor: pointer;
    }
    h3{
        padding: 0 0 36px 16px;
        font-size: 14px;
        span{
            padding-left: 4px;
            color: $lightblue;
        }
    }
}

.tabla{
    display: flex;
    font-size: 13px;
    color: #212121;
    &__scroll-container{
        //width: fit-content;
        width: 100%;
        overflow-x: auto;
        border-radius: $cardRadius;
        box-shadow: $boxesShadow;
    }
    >div{
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba(189, 189, 189, 0.5);
        &:last-child{
            border: none;
            .th{
                border-radius: 0 $cardRadius 0 0;
            }
        }
        span{
            width: 190px;
            height: 100px;
            padding: 4px 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid rgba(189, 189, 189, 0.5);
            &:last-child{
                border: none;
            }
        }
    }
    .th{
        height: 52px;
        font-weight: 700;
        background: rgba(217, 217, 217, 0.25);
        b{
            padding-left: 12px;
            color: $lightgreen;
        }
    }
    .indicadores{
        .th{
            border-radius: $cardRadius 0 0 0;
        }
        span{
            width: 340px;
            padding-left: 20px;
            justify-content: flex-start;
        } 
    }
    .nivel{
        span{
            b{
                padding: 6px 16px;
                font-size: 12px;
                font-weight: 600;
                border-radius: 50px;
                &.ROJO{
                    color: $rojo;
                    background: rgba(245, 112, 85, 0.1);
                }
                &.AMARILLO{
                    color: $amarillo;
                    background: rgba(255, 182, 0, 0.1);
                }
                &.VERDE{
                    background: rgba(0, 197, 143, 0.1);
                    color: $verde;
                }
            }
        }
    }
}
</style>