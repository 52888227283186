<template>
  <div v-for="(data, index) in selectedForm.Datos" :key="data.Id" class="full">
    <div v-if="data.Tipo != 'option'" class="full columns center">
      <span class="indications__text full center">
        {{ data.Texto }}
      </span>
      <input
        :type="data.Tipo"
        class="input full"
        :name="'data' + index"
        v-model="data['Value']"
      />
    </div>
    <div v-else class="full columns center">
      <span class="indications__text full center">
        {{ data.Texto }}
      </span>
      <select
        name="option"
        id="option"
        class="input full"
        v-model="data['Value']"
      >
        <option value="Seleccione una opcion..." disabled selected hidden>
          Seleccione una opcion...
        </option>
        <option
          v-for="option in data.Opciones"
          :key="option.Id"
          :value="option.Id"
        >
          {{ option.Nombre }}
        </option>
      </select>
    </div>
  </div>
  <div class="full rows center">
    <div
      class="btn btn-small pointer center"
      @click="handleSend"
      :disabled="isEnabled"
    >
      Enviar
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "selectedForm",
  props: {
    selectedForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const completedfields = 0;

    return {
      store,
      completedfields,
    };
  },
  methods: {
    handleSend() {
      this.completedfields = 0;
      for (let i in this.selectedForm.Datos) {
        for (let j in this.selectedForm.Datos[i]) {
          if (j == "Value") {
            this.completedfields += 1;
          }
        }
      }
      if (this.selectedForm.Datos.length == this.completedfields) {
        this.$emit("send", this.selectedForm);
      } else {
        this.store.dispatch("notifications_create", {
          text: "No has completado todos los campos",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  max-width: 400px;
}

.btn {
  margin-top: $margin-small;
}

.indications__text {
  @include font-settings(xmilli, base);
}
</style>