import interceptor from '@/plugins/interceptor';
const pathController = '/nivelCinco'

export default {
  actions: {
    async nivelCinco_progreso() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/progreso`);
    },
    async nivelCinco_recorrido() {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/recorrido`);
    },
    //Obtener fechas
    //Usar desde y hasta del mes
    async nivelCinco_talleres_fechas(_,params) {
      return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/talleresDefinicion/${params.IdDefinicion}/horarios?desde=${params.desde}&hasta=${params.hasta}`);
    },
    //Reservar lugar
    async nivelCinco_talleres_reservar(_,IdTallerInstancia) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_URL}${pathController}/talleres/${IdTallerInstancia}/inscripciones`);
    },
    //Liberar lugar
    async nivelCinco_talleres_liberar(_,IdInscripcion) {
      return await interceptor.authenticate.delete(`${process.env.VUE_APP_API_URL}${pathController}/talleres/inscripciones/${IdInscripcion}`);
    },

  }
}
