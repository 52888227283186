import interceptor from '@/plugins/interceptor';
const pathController = '/Formulario'

export default {
    state: () => ({
        pendingForms : []
    }),
    getters: {
        pendingForms: state => state.pendingForms
    },
    mutations: {
        forms_setPending(state, forms) {
            state.pendingForms = forms;
        }
    },
    actions: {
        async form_autoevpending() {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/autoevaluacion-pendiente`);
        },
        async form_getByCode(_,model) {
            return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/autoevaluacion-pendiente/${model.formUCode}`);
        }
    }
}