import interceptor from '@/plugins/interceptor';
const pathController = '/SolicitudDocumentacion'

export default {
    state: () => ({
      
    }),
    mutations: { 
    },
    actions: {
      async interactions_requests(_, model) {
        if(process.env.OFFLINE_MODE == 'enabled')
        {
        
          console.log(model)
          return {
            status: 200,
            data: [
              {
                  _id: 0,
                  solicitudId: 1,
                  archivo: "http://placehold.it/32x32",
                  comentarioAlumno: "Ipsum duis cupidatat amet nisi minim sint culpa est consectetur. Esse nostrud eu quis cillum in pariatur. Minim commodo cupidatat eu aliqua ad ad nisi amet est. Reprehenderit reprehenderit ea anim ad culpa ex nisi in ad ipsum nostrud voluptate. Do aliquip nisi commodo in deserunt id non qui eiusmod laborum cupidatat quis. Lorem mollit laborum ad mollit eu reprehenderit ipsum. Magna sit dolor nulla mollit sit quis fugiat non cillum pariatur aliqua amet sint dolor.\r\n",
                  comentarioFeedback: "Aute occaecat cillum occaecat minim tempor et nisi. Quis excepteur incididunt anim et. Consectetur laboris pariatur et sunt elit adipisicing. Mollit reprehenderit aute aliquip consectetur amet Lorem non irure occaecat. Quis minim in irure deserunt culpa officia. Cillum nisi nisi mollit do officia ex aliqua non.\r\n",
                  fechaSubida: "Mon Apr 06 1981",
                  fechaRevision: "Fri Feb 13 1970",
                  email: "pamelacurry@zorromop.com",
                  estado: "APROBADO"
                },
                {
                  _id: 1,
                  solicitudId: 1,
                  archivo: "http://placehold.it/32x32",
                  comentarioAlumno: "<SyntaxError: Invalid or unexpected token>",
                  comentarioFeedback: "<SyntaxError: Invalid or unexpected token>",
                  fechaSubida: "Sun Mar 02 1975",
                  fechaRevision: "Tue Jun 12 2007",
                  email: "pamelacurry@zorromop.com",
                  estado: "RECHAZADO"
                },
                {
                  _id: 2,
                  solicitudId: 1,
                  archivo: "http://placehold.it/32x32",
                  comentarioAlumno: "<SyntaxError: Invalid or unexpected token>",
                  comentarioFeedback: "",
                  fechaSubida: "Sat Apr 17 2010",
                  fechaRevision: "",
                  email: "pamelacurry@zorromop.com",
                  estado: "PENDIENTE"
                },
                {
                  "_id": 0,
                  "solicitudId": 0,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Aliqua ullamco pariatur adipisicing ullamco aliquip. Aliqua voluptate Lorem quis nulla irure ut occaecat in adipisicing nulla minim amet veniam. Proident consequat sint sint velit mollit aliqua exercitation nostrud in tempor enim incididunt ex.\r\n",
                  "comentarioFeedback": "Pariatur deserunt duis laborum nisi magna pariatur non deserunt quis dolore minim qui mollit enim. Dolore aute pariatur eiusmod aliqua dolor laborum. Qui eu duis occaecat ullamco labore. Et adipisicing dolore veniam officia elit nisi veniam occaecat nulla commodo fugiat ea. Excepteur commodo laboris est aliqua labore do mollit ullamco aliquip. Dolor cillum voluptate veniam ad.\r\n",
                  "fechaSubida": "Wed Mar 30 1994 16:20:49 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Sun Jul 21 1996 08:06:52 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                },
                {
                  "_id": 1,
                  "solicitudId": 3,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Fugiat elit voluptate proident in tempor labore magna ipsum dolore. Dolor cillum esse ipsum eiusmod ut nulla enim commodo aute deserunt labore cillum cupidatat. Sint qui enim proident aliqua eiusmod proident minim aliquip dolor consectetur in enim id. Labore ea eiusmod labore excepteur officia.\r\n",
                  "comentarioFeedback": "Enim pariatur voluptate dolore excepteur excepteur nostrud quis laborum sint proident sint do tempor id. Ex nisi deserunt Lorem consectetur nisi dolore minim deserunt nulla amet pariatur. Sit veniam pariatur eu est ullamco fugiat velit non est ad est. Adipisicing excepteur dolore ipsum consectetur. Officia commodo est amet ea eiusmod elit irure nulla. Pariatur laboris duis proident fugiat et ullamco reprehenderit adipisicing duis incididunt laborum ullamco. Nisi ea dolore tempor nulla consectetur ut eiusmod non irure aliquip.\r\n",
                  "fechaSubida": "Sun Aug 22 1971 04:24:03 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Tue Oct 14 1975 08:37:28 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                },
                {
                  "_id": 2,
                  "solicitudId": 3,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Laborum proident nisi irure minim. Ea velit ea velit mollit nostrud aliquip fugiat minim nisi ad et adipisicing aliqua. Quis pariatur dolor cupidatat culpa cillum fugiat excepteur laborum eiusmod dolor voluptate. Voluptate cillum sit sit irure. In ut eu culpa aute non ullamco veniam. Ut irure ullamco occaecat veniam et dolore Lorem.\r\n",
                  "comentarioFeedback": "Do et exercitation consectetur tempor in ullamco non eiusmod. Fugiat ea cillum occaecat aliqua ea ullamco enim est eu. Est aliqua reprehenderit proident velit Lorem non. Ipsum irure et anim ad nulla excepteur sit nulla cillum ea. Fugiat fugiat Lorem velit consequat. Laboris aliqua esse sunt commodo proident deserunt consectetur in dolor magna proident nisi elit non.\r\n",
                  "fechaSubida": "Sun Mar 21 2004 21:15:10 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Wed May 12 1993 04:17:47 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                },
                {
                  "_id": 3,
                  "solicitudId": 3,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Aute sint anim sit proident cupidatat nostrud nisi ex Lorem. Aute aliquip voluptate id consectetur laboris dolor reprehenderit. Enim quis id consectetur ex cillum. Incididunt excepteur minim non ad et ex. Nisi quis culpa qui cupidatat aliquip eu magna quis. Dolore est adipisicing culpa ex sint aliquip consectetur excepteur enim do. Velit dolore aliqua dolor voluptate id ex dolore nisi ullamco.\r\n",
                  "comentarioFeedback": "Qui magna cupidatat cillum sint cupidatat cupidatat id. Aliqua irure nostrud consectetur consequat esse elit. Enim minim proident excepteur mollit exercitation culpa ea. Elit sunt irure nostrud eiusmod. Nulla excepteur ea dolore ex quis non quis incididunt fugiat tempor fugiat anim.\r\n",
                  "fechaSubida": "Fri Jul 07 1989 01:08:28 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Fri Jun 13 1997 03:03:25 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                },
                {
                  "_id": 4,
                  "solicitudId": 4,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Magna sint occaecat laboris laborum qui velit Lorem eiusmod incididunt aute magna nostrud magna eiusmod. Id cupidatat et sint duis excepteur est aute anim adipisicing ut nostrud ipsum consectetur. Elit do adipisicing duis ipsum qui ex velit. Esse laboris excepteur amet deserunt nulla aliquip. Consectetur anim pariatur cillum do esse elit. Consectetur consequat cillum deserunt amet deserunt ea veniam quis eiusmod qui. Nulla sit cupidatat do mollit enim ipsum cupidatat eu reprehenderit nostrud.\r\n",
                  "comentarioFeedback": "Enim nostrud irure sint ea eu duis aute ad sint. Mollit sit amet nulla proident. Incididunt deserunt nulla aliqua ea dolor adipisicing nulla esse aute et. Laborum consectetur eu excepteur eiusmod ad sunt dolor ex labore voluptate deserunt tempor nostrud. Incididunt ullamco enim eu occaecat consectetur fugiat cillum.\r\n",
                  "fechaSubida": "Mon Mar 26 2012 05:57:18 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Sun Sep 13 1987 12:26:27 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                },
                {
                  "_id": 5,
                  "solicitudId": 4,
                  "archivo": "http://placehold.it/32x32",
                  "comentarioAlumno": "Do eiusmod officia elit veniam elit aute Lorem velit. Occaecat aute duis quis est enim consectetur. Labore ad eiusmod labore consectetur commodo commodo irure et eu et in adipisicing consequat anim. Sint deserunt aliqua et Lorem qui aute ex consequat ad dolor occaecat cillum voluptate voluptate. Nostrud cupidatat qui et anim voluptate culpa laborum labore officia. Pariatur nisi aute dolore proident ea id dolore qui irure sit.\r\n",
                  "comentarioFeedback": "Pariatur id non exercitation eiusmod. Officia sint voluptate sunt ad nisi irure mollit nulla esse amet adipisicing dolor voluptate. Sunt cupidatat duis occaecat proident tempor pariatur nulla aliquip esse qui ullamco. Veniam minim aliquip nostrud dolor nulla sit nulla eiusmod adipisicing veniam qui sint consequat. Proident sint ad ipsum sit aute anim ad exercitation. Quis elit reprehenderit sint do enim voluptate aliqua aute Lorem. Duis officia proident voluptate aliquip irure laborum occaecat deserunt.\r\n",
                  "fechaSubida": "Wed Feb 24 1971 14:39:35 GMT-0300 (hora estándar de Argentina)",
                  "fechaRevision": "Mon Sep 27 2004 04:16:53 GMT-0300 (hora estándar de Argentina)",
                  "email": "janetmcfarland@xymonk.com"
                }
            ],
          }
        }
        else
        {
          return await interceptor.authenticate.get(`${process.env.VUE_APP_API_URL}${pathController}/detalles/${model}`);
        }
      },
      async interactions_uploadFile() {
        return await interceptor.authenticate.postFile(`${process.env.VUE_APP_API_URL}${pathController}/uploadDocumentacion`);
      }
    }
  }