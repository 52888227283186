

export default function formatDate(fechaOriginal, t, onlyDayAndMonth=false) {  
    fechaOriginal = new Date(fechaOriginal);
  
    const diaSemana = t('dias.'+fechaOriginal.getDay());
    const dia = fechaOriginal.getDate();
    const mes = t('meses.'+fechaOriginal.getMonth());
    const hora = fechaOriginal.getHours();
    const minutos = fechaOriginal.getMinutes();
  
    return onlyDayAndMonth 
      ?  `${dia} ${mes}`
      : `${diaSemana} ${dia} de ${mes}, ${hora}${minutos ? ':'+minutos : ''}hs`;
}
  